import { FilterContext, FilterTermsResult, getFilterString, getFilterTerms, GroupFilter, registerFilter } from "./filter-setup";
import { BoundingBox, boundingBoxToElasticFilter } from "./map-state";
import { DateFilter as DateFilterModel, DateFilter } from "./date-filter";
import { DateTiming, TimingFilterType } from "./filter-timing";
import { DataDictionaryField, DataDictionaryFieldDataSource, DataDictionaryIndexStatus, SystemFieldType } from "./data-dictionary";
import { CalculatedFieldDataTypeCode, QuestionType } from "../../../form-runtime/src/enums";
import { PaymentFields } from "views/components/application-filters/payment-status-filter";
import { SubmissionFields } from "views/components/application-filters/submission-status-filter";

export const getOp = (operator: string) => {
  let op = ":";
  switch (operator) {
    case ">":
    case ">=":
    case "<":
    case "<=":
      op = `:${operator}`;
      break;
  }
  return op;
};

const getDateMath = (filter: DateFilterModel | ApplicationPropertyFilter, field: string) => {
  let math = "";
  const timing = filter.since || filter.timing;
  switch (timing) {
    case TimingFilterType.Any:
      return `_exists_:${field}`;

    case TimingFilterType.Empty:
      return `_missing_:${field}`;

    case TimingFilterType.Today:
      return `${field}:[now/d TO now+1d/d}`;

    case TimingFilterType.Tomorrow:
      return `${field}:[now+1d/d TO now+2d/d}`;

    case TimingFilterType.Yesterday:
      return `${field}:[now-1d/d TO now/d}`;

    case TimingFilterType.Last7:
    case TimingFilterType.Last7Days: 
      return `(${field}:[now-7d/d TO now])`;

    case TimingFilterType.LastX:
    case TimingFilterType.LastXDays:      
      return `(${field}:[now-${filter.sinceDays}d/d TO now])`;
      
    case TimingFilterType.Month:
    case TimingFilterType.CurrentMonth:
      return `${field}:[now/M TO now+1M/M}`;

    case TimingFilterType.Year:
    case TimingFilterType.CurrentYear:
      return `${field}:[now/y TO now+1y/y}`;

    case TimingFilterType.DayRange:
      return `${field}:[now-${filter.daysFrom}d/d TO now-${filter.daysTo}d/d]`

    case TimingFilterType.DateRange:
      return `${field}:[${filter.startDate} TO ${filter.endDate}]`

    default:
      math = "now/d";
      break;
  }

  return `${field}:>=${math}`;
};

const fieldQuery = (key: string, operator: string, value: any, isIndexed: boolean = true) => {
  switch (operator) {
    case "=":
    case ">":
    case ">=":
    case "<":
    case "<=":
      return `${key}${getOp(operator)}${value}`;

    case "!=":
      return `-${key}:${value}`;

    case "~":
      return `_exists_:${key}`;

    case "!~":
      return `_missing_:${key}`;

    case "==":      
      return isIndexed ? `${key}.keyword:${value}`: `${key}:${value}`;

    case "!==":
    case "<>":
      return isIndexed ? `-${key}.keyword:${value}`: `-${key}:${value}`;
  }

  return `${key}:${value}`;
};


export const requiresRuntimeFields = (field: DataDictionaryField): boolean => {
  return field && (field.IndexStatus === DataDictionaryIndexStatus.NotIndexed || field.IndexStatus === DataDictionaryIndexStatus.Indexing)
}

export const fieldOperatorRequiresIndexing = (field: DataDictionaryField, operation: string): boolean => {
  if (!field) {
    return false;
  }

  if (field.IndexStatus === DataDictionaryIndexStatus.Indexed) {
    return false;
  }

  if (field.IndexStatus === DataDictionaryIndexStatus.Unavailable) {
    return true;
  }

  switch (field.DataSource) {
    case DataDictionaryFieldDataSource.CalculatedFieldDataTypeCode:
      // CeebFieldMappings
      switch (field.DataType) {
        case CalculatedFieldDataTypeCode.String: // ValueType.Text
          return operation === "=" || operation === "!="; // contains
        default:
          return false;
      }
    case DataDictionaryFieldDataSource.QuestionTypeCode:  // ValueType.Text
      switch (field.DataType) {
        case QuestionType.Table:
        case QuestionType.ShortText:
        case QuestionType.LongText:
        case QuestionType.URL:
        case QuestionType.PhoneNumber:
        case QuestionType.EmailAddress:
          return operation === "=" || operation === "!="; // contains
        default:
          return false;
      }
    case DataDictionaryFieldDataSource.SystemFieldType:
      // Address, CEEBCode, Name, ScaleGroup Subfields
      switch (field.DataType) {
        case SystemFieldType.String: // ValueType.DataFields
          return operation === "="; // contains
        default:
          return false;
      }
  }

  return false;
}
export class KeywordFilter {
  keyword: string = null;
}

export class TagFilter {
  mode: "any" | "all" | "none" = "any";
  tags: string[] = [];
}

export class ReferenceCountFilter {
  referenceStepKey: string = null;
  type: "completed" | "pending" = "completed";
  operation: "at-least" | "exactly" = "at-least";
  value = 0;
}

export class FormFieldFilter extends DateFilter {
  formKey: string = null;
  sectionKey: string = null;
  field: string = null;
  value: string = null;
  dataFields: { [key: string]: { value: string; op: string } } = null;
  operator = "=";
  shouldQuoteValue = true;
}

export class CommentFilter {
  hasComments: boolean = true;
}

export class OriginFilter {
  origin: string = null;
}

export class ApplicationSourceFilter {
  applicationSourceId: string = null;
}

export class ProgramFilter {
  operator = "=";
  programId: string = null;
}

export class ProgramPropertyFilter {
  field: string = null;
  operator = "=";
  value: string = null;
  shouldQuoteValue = false;
}

export class ProgramStageFilter {
  stage: string = null;
}

export class BoundedAddressFilter {
  formKey: string = null;
  field: string = null;
  boundingBox: BoundingBox = null;
}

export class SegmentFilter {
  operator = "=";
  category: string = null;
  segmentId: string = null;
}

export class PhaseFilter {
  phaseId: string = null;
}

export class PhaseFilterWithCategories {
  phaseId: string = null;
}

export class DecisionFilter {
  decisionId: string = null;
}

export class DecisionLetterFilter {
  hasDecisionLetter: boolean = null;
}

export class DecisionLetterRevealedFilter extends DateFilter {
  decisionLetterRevealed: boolean = null;
  timing: DateTiming = TimingFilterType.Today;
  sinceDays = 7;
}

export class AssignedUserFilter {
  phaseKey: string = "current";
  assignmentType: "user" | "team" | "user-and-team" = "user";
  userId: string = null;
  teamId: string = null;
}

export class EvaluatedByFilter {
  phaseKey = "current";
  userCompletedEvaluation = true;
  userId: string = null;
}

export class MyCompletedEvaluationsFilter {
  phaseKey = "current";
  userCompletedEvaluation = true;
  userId: string = null;
}

export class MyIncompletedEvaluationsFilter {
  phaseKey = "current";
  userId: string = null;
}

export class EvaluationCompleteFilter {
  phaseKey = "current";
  complete = true;
}

export class PhaseCalculatedFieldFilter {
  phaseKey: string = null;
  field: string = null;
  operator = "=";
  value: string = null;
}

export class ApplicationPropertyFilter extends DateFilter {
  field: string = null;
  operator = "=";
  value: string = null;
  shouldQuoteValue = false;
  since: DateTiming = TimingFilterType.Any;
  sinceDays = 7;
}

export class ApplicantPropertyFilter {
  field: string = null;
  operator = "=";
  value: string = null;
  shouldQuoteValue = false;
}

export class CollaborationCalculatedFieldFilter {
  moduleType: "forms" = "forms";
  moduleKey: string = null;
  field: string = null;
  operator = "=";
  value: string = null;
}

export class CollaborationAssignedUserFilter {
  moduleType: "forms" = "forms";
  moduleKey: string = null;
  userId: string = null;
}

export class CollaborationEvaluatedByFilter {
  moduleType: "forms" = "forms";
  moduleKey: string = null;
  userCompletedEvaluation = true;
  userId: string = null;
}

export class LastUpdatedFilter extends DateFilter {
  type = "applicant";
  mode = "timing";
  timing: DateTiming = TimingFilterType.Today;
  sinceDays = 7;
}

export class DateStartedFilter extends DateFilter {
  type = "date-started-filter";
  mode = "timing";
  timing: DateTiming = TimingFilterType.Today;
  sinceDays = 7;
}

export class PaymentStatusFilter extends DateFilter {
  type = "payment-status-filter";
  timing: TimingFilterType = TimingFilterType.Today;
  stepGroupKey: string = null;
  operator = "=";
  value: string | number = null;
}

export class DateStageChangedFilter extends DateFilter {
  type = "date-stage-changed-filter";
  mode = "timing";
  timing: DateTiming = TimingFilterType.Today;
  sinceDays = 7;
}

export class DatePhaseChangedFilter extends DateFilter {
  type = "date-phase-changed-filter";
  mode = "timing";
  timing: DateTiming = TimingFilterType.Today;
  sinceDays = 7;
}

export class SubmissionStatusFilter extends DateFilter {
  type = "submission-status-filter";
  timing: TimingFilterType = TimingFilterType.Today;
  stepGroupKey: string = null;
  operator = ":";
  value: boolean = null;
}


export class DateDecisionChangedFilter extends DateFilter {
  type = "date-decision-changed-filter";
  mode = "timing";
  timing: DateTiming = TimingFilterType.Today;
  sinceDays = 7;
}

export class ErrorsFilter {
  hasErrors: boolean;
}

export class ApplicationFilter {
  hasApplication: boolean = true;
}

export class TableSectionGroupFilter extends GroupFilter {
  tableSectionPath: string = null;
  formKey: string = null;
  sectionKey: string = null;
}

registerFilter(TableSectionGroupFilter, s => {
  s.toFilterString((data, context) => {
    if (data.filters === null || data.filters.length === 0) {
      return "";
    }

    const inRowFilterStr = data.filters.map(f => getFilterString(f, context)
      .replace(`forms.${data.formKey}.${data.sectionKey}.rows.`, "")
    ).join(` ${data.operation} `);
    if(!inRowFilterStr)
      return "";
    
    const str = `forms.${data.formKey}.${data.sectionKey}.rows:(${inRowFilterStr})`;
    return data.filters.length === 1 ? str : `(${str})`;
  });
  s.toFilterTerms((data, context) => {
    if (data.filters === null || data.filters.length === 0) {
      return [];
    }

    return data.filters
      .map(f =>{ 
        const term = getFilterTerms(f, context)
        term.forEach(t => t.requiresIndexing = t.requiresRuntimeFields);
        return term;
      })
      .reduce((paths, current) => paths.concat(current), []);
  });
});

export const registerApplicationFilters = () => {
  registerFilter(CommentFilter, s => {
    s.toFilterString(data => {
      return data.hasComments ? "commentCount:>0" : "commentCount:0";
    });
    s.toFilterTerms(data => [{ term: "commentCount" }]);
  });

  registerFilter(KeywordFilter, s => {
    s.toFilterString(data => data.keyword);
    s.validate(v => v.required(d => d.keyword));
  });

  registerFilter(TagFilter, s => {
    s.toFilterString(data => {
      if (data.tags == null || data.tags.length == 0) return "";

      const tags = data.tags.map(v => `"${v.replace(/\"/g, "\\\"")}"`)
        .join(data.mode == "all" ? " AND " : " OR ");
      return data.mode == "none" ? `-(tags:(${tags}))` : `(tags:(${tags}))`;
    });
    s.toFilterTerms(data => [{ term: "tags" }]);
  });

  registerFilter(ReferenceCountFilter, s => {
    s.toFilterString(data => {
      const operation = data.operation == "at-least" ? ">=" : "";
      return `references.${data.referenceStepKey}.${data.type
        }:${operation}${data.value || 0}`;
    });
    s.toFilterTerms(data => [{ term: `references.${data.referenceStepKey}.${data.type}` }]);

    s.validate(v => v.required(d => d.referenceStepKey));
  });

  registerFilter(FormFieldFilter, s => {
    s.toFilterString((data, context) => {
      let path = null;
      if (data.sectionKey != null) {
        path = `forms.${data.formKey}.${data.sectionKey}.${data.field}`;
      } else {
        path = `forms.${data.formKey}.${data.field}`;
      }

      if (data.timing) {
        return getDateMath(data, path);
      }

      const safeQuoteValue = (data.value || "~~~").replace(/[\s\"]+/g, " ");
      const value = data.shouldQuoteValue ? `"${safeQuoteValue}"` : data.value || 0;

      if (data.dataFields == null) {
        const field: DataDictionaryField = context?.fields.find(x => x.SearchPath == path ?? x.Path == path);
        

        if(field?.DataType == QuestionType.Encrypted)
          path = `${path}.encrypted`;
           
        return fieldQuery(path, data.operator, value, field == null || field.IndexStatus == DataDictionaryIndexStatus.Indexed);
      }

      const dataFields: string[] = [];
      Object.keys(data.dataFields).forEach(k => {
        if (data.dataFields[k] == null || (data.dataFields[k].value || "").trim().length == 0) {
          return;
        }

        let subPath = `${path}.${k}`;
        const safeQuoteFieldValue = (data.dataFields[k].value || "~~~").replace(/[\s\"]+/g, " ");
        const fieldValue = data.shouldQuoteValue
          ? `"${safeQuoteFieldValue}"`
          : data.dataFields[k].value || 0;

        const field = context?.fields.find(x => x.SearchPath == path ?? x.Path == subPath);
        

        if(field?.DataType == QuestionType.Encrypted)
          subPath = `${subPath}.encrypted`;
        dataFields.push(
          fieldQuery(subPath, data.dataFields[k].op, fieldValue, field?.IndexStatus == DataDictionaryIndexStatus.Indexed)
        );
      });

      return dataFields.join(" AND ");
    });
    s.toFilterTerms((data: FormFieldFilter, context?: FilterContext) => {
      if (!data.formKey || !data.field) {
        return [];
      }

      let path = null;
      if (data.sectionKey != null) {
        path = `forms.${data.formKey}.${data.sectionKey}.${data.field}`;
      } else {
        path = `forms.${data.formKey}.${data.field}`;
      }

      if (data.timing) {
        const field = context?.fields?.find(f => f.SearchPath === path || f.Path === path);
        const requiresIndexing = fieldOperatorRequiresIndexing(field, null);

        return [{
          field,
          term: path,
          requiresIndexing,
          requiresRuntimeFields: requiresIndexing ? false : requiresRuntimeFields(field)
        }]; // date operations are allowed: _exists_ / _missing_ / >= / < / [ TO ]
      }

      if (data.dataFields == null) {
        const field = context?.fields?.find(f => f.SearchPath === path ||  f.Path === path);
        const requiresIndexing = fieldOperatorRequiresIndexing(field, data.operator);

        return [{
          field,
          term: path,
          requiresIndexing,
          requiresRuntimeFields: requiresIndexing ? false : requiresRuntimeFields(field)
        }];
      }

      const paths: FilterTermsResult[] = [];
      Object.keys(data.dataFields).forEach(k => {
        if (data.dataFields[k] == null || (data.dataFields[k].value || "").trim().length == 0) {
          return;
        }

        const subPath = `${path}.${k}`;
        const field = context?.fields?.find(f => f.SearchPath === path ||  f.Path === subPath);
        const requiresIndexing = fieldOperatorRequiresIndexing(field, data.dataFields[k].op);

        paths.push({
          field,
          term: subPath,
          requiresIndexing,
          requiresRuntimeFields: requiresIndexing ? false : requiresRuntimeFields(field)
        });
      });

      return paths;
    });

    s.validate((v, data) => {
      v.required(d => d.formKey);
      v.required(d => d.field);

      switch (data.operator) {
        case null: // dates
        case "~": // is answered
        case "!~": // is not answered
          break;

        default:
          if (data.dataFields == null) {
            v.required(d => d.value);
          }
          break;
      }
    });
  });

  registerFilter(ProgramFilter, s => {
    s.toFilterString(data => fieldQuery("programId", data.operator, data.programId));
    s.toFilterTerms(data => [{ term: "programId" }]);
    s.validate(v => v.required(d => d.programId));
  });

  registerFilter(OriginFilter, s => {
    s.toFilterString(data => `origin:${data.origin}`);
    s.toFilterTerms(data => [{ term: "origin" }]);
    s.validate(v => v.required(d => d.origin));
  });

  registerFilter(ApplicationSourceFilter, s => {
    s.toFilterString(data => `originContext.type:ApplicationSource AND originContext.id:${data.applicationSourceId}`);
    s.toFilterTerms(data => [{ term: "originContext.type" }, { term: "originContext.id" }]);
    s.validate(v => v.required(d => d.applicationSourceId));
  });

  registerFilter(ProgramStageFilter, s => {
    s.toFilterString(data => `stage:${data.stage}`);
    s.toFilterTerms(data => [{ term: "stage" }]);
    s.validate(v => v.required(d => d.stage));
  });

  registerFilter(SegmentFilter, s => {
    s.toFilterString(data => (data.operator == "!=" ? "-" : "") + `(@include:${data.segmentId})`);
    s.validate(v => v.required(d => d.segmentId));
  });

  registerFilter(BoundedAddressFilter, s => {
    s.toFilterString(data => `forms.${data.formKey}.${data.formKey}.geo:${boundingBoxToElasticFilter(data.boundingBox)}`);
    s.toFilterTerms((data, context) => {
      const path: string = `forms.${data.formKey}.${data.formKey}.geo`;
      const field = context?.fields?.find(f => f.Path === path);
      return [{
        field,
        term: path,
        requiresIndexing: fieldOperatorRequiresIndexing(field, null),
        requiresRuntimeFields: requiresRuntimeFields(field)
      }];
    });
  });

  registerFilter(PhaseFilter, s => {
    s.toFilterString(data => {
      if (data.phaseId == null) {
        return "_missing_:phaseId";
      }

      return `phaseId:${data.phaseId}`;
    });
    s.toFilterTerms(data => [{ term: "phaseId" }]);
  });

  registerFilter(PhaseFilterWithCategories, s => {
    s.toFilterString(data => {
      if (data.phaseId == null) {
        return "_missing_:phaseId";
      }

      return `phaseId:${data.phaseId}`;
    });
    s.toFilterTerms(data => [{ term: "phaseId" }]);
  });

  registerFilter(DecisionFilter, s => {
    s.toFilterString(data => {
      if (data.decisionId == null) {
        return "_missing_:decisionId";
      } else {
        return `decisionId:${data.decisionId}`
      }
    });
    s.toFilterTerms(data => [{ term: "decisionId" }]);
  });

  registerFilter(DecisionLetterFilter, s => {
    s.toFilterString(data => {
      if (data.hasDecisionLetter === true) {
        return "_exists_:decisionLetter.fileId";
      } else {
        return "_missing_:decisionLetter.fileId"
      }
    });
    s.toFilterTerms(data => [{ term: "decisionLetter.fileId" }]);
  });

  registerFilter(DecisionLetterRevealedFilter, s => {
    s.toFilterString(data => {
      switch (data.decisionLetterRevealed) {
        case true:
          return "_exists_:decisionLetter.revealedAtUtc";
        case false:
          return "_missing_:decisionLetter.revealedAtUtc";
        default:
          return getDateMath(data, "decisionLetter.revealedAtUtc")
      }
    });
    s.toFilterTerms(data => [{ term: "decisionLetter.revealedAtUtc" }]);
  });

  registerFilter(AssignedUserFilter, s => {
    s.toFilterString(data => {
      const filters: string[] = [];

      if (
        (data.assignmentType == "user" ||
          data.assignmentType == "user-and-team") &&
        data.userId != null
      ) {
        filters.push(`phases.${data.phaseKey}.assigned_users:${data.userId}`);
      }

      if (
        (data.assignmentType == "team" ||
          data.assignmentType == "user-and-team") &&
        data.teamId != null
      ) {
        filters.push(`phases.${data.phaseKey}.assigned_team_id:${data.teamId}`);
      }

      return filters.join(" AND ");
    });
    s.toFilterTerms(data => {
      const paths: FilterTermsResult[] = [];

      if ((data.assignmentType == "user" || data.assignmentType == "user-and-team") && data.userId != null) {
        paths.push({ term: `phases.${data.phaseKey}.assigned_users` });
      }

      if ((data.assignmentType == "team" || data.assignmentType == "user-and-team") && data.teamId != null) {
        paths.push({ term: `phases.${data.phaseKey}.assigned_team_id` });
      }

      return paths;
    });

    s.validate((v, data) => {
      switch (data.assignmentType) {
        case "user":
          v.required(d => d.userId);
          break;

        case "team":
          v.required(d => d.teamId);
          break;

        case "user-and-team":
          v.required(d => d.teamId);
          v.required(d => d.userId);
          break;
      }
    });
  });

  registerFilter(PaymentStatusFilter, s => {
    s.toFilterString(data => {
      if(data.timing) {
        return getDateMath(data, `${data.stepGroupKey}.${data.field}`);
      }

      return fieldQuery(`${data.stepGroupKey}.${data.field}`, data.operator, data.value || 0);
    });
    s.toFilterTerms(data => [{ term: `${data.stepGroupKey}.${data.field}` }]);

    s.validate((v, data) => {
      v.required(d => d.stepGroupKey);
      v.required(d => d.field)
      
      switch (data.field) {
        case PaymentFields.PaymentStatus:
        case PaymentFields.AmountPaid:
        case PaymentFields.WaivedBy:
          v.required(d => d.value);
          break;
      }
    });
  });

  registerFilter(EvaluatedByFilter, s => {
    s.toFilterString(data => {
      const { phaseKey, userCompletedEvaluation, userId } = data;
      return `${userCompletedEvaluation ? "" : "-"
        }(phases.${phaseKey}.evals_completed_by:(${userId}))`;
    });
    s.toFilterTerms(data => [{ term: `phases.${data.phaseKey}.evals_completed_by` }]);

    s.validate(v => v.required(d => d.userId));
  });

  registerFilter(SubmissionStatusFilter, s => {
    s.toFilterString(data => {
      if(data.timing) {
        return getDateMath(data, `${data.stepGroupKey}.${data.field}`);
      }

      return fieldQuery(`${data.stepGroupKey}.${data.field}`, data.operator, data.value);
    });
    s.toFilterTerms(data => [{ term: `${data.stepGroupKey}.${data.field}` }]);

    s.validate((v, data) => {
      v.required(d => d.stepGroupKey);
      v.required(d => d.field)

      switch (data.field) {
        case SubmissionFields.IsSubmitted:
          v.required(d => d.value);
          break;
      }
    });
  });

  registerFilter(MyCompletedEvaluationsFilter, s => {
    s.toFilterString(data => {
      const { phaseKey, userCompletedEvaluation, userId } = data;
      if (phaseKey.includes(','))
        return phaseKey.split(',')
          .map(key => `(phases.${key}.evals_completed_by:(${userId}) AND (phases.${key}.evaluation_complete:${true}))`)
          .join(' OR ');
      return `${userCompletedEvaluation ? "" : "-"
        }(phases.${phaseKey}.evals_completed_by:(${userId}) AND (phases.${phaseKey}.evaluation_complete:${true}))`;
    });
    s.toFilterTerms(data => {
      const { phaseKey } = data;
      if (phaseKey.includes(',')) {
        const evalCompletedByFields = phaseKey.
          split(',').map(key => <FilterTermsResult>{ term: `phases.${key}.evals_completed_by` });
        const evalCompleteFields = phaseKey.
          split(',').map(key => <FilterTermsResult>{ term: `phases.${key}.evaluation_complete` });
        return evalCompletedByFields.concat(evalCompleteFields);
      }
      return [
        { term: `phases.${phaseKey}.evals_completed_by` },
        { term: `phases.${phaseKey}.evaluation_complete` }
      ];
    });

    s.validate(v => v.required(d => d.userId));
  });

  registerFilter(MyIncompletedEvaluationsFilter, s => {
    s.toFilterString(data => {
      const { phaseKey, userId } = data;
      return `(phases.${phaseKey}.assigned_users:${userId}) AND (phases.${phaseKey}.evaluation_complete:${false})`;
    });
    s.toFilterTerms(data => [
      { term: `phases.${data.phaseKey}.assigned_users` },
      { term: `phases.${data.phaseKey}.evaluation_complete` }
    ]);

    s.validate(v => v.required(d => d.userId));
  });

  registerFilter(EvaluationCompleteFilter, s => {
    s.toFilterString(data => {
      const { phaseKey, complete } = data;
      return `phases.${phaseKey}.evaluation_complete:${complete}`;
    });
    s.toFilterTerms(data => [{ term: `phases.${data.phaseKey}.evaluation_complete` }]);
  });

  registerFilter(PhaseCalculatedFieldFilter, s => {
    s.toFilterString(data => {
      return `phases.${data.phaseKey}.${data.field}${getOp(
        data.operator,
      )}${data.value || 0}`;
    });
    s.toFilterTerms(data => [{ term: `phases.${data.phaseKey}.${data.field}` }]);

    s.validate(v => {
      v.required(d => d.phaseKey);
      v.required(d => d.field);
    });
  });

  registerFilter(ApplicationPropertyFilter, s => {
    s.toFilterString(data => {
      if (data.timing) {
        return getDateMath(data, data.field);
      }

      const safeQuoteValue = (data.value || "~~~").replace(/[\s\"]+/g, " ");
      const value = data.shouldQuoteValue
        ? `"${safeQuoteValue}"`
        : data.value || 0;

      return fieldQuery(data.field, data.operator, value);
    });
    s.toFilterTerms(data => [{ term: data.field }]);

    s.validate(v => v.required(d => d.field));
  });

  registerFilter(ProgramPropertyFilter, s => {
    s.toFilterString(data => {
      const safeQuoteValue = (data.value || "~~~").replace(/[\s\"]+/g, " ");
      const value = data.shouldQuoteValue
        ? `"${safeQuoteValue}"`
        : data.value || 0;

      return fieldQuery(`program.properties.${data.field}`, data.operator, value);
    });
    s.toFilterTerms(data => [{ term: `program.properties.${data.field}` }]);

    s.validate(v => v.required(d => d.field));
  });

  registerFilter(ApplicantPropertyFilter, s => {
    s.toFilterString(data => {

      const safeQuoteValue = (data.value || "~~~").replace(/[\s\"]+/g, " ");
      const value = data.shouldQuoteValue
        ? `"${safeQuoteValue}"`
        : data.value || 0;

      //return `applicant.${data.field}${op}${value}`;
      return fieldQuery(`applicant.${data.field}`, data.operator, value);
    });
    s.toFilterTerms(data => [{ term: `applicant.${data.field}` }]);

    s.validate(v => v.required(d => d.field));
  });

  registerFilter(CollaborationCalculatedFieldFilter, s => {
    s.toFilterString(data => {
      return `collaborations.${data.moduleType}.${data.moduleKey}.${data.field
        }${getOp(data.operator)}${data.value || 0}`;
    });
    s.toFilterTerms(data => [{ term: `collaborations.${data.moduleType}.${data.moduleKey}.${data.field}` }]);

    s.validate(v => {
      v.required(d => d.moduleKey);
      v.required(d => d.field);
    });
  });

  registerFilter(CollaborationAssignedUserFilter, s => {
    s.toFilterString(data => {
      return `collaborations.${data.moduleType}.${data.moduleKey
        }.assigned_users:${data.userId}`;
    });
    s.toFilterTerms(data => [{ term: `collaborations.${data.moduleType}.${data.moduleKey}.assigned_users` }]);

    s.validate(v => {
      v.required(d => d.moduleKey);
      v.required(d => d.userId);
    });
  });

  registerFilter(CollaborationEvaluatedByFilter, s => {
    s.toFilterString(data => {
      const { moduleKey, moduleType, userCompletedEvaluation, userId } = data;

      return `${userCompletedEvaluation ? "" : "-"
        }(collaborations.${moduleType}.${moduleKey}.evals_completed_by:(${userId}))`;
    });
    s.toFilterTerms(data => [{ term: `collaborations.${data.moduleType}.${data.moduleKey}.evals_completed_by` }]);

    s.validate(v => {
      v.required(d => d.moduleKey);
      v.required(d => d.userId);
    });
  });

  registerFilter(LastUpdatedFilter, s => {
    const applicantField = "dateApplicantUpdated";
    const userField = "dateUserUpdated";
    s.toFilterString(data => {
      data.field = data.type == "user" ? userField : applicantField;
      return getDateMath(data, data.field);
    });
    s.toFilterTerms(data => [{ term: data.type === "user" ? userField : applicantField }]);
  });

  registerFilter(DateStageChangedFilter, s => {
    const field = "dateLastStageChanged";
    s.toFilterString(data => {
      data.field = field;
      return getDateMath(data, data.field);
    });
    s.toFilterTerms(data => [{ term: field }]);
  });

  registerFilter(DatePhaseChangedFilter, s => {
    const field = "dateLastPhaseChanged";
    s.toFilterString(data => {
      data.field = field;
      return getDateMath(data, data.field);
    });
    s.toFilterTerms(data => [{ term: field }]);
  });

  registerFilter(DateDecisionChangedFilter, s => {
    const field = "dateLastDecisionChanged";
    s.toFilterString(data => {
      data.field = field;
      return getDateMath(data, data.field);
    });
    s.toFilterTerms(data => [{ term: field }]);
  });

  registerFilter(DateStartedFilter, s => {
    const field = "dateStarted";
    s.toFilterString(data => {
      data.field = field;
      return getDateMath(data, data.field);
    });
    s.toFilterTerms(data => [{ term: field }]);
  });

  registerFilter(ErrorsFilter, s => {
    s.toFilterString(data => `errorCount:${data.hasErrors ? ">0" : "0"}`);
    s.toFilterTerms(data => [{ term: "errorCount" }]);
  });

  registerFilter(ApplicationFilter, f => {
    f.toFilterString(data => {
      return data.hasApplication ? "@application:(*)" : "-@application:(*)";
    });
  });
};
