import { inject } from "fw";
import { Store, dispatch, handle } from "fw-state";

import { FormErrorHandling } from "./error-handling";

import { LogoutAction, StartAction } from "./actions";
import { ApiKey } from "models/api-key";
import { ApiKeyRepository } from "network/api-key-repository";
import {
  AddApiKeyAction,
  UpdateApiKeyAction,
  DeleteApiKeysAction,
  UpdateApiKeyActiveAction,
} from "forms/api-key";

import { difference } from "lodash-es";
import { EnsureRolesAction } from "state/roles";

interface ApiKeyShape {
  loaded: boolean;
  apiKeys: ApiKey[];
}

export class EnsureApiKeysAction {}

@inject
export class ApiKeyStore extends Store<ApiKeyShape> {
  constructor(private apiKeyRepo: ApiKeyRepository) {
    super();
  }

  defaultState() {
    return {
      loaded: false,
      apiKeys: [],
    };
  }

  @handle(LogoutAction)
  @handle(StartAction)
  private handleLogout() {
    this.setState(s => this.defaultState());
  }

  @handle(EnsureApiKeysAction)
  private async handleEnsureApikeys() {
    if (this.state.loaded) return;

    //  todo:  this is better than listing everything
    //  eventually needs a separate "private" api call to just return
    //  the developer api keys without all the "noise"
    const all = await this.apiKeyRepo.list();
    const apiKeys = all.filter(k => k.RoleId && k.RoleId.length > 0);
    await dispatch(new EnsureRolesAction(apiKeys.map(k => k.RoleId)));

    this.setState(state => ({
      loaded: true,
      apiKeys,
    }));
  }

  @handle(AddApiKeyAction)
  private async handleAddApiKeyAction(action: AddApiKeyAction) {
    action.form.validate();

    await this.handleEnsureApikeys();

    const newApiKey = await this.apiKeyRepo.post(action.form.RoleId);
    await dispatch(new EnsureRolesAction([action.form.RoleId]));

    action.addedId = newApiKey.Id;

    this.setState(state => ({
      loaded: true,
      apiKeys: [...state.apiKeys, newApiKey],
    }));
  }

  @handle(UpdateApiKeyAction, FormErrorHandling)
  private async handleUpdateApiKeyAction(action: UpdateApiKeyAction) {
    action.form.validate();

    const updatedApiKey = await this.apiKeyRepo.put(action.form.updatedModel());
    await dispatch(new EnsureRolesAction([action.form.RoleId]));

    const existingApiKey = this.state.apiKeys.find(
      t => t.Id == updatedApiKey.Id,
    );
    if (existingApiKey == null) return;

    Object.assign(existingApiKey, updatedApiKey);

    this.setState(s => s);
  }

  @handle(UpdateApiKeyActiveAction)
  private async handleUpdateApiKeyActiveAction(
    action: UpdateApiKeyActiveAction,
  ) {
    await this.apiKeyRepo.putActive(action.ids, action.isActive);

    const keys = this.state.apiKeys.filter(k =>
      action.ids.some(id => id == k.Id),
    );
    keys.forEach(k => (k.IsActive = action.isActive));

    this.setState(s => s);
  }

  @handle(DeleteApiKeysAction)
  private async handleDeleteApiKeysAction(action: DeleteApiKeysAction) {
    await this.handleEnsureApikeys();

    const apiKeys = this.state.apiKeys.filter(t =>
      action.apiKeyIds.some(id => id == t.Id),
    );

    await this.apiKeyRepo.del(apiKeys.map(t => t.Id));

    this.setState(state => ({
      loaded: true,
      apiKeys: difference(state.apiKeys, apiKeys),
    }));
  }
}
