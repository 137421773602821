import { Form, FormAnswer, Result, Resolved, IProgramPropertiesHash } from "./models";
import { validateAll, isAnswered } from "./validation";

const s4 = () => Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);

export const WORKER_FILE_NAME: string = "condition-resolver";

export class FormResolverService {
  private worker: Worker;
  private resolverHash: { [id: string]: {
    resolver: (s: Resolved) => void,
    rawResolver: (s: any) => void,
    reject: (err: any) => void
  } } = {};

  constructor() {
    this.worker = new Worker(new URL('./worker/condition-resolver.worker', import.meta.url));
    this.worker.onmessage = this.onMessage.bind(this);
  }

  private onMessage(e: MessageEvent) {
    const { id, sections, error, calculatedValues, rawResult, isRaw } = e.data as Result;

    if (error != null) {
      this.resolverHash[id].reject(new Error(error));
    } else {
      if (isRaw) {
        this.resolverHash[id].rawResolver(rawResult);
      } else {
        this.resolverHash[id].resolver({ sections, calculatedValues });
      }
    }

    delete this.resolverHash[id];
  }

  public async resolveForm(
    form: Form,
    answers: FormAnswer[] | { [key: string]: FormAnswer },
    baseAnswers: FormAnswer[] = [],
    programPropertiesHash: IProgramPropertiesHash = {},
    onlyOptions = false,
  ): Promise<Resolved> {
    const id = s4() + s4() + "-" + s4() + s4();

    const promise = new Promise<Resolved>((resolver, reject) => {
      this.resolverHash[id] = { resolver, reject, rawResolver: null };
    });

    let answersToSend: FormAnswer[] = [];

    if (Array.isArray(answers)) {
      answersToSend = answers;
    } else {
      for (const key in answers) {
        answersToSend.push(answers[key]);
      }
    }

    this.worker.postMessage({
      id,
      form,
      answers: answersToSend,
      baseAnswers: baseAnswers || [],
      programPropertiesHash,
      onlyOptions,
    });

    return promise;
  }

  public pruneAnswers(form: Form, answers: FormAnswer[]) {
    return answers.filter(aa => {
      for (const section of form.Sections) {
        // special handling for table sections
        if (section.IsTableSection && section.TableSectionOptions && aa.SectionKey == section.TableSectionOptions.Key) {
          for (const sectionAnswer of aa.SectionAnswers) {
            sectionAnswer.Answers = sectionAnswer.Answers.filter(sa => {
              const question = section.Questions.find(q => q.Key == sa.QuestionKey);
              return isAnswered(question, sa);
            });
          }

          return aa.SectionAnswers.length > 0;
        }

        const question = section.Questions.find(q => q.Key == aa.QuestionKey);
        if (question != null) {
          return isAnswered(question, aa);
        }
      }

      return false;
    });
  };

  // this will resolve a form, fill in any blank answers for you, and then validate
  public async validateResolved(form: Form, answers: FormAnswer[] | { [key: string]: FormAnswer }) {
    const results = await this.resolveForm(form, answers);

    const keys: string[] = [];
    results.sections.forEach(cs => {
      const section = form.Sections[cs.sectionIndex];

      if (!section.IsTableSection) {
        cs.questions.forEach(q => keys.push(q.key));
      }
    });

    let theAnswers: FormAnswer[] = [];
    if (Array.isArray(answers)) {
      theAnswers = answers.slice();
    } else {
      for (const key in answers) theAnswers.push(answers[key]);
    }

    // fill out answers that are not in the list
    keys.forEach(key => {
      if (theAnswers.find(a => a.QuestionKey == key) == null) {
        const newAnswer = new FormAnswer();
        newAnswer.QuestionKey = key;

        theAnswers.push(newAnswer);
      }
    });

    return validateAll(form, theAnswers);
  }

  // need to run some javascript? ok! here you go!
  public async runJs<T>(code: string, context: any): Promise<T> {
    const id = s4() + s4() + "-" + s4() + s4();

    const promise = new Promise<T>((resolver, reject) => {
      this.resolverHash[id] = { resolver: null, reject, rawResolver: resolver };
    });

    this.worker.postMessage({ id, isRaw: true, code, context });

    return promise;
  }
}

