import { FormForType, formFor } from "fw-model";

import {
  Role,
  DataPolicy,
  ApplicationRestriction,
  SeasonalRoleSettings
} from "models/role";

export type RoleFormType = FormForType<Role>;

export class UpdateRoleAction {
  public canForce = false;
  public updated: Role = null;
  constructor(public form: RoleFormType, public force = false) { }
}

export class AddRoleAction {
  public added: Role = null;
  constructor(public form: RoleFormType) { }
}

export class DeleteRoleAction {
  constructor(public roleId: string) { }
}
export const applicationRestrictionFormCreator = formFor(
  ApplicationRestriction,
  s => {
    s.requiredField(u => u.Type, "Restriction Type");
    s.field(u => u.Options, "Options");
    s.field(u => u.Paths, "Paths");
    s.field(u => u.StepKey, "Step Key");
  }
);

export const dataPolicyFormCreator = formFor(DataPolicy, s => {
  s.formArray(
    u => u.ApplicationRestrictions,
    "",
    applicationRestrictionFormCreator
  );
});

export const seasonalRoleSettingsFormCreator = formFor(
  SeasonalRoleSettings,
  s => {
    s.field(u => u.DashboardReportId, "Dashboard");
    s.field(u => u.ApplicationSegmentIds, "ApplicationSegments");
  }
);

export const roleFormCreator = formFor(Role, s => {
  s.requiredField(u => u.Name, "Name");
  s.field(u => u.Permissions, "Permissions");

  s.formProperty(u => u.DataPolicies, "", dataPolicyFormCreator);
  s.formProperty(
    u => u.SeasonalRoleSettings,
    "",
    seasonalRoleSettingsFormCreator
  );
});
