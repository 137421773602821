import { ComponentEventBus, inject, needs, prop } from "fw";
import { ApplicationRestriction, ApplicationRestrictionFormType } from "models/role";
import { PopoverService } from "service/popover";
import { RestrictionSelectorPopover } from "views/settings/components/data-restrictions/restriction-selector-popover";
import { getRestrictionComponentType, restrictionComponents } from "views/settings/roles/restriction-forms/components";

@inject
@needs(...restrictionComponents)
export class ManageApplicationRestrictions {
    @prop("Application Data Restrictions") title: string;
    @prop("Hide specific property values, forms, or individual questions") description: string;
    @prop(false) canEdit: boolean;
    @prop([]) activeRestrictions: ApplicationRestriction[];

    constructor(
        private ceb: ComponentEventBus,
        private popoverService: PopoverService
    ) { }

    getRestrictionType(r: ApplicationRestrictionFormType) {
        return getRestrictionComponentType(r);
    }

    async addRestriction(): Promise<ApplicationRestriction[]> {
        const restrictions: ApplicationRestriction[] = []
        const activeRestrictions = this.activeRestrictions;
        const popover = await this.popoverService.open<ApplicationRestriction[]>(
            RestrictionSelectorPopover,
            activeRestrictions,
        );

        if (popover.canceled || !popover.result || popover.result.length === 0) {
            return;
        }

        for (const restriction of popover.result) {
            switch (restriction.Type) {
                case "ApplicantIdentity": {
                    const applicantIdentity = activeRestrictions.find(r => r.Type == restriction.Type);
                    if (applicantIdentity) {
                        continue;
                    }
                    break;
                }

                case "ApplicantProfileImage": {
                    const applicantProfileImage = activeRestrictions.find(r => r.Type == restriction.Type);
                    if (applicantProfileImage) {
                        continue;
                    }
                    break;
                }

                case "ApplicationProperty": {
                    if (!restriction.Paths || restriction.Paths.length === 0) continue;
                    const applicationProperty = activeRestrictions.find(r => r.Type == restriction.Type);
                    if (applicationProperty) {
                        applicationProperty.Paths = applicationProperty.Paths.concat(restriction.Paths);
                        continue;
                    }
                    break;
                }

                case "FormStep":
                case "Evaluation":
                case "ReferenceForm":
                    //  just create a new one
                    break;

                case "DocumentStep":
                case "PortfolioStep": {
                    if (!restriction.StepKey || restriction.StepKey.trim().length === 0) {
                        continue;
                    }

                    const step = activeRestrictions.find(r => r.Type == restriction.Type && r.StepKey == restriction.StepKey);
                    if (step) {
                        continue;
                    }
                    break;
                }
            }

            restrictions.push(restriction);
        }

        this.ceb.dispatch("add-restriction", restrictions);
    }

    public deleteRestriction() {
        this.ceb.dispatch("delete-restriction");
    }

    public deletePath(data: { restriction: ApplicationRestriction, index: number }) {
        this.ceb.dispatch("delete-path", data);
    }
}