import { makerOf, nameOf, Validators, createFrom, fromCustom } from "fw-model";
import { kebab } from "fw";
import { ActivityGroupFilter } from "views/components/activity-filters/activity-group-filter";
import { DataDictionaryField } from "./data-dictionary";
import { ValueType } from "views/components/application-filters/value-field";
import { QuestionType } from "./form";
import { FormFieldFilter } from "./application-filters";
import { TimingFilterType } from "./filter-timing";

export interface ValidationContext<T = any> {
  required(n: (d: T) => any);
}

export type FilterContext = {
  // NOTE: you can add additional known context keys to get typing information and validation.
  ["contactType"]?: string;
  ["fields"]?: DataDictionaryField[]
  [id: string]: unknown;
};

export type Filter = {
  type: string;
  // NOTE: Would be good to populate with types if we know them.
  data?: GroupFilter|ActivityGroupFilter|any;
};

class TheValidationContext<T> implements ValidationContext<T> {
  private val: { [propName: string]: string } = {};

  constructor(private data: T) {}

  required(n: (d: T) => any) {
    
    const propName = nameOf(n);

    const res = Validators.required(this.data[propName]);
    if (res != null) this.val[propName] = res;
  }

  getValidation() {
    if (Object.keys(this.val).length > 0)
      return this.val;

    return null;
  }
}

export type FilterTermsResult = {
  field?: DataDictionaryField,
  term: string,
  requiresIndexing?: boolean
  requiresRuntimeFields?: boolean
}

type FilterStringFunction<T> = (data: T, context?: FilterContext) => string;
type FilterTermsFunction<T> = (data: T, context?: FilterContext) => FilterTermsResult[];
type ValidationFunction<T> = (validator: ValidationContext<T>, data: T) => void;

interface FilterSetup<T> {
  toFilterString: (fn: FilterStringFunction<T>) => void;
  toFilterTerms: (fn: FilterTermsFunction<T>) => void;
  validate: (fn: ValidationFunction<T>) => void;
}

class TheFilterSetup<T> implements FilterSetup<T> {
  private filterStringFn: FilterStringFunction<T> = null;
  private filterTermsFunction: FilterTermsFunction<T> = null;
  private validationFunction: ValidationFunction<T> = null;

  constructor(public t: makerOf<T>) {}

  toFilterString(fn: FilterStringFunction<T>) {
    this.filterStringFn = fn;
  }

  toFilterTerms(fn: FilterTermsFunction<T>) {
    this.filterTermsFunction = fn;
  }

  validate(fn: ValidationFunction<T>) {
    this.validationFunction = fn;
  }

  getFilterString(data: T, context?: FilterContext) {
    if (this.filterStringFn == null) {
      return "";
    }

    return this.filterStringFn(data, context) || "";
  }

  getFilterTerms(data: T, context?: FilterContext): FilterTermsResult[] {
    if (this.filterTermsFunction == null) {
      return [];
    }

    return this.filterTermsFunction(data, context) || [];
  }

  getValidation(data: T) {
    if (this.validationFunction == null) {
      return null;
    }

    const vc = new TheValidationContext(data);
    this.validationFunction(vc, data);

    return vc.getValidation();
  }
}

const registeredFilters: { [type: string]: TheFilterSetup<any> } = {};

export const registerFilter = <T>(
  type: makerOf<T>,
  setup: (d: FilterSetup<T>) => void,
) => {
  const s = new TheFilterSetup<T>(type);
  setup(s);

  registeredFilters[kebab(type.name)] = s;
};

const toDataModelAndType = (d: Filter) => {
  const rf = registeredFilters[d.type];
  if (rf == null) {
    return null;
  }

  return { model: createFrom(rf.t, d.data), registeredFilter: rf };
};

export const getFilterString = (d: Filter, context?: FilterContext): string => {
  const rfd = toDataModelAndType(d);
  if (rfd == null) {
    return "";
  }

  return rfd.registeredFilter.getFilterString(rfd.model, context);
};

export const getFilterTerms = (d: Filter, context?: FilterContext): FilterTermsResult[] => {
  const rfd = toDataModelAndType(d);
  if (rfd == null) {
    return [];
  }

  return rfd.registeredFilter.getFilterTerms(rfd.model, context);
};

export const getValidation = (d: Filter) => {
  const rfd = toDataModelAndType(d);
  if (rfd == null) {
    return null;
  }

  return rfd.registeredFilter.getValidation(rfd.model);
};

const filterCreator = (filter: Filter): Filter => {
  const dm = toDataModelAndType(filter);
  if (dm == null) {
    return null;
  }

  return { type: filter.type, data: dm.model };
};

const filterCustomCreator = (filters: Filter[]) => filters.map(filterCreator);

// the one and only built in filter
export class GroupFilter {
  operation: "OR" | "AND" = "AND";
  @fromCustom(filterCustomCreator) filters: Filter[] = [];

  toFilterString(context?: FilterContext) {
    return getFilterString({ type: "group-filter", data: this }, context);
  }

  toFilterTerms(context?: FilterContext) {
    return getFilterTerms({ type: "group-filter", data: this }, context);
  }

  get hasFilter() : boolean { return this.filters?.length > 0; }
}

// JK, This one is built in too!!
export class RawFilter {
  filter: string = null;
}

registerFilter(GroupFilter, s => {
  s.toFilterString((data, context) => {
    if (data.filters == null || data.filters.length == 0) {
      return "";
    }

    const str = data.filters.map(f => getFilterString(f, context)).join(` ${data.operation} `);
    return data.filters.length == 1 ? str : `(${str})`;
  });
  s.toFilterTerms((data, context) => {
    if (data.filters == null || data.filters.length == 0) {
      return [];
    }

    return data.filters.map(f => getFilterTerms(f, context)).reduce((paths, current) => paths.concat(current), []);
  });
});

registerFilter(RawFilter, s => {
  s.toFilterString(data => data.filter);
});

export const createFilter = <T>(t: makerOf<T> | string, setup?: (d: T) => void): Filter => {
  if (typeof t == "string") {
    const p = toDataModelAndType({ type: t, data: null });
    const model = p == null ? null : p.model;
    if (setup) {
      setup(model);
    }

    return { type: t, data: model };
  } else {
    const p = createFrom(t, null);
    if (setup) {
      setup(p);
    }

    return { type: kebab(t.name), data: p };
  }
};

export const getFormFieldValueType = (field: DataDictionaryField): ValueType => {
  if(!field.FormKey) return null;

  switch (field.DataType) {
      case QuestionType.Table:
      case QuestionType.Encrypted:
      case QuestionType.File:
      case QuestionType.PhoneNumber:
        return ValueType.AnsweredOnly;

      case QuestionType.Number:
      case QuestionType.Scale:
        return ValueType.Number;

      case QuestionType.Date:
        return ValueType.Date;

      case QuestionType.CheckBoxList:
      case QuestionType.DropDown:
      case QuestionType.RadioButton:
        return ValueType.Dropdown;

      case QuestionType.Address:
      case QuestionType.CEEBCode:
      case QuestionType.Name:
      case QuestionType.ScaleGroup:
        return ValueType.DataFields;

      default:
        return ValueType.Text;
  }
}

export const setDefaultFormFieldFilterParams = (filter: FormFieldFilter, valueType: ValueType, ffDropdownFilterAsKeyword8366: boolean) => {
switch (valueType) {
      case ValueType.AnsweredOnly:
        filter.operator = "~"; // is answered
        break;

      case ValueType.DataFields:

      case ValueType.IsOnly:
      case ValueType.Number:
        filter.operator = "="; // is
        break;

      case ValueType.Dropdown:
        if (ffDropdownFilterAsKeyword8366)
          filter.operator = "=="; // is exactly
        else
          filter.operator = "="; // is
        break;

      case ValueType.Date:
        filter.timing = TimingFilterType.Any;
        filter.operator = null;
        break;

      case ValueType.Text:
        filter.operator = "=="; // is exactly
        break;
    }
}