import { inject } from "fw";

import { ATS } from "./ats";
import { AnalyzeReferenceResponse, BedrockFoundationModel, GenerateApplicationQueryResponse, QueryExampleType } from "models/gen-ai";
import { WebSocketStore } from "state/web-socket";
import { WebSocketMessageAction } from "state/web-socket-message";
import { ConverseStreamEvent } from "models/converse-stream-event";
import { generateUUID } from "helpers/generate-uuid";

@inject
export class GenAIRepository {
  constructor(
    private s: ATS,
    private wsStore: WebSocketStore
  ) {}

  public async summarize(text: string, handler: (response: string) => void): Promise<void> {
    const { requestId, dispose } = this.subscribeToConverseStreamEvent(handler);
    await this.s.post<void>("gai/summarize", { requestId, text, modelKey: this.getModelKey() }).finally(dispose);
  }  
  
  public async generateApplicationQuery(query: string): Promise<string> {
    const res = await this.s.post<GenerateApplicationQueryResponse>("gai/generate-application-query", { query, modelKey: this.getModelKey() });
    return res.body.LuceneQuery;
  }

  public async explainApplicationQuery(query: string): Promise<string> {
    const res = await this.s.post<string>("gai/explain-application-query", { query, modelKey: this.getModelKey() });
    return res.body;
  }

  public async querySupportKnowledgeBase(query: string, handler: (response: string) => void): Promise<void> {
    const { requestId, dispose } = this.subscribeToConverseStreamEvent(handler);
    await this.s.post<string>("gai/query-support-knowledge-base", { requestId, query, modelKey: this.getModelKey() }).finally(dispose);
  }

  public async queryApplication(applicationText: string, conversationHistory: string, query: string, handler: (response: string) => void): Promise<void> {
    const { requestId, dispose } = this.subscribeToConverseStreamEvent(handler);
    await this.s.post<void>("gai/query-application", { requestId, applicationText, conversationHistory, query, modelKey: this.getModelKey() }).finally(dispose);
  }

  public async summarizeApplication(applicationText: string, handler: (response: string) => void): Promise<void> {
    const { requestId, dispose } = this.subscribeToConverseStreamEvent(handler);
    await this.s.post<void>("gai/summarize-application", { requestId, applicationText, modelKey: this.getModelKey() }).finally(dispose);
  }

  public async extractApplicationText(applicationId: string): Promise<string> {
    const res = await this.s.post<string>("gai/extract-application-text", applicationId);
    return res.body;
  }

  public async populateDataDictionaryVectorStore(): Promise<void> {
    await this.s.post<void>("gai/populate-data-dictionary-vector-store", {});
  }

  public async deleteDataDictionaryVectorStore(): Promise<void> {
    await this.s.post<void>("gai/delete-data-dictionary-vector-store", {});
  }

  public async storeQueryExample(naturalLanguageQuery: string, luceneQuery: string, type: QueryExampleType): Promise<void> {
    await this.s.post<void>("gai/store-query-example", { naturalLanguageQuery, luceneQuery, type });
  }

  public async analyzeReference(referenceId: string): Promise<string> {
    const res = await this.s.post<AnalyzeReferenceResponse>("gai/analyze-reference", { referenceId, modelKey: this.getModelKey() });
    return res.body.Response;
  }

  public async analyzeReferenceDetailed(referenceId: string, handler: (response: string) => void): Promise<void> {
    const { requestId, dispose } = this.subscribeToConverseStreamEvent(handler);
    await this.s.post<void>("gai/analyze-reference-detailed", { requestId, referenceId, modelKey: this.getModelKey() }).finally(dispose);
  }

  public async summarizeReference(referenceId: string, handler: (response: string) => void): Promise<void> {
    const { requestId, dispose } = this.subscribeToConverseStreamEvent(handler);
    await this.s.post<void>("gai/summarize-reference", { requestId, referenceId, modelKey: this.getModelKey() }).finally(dispose);
  }

  public async listAvailableFoundationModels(): Promise<BedrockFoundationModel[]> {
    const res = await this.s.get<BedrockFoundationModel[]>("gai/list-available-foundation-models");
    return res.body;
  }

  private subscribeToConverseStreamEvent(handler: (response: string) => void): { requestId: string; dispose: () => void } {
    const requestId = generateUUID();
    const subscription = this.wsStore.subscribe((message: WebSocketMessageAction<ConverseStreamEvent>) => {
      if (message.data.requestId === requestId) {
        handler(message.data.response);
      }
    });
    return { 
      requestId, 
      dispose: () => subscription.dispose()
    };
  }

  private getModelKey(): string {
    return localStorage.getItem('genai_settings_bedrock_model_key');
  }
}