import { RouterConfig, inject, needs, Navigator } from "fw";
import { dispatch } from "fw-state";
import { ApplicationStore } from "state/application";
import { CurrentUserStore, UpdateLastSettingsPageAction } from "state/current-user";
import { FeatureFlagService } from "service/feature-flag";
import { OrganizationModuleService } from "service/organization-module";
import { RequireGlobal } from "router-middleware/require-global";
import { RouterPath } from "router-middleware/router-path";
import { TeamType } from "models/team";
import { PortalInvitationsStore } from "state/portal-invitations";
import { Permissions } from "service/permission";
import { PopoverService, PopoverCoordinator } from "service/popover";
import {
  SettingsSearchResultsPopover,
  SettingsSearchResultsPopoverArgs,
} from "./settings-search-results-popover";
import { UserNavLink } from "views/components/user-nav-link";
import { SETTINGS_PAGES } from "./settings-constants";
import { CurrentOrganizationStore } from "state/current-organization";
import { RolesStore } from "state/roles";
import { CUSTOM_PERMISSIONS_PROPERTY, SPLIT_CHAR } from "./roles/edit-role";
import { allOf } from "helpers/permission";
import { OneOffService } from "service/one-off";
import { wait } from "wait";

export const TRANSFER_PROGRAMS_PLANNER_PERMISSION = "Manage Transfer Planner Programs";

class SettingsCategory {
  name: string = null;
  sections: SettingsSectionItem[] = null;
  show?: boolean = true;
  countLabel?: number = null;
  noNestedRoutes?: boolean = false;
}
class SettingsSectionItem {
  name: string = null;
  href: string = null;
  routes: string = null;
  show?: boolean = true;
  countLabel?: number = null;
  click?: Function = null;
  icon?: string = null;
}
export class SettingsSearchResult {
  categoryName: string = null;
  itemName: string = null;
  itemHref: string = null;
}

@inject
@needs(UserNavLink)
export class Settings {
  public loading = false;
  public navOpen = false;
  public search: string = "";
  public settingsCategories: SettingsCategory[] = [];
  public searchSettingsEl: HTMLElement;
  public showSideBar = false;
  private router: RouterConfig;
  private searchPopoverData = { results: [], search: "", row: 0 };
  private searchPopoverContainerEl;
  private popoverIsOpen = false;

  constructor(
    public organizationModuleService: OrganizationModuleService,
    private appStore: ApplicationStore,
    private currentUserStore: CurrentUserStore,
    private currentOrganizationStore: CurrentOrganizationStore,
    private roleStore: RolesStore,
    private portalInvitationsStore: PortalInvitationsStore,
    private ffs: FeatureFlagService,
    private permissions: Permissions,
    private popoverService: PopoverService,
    private popoverCoordinator: PopoverCoordinator,
    private nav: Navigator,
    private oneOff: OneOffService
  ) {}

  public attached() {
    this.settingsCategories = this.localSettingsCategories;

    // remove settings categories and sections that are not shown
    this.settingsCategories.forEach((c) => {
      c.sections = c.sections.filter((s) => s.show !== false);
    });
    this.settingsCategories = this.settingsCategories.filter((c) => c.sections.length && c.show !== false);

    this.popoverCoordinator.closeAll();
    setTimeout(() => {
      this.searchSettingsEl.focus();
    }, 50);
    setTimeout(() => {
      this.showSideBar = true;
    }, 500);
  }

  get portalInviteTotal() {
    return this.portalInvitationsStore.state.organizationTotal;
  }

  get hideChrome() {
    return this.appStore.state.hideChrome;
  }

  get permissionsManageMarketingSettings() {
    return this.permissions.all("ManageMarketingSettings");
  }

  get transferPlannerPermissionExists(): boolean {
    const { PropertyValues = [] } = this.currentOrganizationStore.state.organization;
    const propertyValues = PropertyValues.find(
      (item) => item.FieldKey === CUSTOM_PERMISSIONS_PROPERTY
    )?.Value;
    const permissions = propertyValues?.split(SPLIT_CHAR).map((value) => value.trim()) || [];

    return permissions.indexOf(TRANSFER_PROGRAMS_PLANNER_PERMISSION) !== -1;
  }

  get permissionManageTransferPrograms(): boolean {
    const { permissions } = this.currentUserStore.state;

    return allOf(permissions, TRANSFER_PROGRAMS_PLANNER_PERMISSION);
  }

  get isGlobalPrincipal() {
    return this.currentUserStore.state.isGlobalPrincipal;
  }

  get isGlobalAdmin() {
    return this.currentUserStore.state.isGlobalAdmin;
  }

  get isContactsRoute() {
    const routes = [
      "contact-types-routes",
      "contact-segments-routes",
      "contact-view-routes",
      "contact-routing-table-routes",
    ];

    if (this.organizationModuleService.hasContactsFeature) {
      routes.push("data-source-routes", "activity-templates-routes");
    }

    return routes.includes(this.router.current);
  }

  get isWorkflowRoute() {
    return ["settings/automation-rules", "settings/workflows"].includes(this.router.fullLocation);
  }

  get isInquiryRoute() {
    return ["settings/inquiry-portal", "settings/forms/inquiry"].includes(this.router.fullLocation);
  }

  get isMarketingRoute() {
    return this.router.fullLocation.startsWith("settings/marketing");
  }

  get isOrganizationRoute() {
    return [
      "organization-routes",
      "module-routes",
      "global-data-restrictions-routes",
      "user-routes",
      "roles-routes",
      "workspaces-routes",
      "teams-routes",
      "sso-routes",
      "event-types-routes",
    ].includes(this.router.current);
  }

  get isCollaborationRoute() {
    return [
      "settings/collaborator-roles",
      "settings/collaboration-portal",
      "settings/collaboration-modules",
      "settings/collaboration-users",
      "settings/teams/collaboration",
      "settings/routing-tables/collaboration",
    ].includes(this.router.fullLocation);
  }

  get isApplicationRoute() {
    return [
      "settings/forms/applicant",
      "settings/programs",
      "settings/program-properties",
      "settings/custom-properties",
      "settings/content",
      "settings/applicant-portal",
      "settings/student-portal",
    ].includes(this.router.fullLocation);
  }

  get isReferenceRoute() {
    return ["settings/forms/recommender", "settings/reference-portal"].includes(this.router.fullLocation);
  }

  get isImportRoute() {
    return ["settings/file-sources", "settings/application-sources"].includes(this.router.fullLocation);
  }

  get isEvaluationRoute() {
    return [
      "settings/application-view",
      "settings/forms/evaluation",
      "settings/routing-tables/evaluation",
      "settings/phases",
      "settings/segments",
      "settings/routing-tables/evaluation",
      "settings/decisions",
      "settings/decision-letters",
    ].includes(this.router.fullLocation);
  }

  get canViewImport() {
    if(this.ffs.isFeatureFlagEnabled("AppImportUI_OUT4867") ){
      return  (this.isGlobalPrincipal && this.organizationModuleService.hasReviewFeature) || this.organizationModuleService.hasApplicationSourcesPermissions
    }
    else{
      return this.isGlobalPrincipal && this.organizationModuleService.hasReviewFeature
    }
  }

  get isExportsRoute() {
    return [
      "exports-routes",
      "file-provider-routes",
      "pdf-export-template-routes",
      "translation-tables-routes",
    ].includes(this.router.current);
  }

  get isSystemRoute() {
    const routes: string[] = [];

    if (this.organizationModuleService.hasReviewFeature) {
      routes.push("email-template-routes", "payment-routes");
    }

    routes.push("function-routes");

    routes.push("vault-routes");

    if (this.customCeebData) {
      routes.push("contacts-routes");
    }

    if (this.organizationModuleService.hasContactsFeature) {
      routes.push("system-activity-routes");
    }

    if (this.isGlobalPrincipal) {
      routes.push("metrics-routes", "global-admin-routes");
    }

    return routes.includes(this.router.current);
  }

  get isDeveloperRoutes() {
    return ["developer-routes", "data-dictionary-routes"].includes(this.router.current);
  }

  get customCeebData() {
    return this.ffs.isFeatureFlagEnabled("CustomCeebData");
  }

  get collabTeamsFeature() {
    return this.ffs.isFeatureFlagEnabled("CollabTeams");
  }

  get showCollaboration() {
    return (
      this.ffs.isFeatureFlagEnabled("Collaboration") && this.organizationModuleService.hasCollaborationFeature
    );
  }

  get contactsDefaultLink() {
    return this.permissions.any("ManageContactType")
      ? "#/settings/contact-types"
      : "#/settings/contact-segments";
  }

  public get ffRoutingTables(): boolean {
    return this.permissions.all([], "Feature.RoutingTables");
  }

  public get ffCalendar(): boolean {
    return this.ffs.isFeatureFlagEnabled("CalendarModule");
  }

  public get ffStudentPortal(): boolean {
    return this.ffs.isFeatureFlagEnabled("StudentPortal");
  }

  public get ffCustomPortals(): boolean {
    return this.ffs.isFeatureFlagEnabled("CustomPortals");
  }

  public get ffGlobalDataRestrictions(): boolean {
    return this.ffs.isFeatureFlagEnabled("GlobalDataRestrictions");
  }

  public get showAllSettingPages(): boolean {
    return (
      !this.ffs.isFeatureFlagEnabled("RestrictViewToSettingsPages") ||
      this.permissions.all("ViewAllSettingsPages")
    );
  }

  public get showApplicantPortal(): boolean {
    return !this.ffs.isFeatureFlagEnabled("DeprecateApplicantPortal")
            && this.organizationModuleService.hasApplicantPortalFeature
            && (this.showAllSettingPages || this.permissions.any("ManageApplicantPortal"));
  }

  public get showWorkspaces(): boolean {
    return this.permissions.all("ManageWorkspaces");
  }

  public get showInterviewPortal(): boolean {
    if (!this.interviewPortalEnabled) {
      return false;
    }

    if (!this.ffs.isFeatureFlagEnabled("InterviewManagement")) {
      return false;
    }

    return (
      this.showAllSettingPages || this.permissions.any(["OrganizationSettings", "ManageInterviewPortal"])
    );
  }

  public get hasDecisionsFeatureEnabled() {
    return this.currentOrganizationStore.hasDecisionsFeatureEnabled;
  }

  public get reviewPortalEnabled(): boolean {
    return this.currentOrganizationStore.hasReviewPortalFeatureEnabled;
  }

  private get interviewPortalEnabled(): boolean {
    const { Features } = this.currentOrganizationStore.state.organization;

    return !!Features?.InterviewPortal;
  }

  private get showGlobalDataRestrictions(): boolean {
    return (
      this.ffGlobalDataRestrictions &&
      (this.showAllSettingPages || this.permissions.all("ManageGlobalDataRestrictions"))
    );
  }

  private get showUsers(): boolean {
    return this.showAllSettingPages || this.permissions.any(["InviteUsers", "ManageUsers"]);
  }

  private get showRoles(): boolean {
    return this.showAllSettingPages || this.permissions.all("ManageRoles");
  }

  public searchKeyUp(event) {
    const key = event.keyCode,
      enter = 13,
      up = 38,
      down = 40,
      tab = 9;

    if (key == enter) {
      if (this.searchPopoverData.results.length) {
        this.removeActiveDescendant();
        this.popoverCoordinator.closeAll();
        const res = this.searchPopoverData.results[this.searchPopoverData.row];
        this.gotoSettingsPage(res.itemHref, res.itemClick);
      }
    } else if (key == down) {
      if (this.searchPopoverData.row < this.searchPopoverData.results.length - 1) {
        this.searchPopoverData.row++;
        this.setActiveDescendant(this.searchPopoverData.row);
      }
    } else if (key == up) {
      if (this.searchPopoverData.row > 0) {
        this.searchPopoverData.row--;
        this.setActiveDescendant(this.searchPopoverData.row);
      }
    } else {
      this.searchPopoverData.row = 0;
      this.updateSearchResultsPopover();
    }
  }

  public onBlur() {
    this.removeActiveDescendant();
    this.popoverCoordinator.closeAll();
  }

  private setActiveDescendant(id: number): void {
    this.searchSettingsEl.setAttribute("aria-expanded", "true");
    this.searchSettingsEl.setAttribute("aria-activedescendant", `search-option-${id}`);
  }

  private removeActiveDescendant(): void {
    this.searchSettingsEl.setAttribute("aria-expanded", "false");
    this.searchSettingsEl.removeAttribute("aria-activedescendant");
  }

  public async updateSearchResultsPopover() {
    this.searchPopoverData.search = this.search;
    this.searchPopoverData.results = [];

    if (this.search) {
      const term = this.search.toLowerCase();

      this.settingsCategories.forEach((c) => {
        c.sections.forEach((s) => {
          if (s.name.toLowerCase().indexOf(term) != -1) {
            this.searchPopoverData.results.push(<SettingsSearchResult>{
              categoryName: c.name,
              itemName: s.name,
              itemHref: s.href,
              itemClick: s.click,
            });
          }
        });
      });
    }

    if (this.searchPopoverData.results.length) {
      this.setActiveDescendant(0);
    } else {
      this.removeActiveDescendant();
    }

    if (!this.popoverIsOpen) {
      this.openSearchResults();
    }
  }

  async openSearchResults() {
    this.popoverIsOpen = true;
    await this.popoverService.open(
      SettingsSearchResultsPopover,
      <SettingsSearchResultsPopoverArgs>{
        searchPopoverData: this.searchPopoverData,
        onClick: this.gotoSettingsPage,
      },
      document.getElementById("settings-search-results"),
      false,
      false,
      this.searchPopoverContainerEl
    );
    this.popoverIsOpen = false;
  }

  public isSettingsCategoryActive(settingsCategory: SettingsCategory): boolean {
    for (let settingsSectionItem of settingsCategory.sections) {
      if (this.isSettingsSectionItemActive(settingsSectionItem)) {
        return true;
      }
    }

    return false;
  }

  public isSettingsSectionItemActive(settingsSectionItem: SettingsSectionItem): boolean {
    if (
      this.router.fullLocation == "settings" &&
      this.router.current == "organization-routes" &&
      settingsSectionItem.routes == "organization/organization-routes"
    ) {
      return true;
    }

    const hash = window.location.hash;
    return hash && hash.startsWith("#/" + settingsSectionItem.href);
  }

  public gotoSettingsPage(url: string, click?: Function) {
    if (click) {
      click();
    } else {
      dispatch(new UpdateLastSettingsPageAction(url));
      this.search = "";
      this.searchPopoverData.row = 0;
      this.nav.navigate(`/${url}`);
    }
  }

  public get lastPage() {
    if(this.currentUserStore.state.lastPage?.startsWith("#/login?redirect=")){
      this.currentUserStore.state.lastPage="#/";
    }
    return this.currentUserStore.state.lastPage ? this.currentUserStore.state.lastPage : "#/";
  }

  public get localSettingsCategories(): SettingsCategory[] {
    return [
      {
        name: "Organization",
        countLabel: this.portalInviteTotal,
        sections: [
          {
            name: SETTINGS_PAGES.General,
            href: "settings/organization",
            routes: "organization/organization-routes",
            show: this.showRoles,
          },
          {
            name: SETTINGS_PAGES.GlobalDataRestrictions,
            href: "settings/global-data-restrictions",
            routes: "global-data-restrictions/global-data-restrictions-routes",
            show: this.showGlobalDataRestrictions,
          },
          {
            name: SETTINGS_PAGES.Users,
            href: "settings/users",
            routes: "users/user-routes",
            countLabel: this.portalInviteTotal,
            show: this.showUsers,
          },
          {
            name: SETTINGS_PAGES.GeneralRoles,
            href: "settings/roles",
            routes: "roles/roles-routes",
            show: this.showAllSettingPages || this.permissions.all("ManageRoles"),
          },
          {
            name: SETTINGS_PAGES.Workspaces,
            href: "settings/workspaces",
            routes: "workspaces/workspaces-routes",
            show: this.showWorkspaces,
          },
          {
            name: SETTINGS_PAGES.Teams,
            href: "settings/teams",
            routes: "teams/teams-routes",
            show: this.showAllSettingPages || this.permissions.any("ManageTeams"),
          },
          {
            name: SETTINGS_PAGES.TransferPlannerPrograms,
            href: "#",
            routes: "transfer-planner-programs/transfer-planner-programs-routes",
            show:
              (this.isGlobalPrincipal && this.transferPlannerPermissionExists) ||
              this.permissionManageTransferPrograms,
            click: () => this.oneOff.launchCsoProgramSso(),
            icon: "open_in_new",
          },
          {
            name: SETTINGS_PAGES.SSO,
            href: "settings/sso",
            routes: "sso/sso-routes",
            show: this.isGlobalPrincipal,
          },
        ],
      },
      {
        name: "Portals",
        show:
          this.ffStudentPortal ||
          this.reviewPortalEnabled ||
          this.showInterviewPortal ||
          this.organizationModuleService.hasContactsFeature,
        sections: [
          {
            name: SETTINGS_PAGES.InterviewPortal,
            href: "settings/interview-portal",
            routes: "interview-portal/interview-portal-routes",
            show: this.showInterviewPortal,
          },
          {
            name: SETTINGS_PAGES.StudentPortal,
            href: "settings/student-portal",
            routes: "student-portal/student-portal-routes",
            show:
              this.ffStudentPortal &&
              (this.showAllSettingPages || this.permissions.any("ManageStudentPortal")),
          },
          {
            name: SETTINGS_PAGES.ReviewPortal,
            href: "settings/review-portal",
            routes: "review-portal/review-portal-routes",
            show:
              this.reviewPortalEnabled &&
              (this.showAllSettingPages || this.permissions.any("ManageReviewPortal")),
          },
          {
            name: SETTINGS_PAGES.InquiryPortal,
            href: "settings/inquiry-portal",
            routes: "inquiry-portal/inquiry-portal-routes",
            show:
              this.organizationModuleService.hasContactsFeature &&
              (this.showAllSettingPages || this.permissions.any("ManageInquiryPortal")),
          },
        ],
      },
      {
        name: SETTINGS_PAGES.CustomPortals,
        noNestedRoutes: true,
        sections: [
          {
            name: SETTINGS_PAGES.General,
            href: "settings/custom-portals",
            routes: "custom-portals/custom-portals-routes",
            show: this.ffCustomPortals,
          },
        ],
      },

      {
        name: "Contacts",
        sections: [
          {
            name: SETTINGS_PAGES.ContactTypes,
            href: "settings/contact-types",
            routes: "contact-types/contact-types-routes",
            show: this.showAllSettingPages || this.permissions.all("ManageContactType", "Feature.Contacts"),
          },
          {
            name: SETTINGS_PAGES.ContactSegments,
            href: "settings/contact-segments",
            routes: "contact-segments/contact-segments-routes",
            show: this.showAllSettingPages || this.permissions.any("ManageContactSegments"),
          },
          {
            name: SETTINGS_PAGES.ContactSummaryViews,
            href: "settings/contact-views",
            routes: "contact-view/contact-view-routes",
            show:
              this.showAllSettingPages ||
              this.permissions.any("ManageContactSummaryViews", "Feature.Contacts"),
          },
          {
            name: SETTINGS_PAGES.ContactRoutingTables,
            href: "settings/contact-routing-tables",
            routes: "contact-routing-tables/contact-routing-table-routes",
            show:
              this.organizationModuleService.hasContactsFeature &&
              (this.showAllSettingPages || this.permissions.any("ManageContactRoutingTables")),
          },
          {
            name: SETTINGS_PAGES.ContactWorkflows,
            href: "settings/workflows?objectType=Contact",
            routes: "workflow/workflow-routes",
            show:
              this.organizationModuleService.hasWorkflowFeature &&
              (this.showAllSettingPages || this.permissions.any("ManageContactWorkflows")),
          },
          {
            name: SETTINGS_PAGES.PendingContactsPage,
            href: "settings/pending-contacts",
            routes: "pending-contacts/pending-contacts-routes",
            show:
              this.ffs.isFeatureFlagEnabled("PendingContactsPage") &&
              (this.showAllSettingPages || this.permissions.any("ManagePendingContacts")),
          },
          {
            name: SETTINGS_PAGES.InquiryForms,
            href: "settings/forms/inquiry",
            routes: "forms/form-routes",
            show:
              this.organizationModuleService.hasContactsFeature &&
              (this.showAllSettingPages || this.permissions.any(["ManageInquiryForms"])),
          },
          {
            name: SETTINGS_PAGES.ActivityTemplates,
            href: "settings/activity-templates",
            routes: "activity-templates/activity-templates-routes",
            show:
              this.organizationModuleService.hasContactsFeature &&
              this.isGlobalPrincipal &&
              this.isGlobalAdmin,
          },
          {
            name: SETTINGS_PAGES.ContactEmailTemplates,
            href: "settings/contact-email-templates",
            routes: "contact-email-templates/contact-email-templates-routes",
            show:
              this.ffs.isFeatureFlagEnabled("ContactEmailTemplates") &&
              (this.showAllSettingPages || this.permissions.all("ManageContactEmailTemplates")),
          },
        ],
      },
      {
        name: "Application Setup",
        sections: [
          {
            name: SETTINGS_PAGES.Programs,
            href: "settings/programs",
            routes: "program/program-routes",
            show: this.showAllSettingPages || this.permissions.any("ManagePrograms"),
          },
          {
            name: SETTINGS_PAGES.ProgramProperties,
            href: "settings/program-properties",
            routes: "program-properties/program-properties-routes",
            show: this.showAllSettingPages || this.permissions.any("ManageApplicationSettings"),
          },
          {
            name: SETTINGS_PAGES.ApplicationProperties,
            href: "settings/custom-properties",
            routes: "custom-properties/custom-properties-routes",
            show: this.showAllSettingPages || this.permissions.any("ManageApplicationSettings"),
          },
          {
            name: SETTINGS_PAGES.ApplicantForms,
            href: "settings/forms/applicant",
            routes: "forms/form-routes",
            show: this.showAllSettingPages || this.permissions.any("ManageApplicantForms"),
          },
          {
            name: SETTINGS_PAGES.ApplicantPortal,
            href: "settings/applicant-portal",
            routes: "applicant-portal/applicant-portal-routes",
            show: this.showApplicantPortal
          },
          {
            name: SETTINGS_PAGES.ReferenceForms,
            href: "settings/forms/recommender",
            routes: "form-routes",
            show:
              this.organizationModuleService.hasReferencesFeature &&
              (this.showAllSettingPages || this.permissions.any("ManageReferenceForms")),
          },
          {
            name: SETTINGS_PAGES.ReferencePortal,
            href: "settings/reference-portal",
            routes: "reference-portal/reference-portal-routes",
            show:
              this.organizationModuleService.hasReferencesFeature &&
              (this.showAllSettingPages || this.permissions.any("ManageReferencePortal")),
          },
          {
            name: SETTINGS_PAGES.ApplicationEmailTemplates,
            href: "settings/application-email-templates",
            routes: "application-email-templates/application-email-templates-routes",
            show:
              this.ffs.isFeatureFlagEnabled("ApplicationEmailTemplates") &&
              (this.showAllSettingPages || this.permissions.all("ManageApplicationEmailTemplates")),
          },
          {
            name: SETTINGS_PAGES.ContentTemplates,
            href: "settings/content",
            routes: "content/content-routes",
            show: this.showAllSettingPages || this.permissions.any("ManageContentTemplates"),
          },
          {
            name: SETTINGS_PAGES.StageTemplates,
            href: "settings/program-stage-templates",
            routes: "program-stage-templates/program-stage-template-routes",
            show: this.showAllSettingPages || this.permissions.any("ManagePrograms"),
          },
        ],
      },
      {
        name: "Application Review",
        sections: [
          {
            name: SETTINGS_PAGES.ApplicationSummaryView,
            href: "settings/application-view",
            routes: "application-view/application-view-routes",
            show:
              this.organizationModuleService.hasReviewFeature &&
              (this.showAllSettingPages || this.permissions.any("ManageApplicationSettings")),
          },
          {
            name: SETTINGS_PAGES.EvaluationForms,
            href: "settings/forms/evaluation",
            routes: "form-routes",
            show:
              this.organizationModuleService.hasReviewFeature &&
              (this.showAllSettingPages || this.permissions.any("ManageEvaluationForms")),
          },
          {
            name: SETTINGS_PAGES.Decisions,
            href: "settings/decisions",
            routes: "decisions/decisions-routes",
            show:
              this.organizationModuleService.hasReviewFeature &&
              this.hasDecisionsFeatureEnabled &&
              (this.showAllSettingPages || this.permissions.any("ManageDecisionSettings")),
          },
          {
            name: SETTINGS_PAGES.DecisionLetters,
            href: "settings/decision-letters",
            routes: "decision-letters/decision-letters-routes",
            show:
              this.organizationModuleService.hasReviewFeature &&
              this.hasDecisionsFeatureEnabled &&
              (this.showAllSettingPages || this.permissions.any("ManageDecisionLetterTemplate")),
          },
          {
            name: SETTINGS_PAGES.Phases,
            href: "settings/phases",
            routes: "phases/phases-routes",
            show:
              this.organizationModuleService.hasReviewFeature &&
              (this.showAllSettingPages || this.permissions.any("ManageEvaluationPhases")),
          },
          {
            name: SETTINGS_PAGES.ApplicationSegments,
            href: "settings/segments",
            routes: "segments/segments-routes",
            show:
              this.organizationModuleService.hasReviewFeature &&
              (this.showAllSettingPages || this.permissions.any("ManageApplicationSegments")),
          },
          {
            name: SETTINGS_PAGES.ReviewRoutingTables,
            href: "settings/routing-tables/evaluation",
            routes: "routing-tables/routing-table-routes",
            show:
              this.organizationModuleService.hasReviewFeature &&
              this.ffRoutingTables &&
              (this.showAllSettingPages || this.permissions.any("ManageEvaluationPhases")),
          },
          {
            name: SETTINGS_PAGES.Automation,
            href: "settings/automation-rules",
            routes: "automation-rules/automation-rules-routes",
            show:
              this.organizationModuleService.hasWorkflowFeature &&
              (this.showAllSettingPages || this.permissions.any("ManageAutomationRules")),
          },
          {
            name: SETTINGS_PAGES.ApplicationWorkflows,
            href: "settings/workflows?objectType=Application",
            routes: "workflow/workflow-routes",
            show:
              this.organizationModuleService.hasWorkflowFeature &&
              (this.showAllSettingPages || this.permissions.any("ManageAutomationRules")),
          },
        ],
      },
      {
        name: "Marketing",
        show: this.organizationModuleService.hasMarketingFeature && this.permissionsManageMarketingSettings,
        sections: [
          {
            name: SETTINGS_PAGES.EmailInfo,
            href: "settings/marketing/email-info",
            routes: "marketing/email-info-routes",
          },
          {
            name: SETTINGS_PAGES.TextAndVoice,
            href: "settings/marketing/voice",
            routes: "marketing/voice-routes",
          },
          {
            name: SETTINGS_PAGES.Conversations,
            href: "settings/marketing/conversations",
            routes: "marketing/conversations-routes",
            show: this.ffs.isFeatureFlagEnabled("SMSConversationsInbox"),
          },
          {
            name: SETTINGS_PAGES.SuppressedItems,
            href: "settings/marketing/suppressed-items",
            routes: "marketing/suppressed-items",
            show: this.organizationModuleService.hasSuppressedItemsFeature,
          },
        ],
      },
      {
        name: "Calendar",
        show: this.ffs.isFeatureFlagEnabled("CalendarModule"),
        sections: [
          {
            name: SETTINGS_PAGES.CalendarEventTypes,
            href: "settings/event-types",
            routes: "event-types/event-types-routes",
            show:
              this.ffs.isFeatureFlagEnabled("CalendarModule") &&
              (this.showAllSettingPages || this.permissions.any("ManageEventTypes")),
          },
          {
            name: SETTINGS_PAGES.CalendarInquiryForms,
            href: "settings/forms/calendar-inquiry",
            routes: "forms/form-routes",
            show:
              this.organizationModuleService.hasReviewFeature &&
              this.organizationModuleService.hasContactsFeature &&
              (this.showAllSettingPages || this.permissions.any(["ManageInquiryForms"])),
          },
        ],
      },
      {
        name: "Collaboration",
        show: this.showCollaboration,
        sections: [
          {
            name: SETTINGS_PAGES.Portals,
            href: "settings/collaboration-portal",
            routes: "collaboration-portal/collaboration-portal-routes",
            show: this.showAllSettingPages || this.permissions.any("ManageCollaboration"),
          },
          {
            name: SETTINGS_PAGES.Modules,
            href: "settings/collaboration-modules",
            routes: "collaboration-modules/collaboration-modules-routes",
            show: this.showAllSettingPages || this.permissions.any("ManageCollaboration"),
          },
          {
            name: SETTINGS_PAGES.CollaborationRoles,
            href: "settings/collaborator-roles",
            routes: "collaborator-roles/collaborator-roles-routes",
            show:
              !this.collabTeamsFeature &&
              (this.showAllSettingPages || this.permissions.any("ManageCollaboration")),
          },
          {
            name: SETTINGS_PAGES.CollaborationTeams,
            href: "settings/teams/collaboration",
            routes: "teams-routes",
            show:
              this.collabTeamsFeature && (this.showAllSettingPages || this.permissions.any("ManageTeams")),
          },
          {
            name: SETTINGS_PAGES.CollaborationRoutingTables,
            href: "settings/routing-tables/collaboration",
            routes: "routing-table-routes",
            show:
              this.ffRoutingTables &&
              (this.showAllSettingPages || this.permissions.any("ManageCollaboration")),
          },
        ],
      },
      {
        name: "Import/Export",
        sections: [
          {
            name: SETTINGS_PAGES.ContactDataSources,
            href: "settings/integrations/data-sources",
            routes: "integrations/data-sources/data-sources-routes",
            show:
              this.organizationModuleService.hasContactsFeature &&
              (this.showAllSettingPages || this.permissions.any("ManageContactDataSources")),
          },
          {
            name: SETTINGS_PAGES.FileImportSources,
            href: "settings/file-sources",
            routes: "file-source/file-source-routes",
            show: this.isGlobalPrincipal && this.organizationModuleService.hasReviewFeature,
          },
          {
            name: SETTINGS_PAGES.ApplicationImportSources,
            href: "settings/application-sources",
            routes: "application-source/application-source-routes",
            show: this.canViewImport
          },
          {
            name: SETTINGS_PAGES.Exports,
            href: "settings/exports",
            routes: "exports/exports-routes",
            show: this.showAllSettingPages || this.permissions.any(["ManageExports", "GenerateExports"]),
          },
          {
            name: SETTINGS_PAGES.ExportDestinations,
            href: "settings/export-destinations",
            routes: "file-provider/file-provider-routes",
            show: this.showAllSettingPages || this.permissions.any("ManageExports"),
          },
          {
            name: SETTINGS_PAGES.ExportPDFDesign,
            href: "settings/pdf-export-templates",
            routes: "pdf-export-templates/pdf-export-template-routes",
            show: this.isGlobalPrincipal && this.organizationModuleService.hasReviewFeature,
          },
          {
            name: SETTINGS_PAGES.TranslationTables,
            href: "settings/translation-tables",
            routes: "translation-tables/translation-tables-routes",
            show: this.showAllSettingPages || this.permissions.all("ManageExports"),
          },
          {
            name: SETTINGS_PAGES.FieldDictionary,
            href: "settings/field-dictionary",
            routes: "data-dictionary/data-dictionary-routes",
            show: true,
          },
        ],
      },
      {
        name: "System",
        sections: [
          {
            name: SETTINGS_PAGES.EmailTemplates,
            href: "settings/email-templates",
            routes: "email-templates/email-template-routes",
            show:
              (this.organizationModuleService.hasReviewFeature &&
                (this.showAllSettingPages || this.permissions.all("ManageEmailSettings")) &&
                !this.ffs.isFeatureFlagEnabled("ApplicationEmailTemplates")) ||
              (this.ffs.isFeatureFlagEnabled("ApplicationEmailTemplates") &&
                this.permissions.all("ManageSystemEmailTemplates")),
          },
          {
            name: SETTINGS_PAGES.PromptTemplates,
            href: "settings/prompt-templates",
            routes: "prompt-templates/prompt-template-routes",
            show: this.currentUserStore.state.isGlobalAdmin && sessionStorage.getItem("experimentalFeatures") === "true"
          },
          {
            name: SETTINGS_PAGES.Payments,
            href: "settings/payments",
            routes: "payments/payment-routes",
            show:
              this.showAllSettingPages ||
              this.permissions.any("ManagePaymentProcessors", "Feature.Payments") ||
              (this.ffs.isFeatureFlagEnabled("PaymentJournal") && this.permissions.any("ViewPaymentDetails")),
          },
          {
            name: SETTINGS_PAGES.APIKeys,
            href: "settings/developer",
            routes: "developer/developer-routes",
            show: this.showAllSettingPages || this.permissions.all("OrganizationSettings"),
          },
          {
            name: SETTINGS_PAGES.KeyVault,
            href: "settings/key-vault",
            routes: "vault/vault-routes",
            show: this.showAllSettingPages || this.permissions.any("ManageVaults"),
          },
          {
            name: SETTINGS_PAGES.SystemActivity,
            href: "settings/system-activity",
            routes: "system-activity/system-activity-routes",
            show:
              (this.isGlobalAdmin || this.isGlobalPrincipal) &&
              this.organizationModuleService.hasContactsFeature &&
              (this.showAllSettingPages || this.permissions.all("OrganizationSettings")),
          },
          {
            name: SETTINGS_PAGES.CEEBData,
            href: "settings/integrations/contacts",
            routes: "integrations/contacts/contacts-routes",
            show:
              this.customCeebData &&
              (this.showAllSettingPages || this.permissions.all("OrganizationSettings")),
          },
          {
            name: SETTINGS_PAGES.FunctionLibrary,
            href: "settings/functions",
            routes: "functions/function-routes",
            show:
              (this.isGlobalPrincipal && this.isGlobalAdmin) || this.permissions.all("OrganizationSettings"),
          },
          {
            name: SETTINGS_PAGES.Metrics,
            href: "settings/metrics",
            routes: "metrics/metrics-routes",
            show: this.isGlobalPrincipal,
          },
          {
            name: SETTINGS_PAGES.GlobalAdmin,
            href: "settings/global-admin",
            routes: "global-admin/global-admin-routes",
            show: this.isGlobalPrincipal,
          },
        ],
      },
    ];
  }
  async registerRoutes(r: RouterConfig) {
    r.addMiddleware(RequireGlobal);
    r.addMiddleware(RouterPath);
    this.router = r;

    r.add("", () => import("./settings-routes"));
    r.add("global-admin", () => import("./global-admin/global-admin-routes"), {
      global: true,
    });
    r.add("organization", () => import("./organization/organization-routes"));
    r.add("modules", () => import("./modules/module-routes"));
    r.add("email-templates", () => import("./email-templates/email-template-routes"));
    r.add(
      "application-email-templates",
      () => import("./application-email-templates/application-email-templates-routes")
    );
    r.add(
      "contact-email-templates",
      () => import("./contact-email-templates/contact-email-templates-routes")
    );

    r.add("automation-rules", () => import("./automation-rules/automation-rules-routes"));

    r.add("workflows", () => import("./workflow/workflow-routes"));

    r.add("pending-contacts", () => import("./pending-contacts/pending-contacts-routes"));

    r.add("integrations/contacts", () => import("./integrations/contacts/contacts-routes"));
    r.add("integrations/data-sources", () => import("./integrations/data-sources/data-sources-routes"));

    r.add("programs", () => import("./program/program-routes"));
    r.add("program-properties", () => import("./program-properties/program-properties-routes"));

    r.add("forms", () => import("./forms/form-routes"));
    r.add("content", () => import("./content/content-routes"));

    r.add(
      "program-stage-templates",
      () => import("./program-stage-templates/program-stage-templates-routes")
    );

    r.add("custom-portals", () => import("./custom-portals/custom-portals-routes"));
    r.add("custom-properties", () => import("./custom-properties/custom-properties-routes"));

    if (this.showApplicantPortal) {
      r.add("applicant-portal", () => import("./applicant-portal/applicant-portal-routes"));
    }

    r.add("student-portal", () => import("./student-portal/student-portal-routes"));
    r.add("review-portal", () => import("./review-portal/review-portal-routes"));
    r.add("reference-portal", () => import("./reference-portal/reference-portal-routes"));

    r.add("routing-tables", () => import("./routing-tables/routing-table-routes"));

    r.add("application-view", () => import("./application-view/application-view-routes"));

    r.add("inquiry-portal", () => import("./inquiry-portal/inquiry-portal-routes"));
    r.add("segments", () => import("./segments/segments-routes"));
    r.add("phases", () => import("./phases/phases-routes"));
    r.add("teams", () => import("./teams/teams-routes"), {
      teamType: TeamType.Evaluation,
    });
    r.add("exports", () => import("./exports/exports-routes"));
    r.add("export-destinations", () => import("./file-provider/file-provider-routes"));
    r.add("pdf-export-templates", () => import("./pdf-export-templates/pdf-export-template-routes"));
    r.add("translation-tables", () => import("./translation-tables/translation-tables-routes"));
    r.add("payments", () => import("./payments/payment-routes"));

    r.add("developer", () => import("./developer/developer-routes"));
    r.add("field-dictionary", () => import("./data-dictionary/data-dictionary-routes"));

    r.add("functions", () => import("./functions/function-routes"));

    r.add("key-vault", () => import("./vault/vault-routes"));

    r.add("collaboration-portal", () => import("./collaboration-portal/collaboration-portal-routes"));
    r.add("collaboration-modules", () => import("./collaboration-modules/collaboration-modules-routes"));
    r.add("collaboration-users", () => import("./collaboration-users/collaboration-users-routes"));
    r.add("collaborator-roles", () => import("./collaborator-roles/collaborator-roles-routes"));

    // TODO: no link?
    r.add("applicant-properties", () => import("./applicant-properties/applicant-properties-routes"));

    r.add("contact-types", () => import("./contact-types/contact-types-routes"));
    r.add("contact-segments", () => import("./contact-segments/contact-segments-routes"));
    r.add("contact-views", () => import("./contact-view/contact-view-routes"));
    r.add("contact-routing-tables", () => import("./contact-routing-tables/contact-routing-table-routes"));

    r.add("metrics", () => import("./metrics/metrics-routes"), {
      global: true,
    });

    r.add("activity-templates", () => import("./activity-templates/activity-templates-routes"), {
      globalAdmin: true,
    });

    r.add("decisions", () => import("./decisions/decisions-routes"));
    r.add("decision-letters", () => import("./decision-letters/decision-letters-routes"));

    r.add("marketing", () => import("./marketing/marketing-routes"));

    if (this.showGlobalDataRestrictions) {
      r.add(
        "global-data-restrictions",
        () => import("./global-data-restrictions/global-data-restrictions-routes")
      );
    }

    if (this.showUsers) {
      r.add("users", () => import("./users/user-routes"));
    }
    if (this.showRoles) {
      r.add("roles", () => import("./roles/roles-routes"));
    }

    if (this.showWorkspaces) {
      r.add("workspaces", () => import("./workspaces/workspaces-routes"));
    }

    if (this.showInterviewPortal) {
      r.add("interview-portal", () => import("./interview-portal/interview-portal-routes"));
    }

    if (this.isGlobalPrincipal) {
      r.add("sso", () => import("./sso/sso-routes"));
      r.add("file-sources", () => import("./file-source/file-source-routes"));
      r.add("application-sources", () => import("./application-source/application-source-routes"));
    }

    if (this.canViewImport){
      r.add("application-sources", () => import("./application-source/application-source-routes"));
    }

    if (this.ffs.isFeatureFlagEnabled("CalendarModule")) {
      r.add("event-types", () => import("./event-types/event-types-routes"));
    }

    if (this.isGlobalAdmin || this.isGlobalPrincipal) {
      r.add("system-activity", () => import("./system-activity/system-activity-routes"));
    }

    if (this.currentUserStore.state.isGlobalAdmin && sessionStorage.getItem("experimentalFeatures") === "true") {
        r.add("prompt-templates", () => import("./prompt-templates/prompt-template-routes"));
      }

    await wait(100);
  }
}
