import { inject, prop, ComponentEventBus } from "@derekpitt/fw"

import { Question, FormAnswer } from "../../models";
import { checkLongText } from "../../helpers";
import { DialogService } from "fw-dialog";
import { ViewMore } from "../view-more";

@inject
export class LongTextType {
  @prop(null) question!: Question;
  @prop(null) answer!: FormAnswer;
  @prop(false) readonly!: boolean;
  @prop(false) displayonly!: boolean;
  @prop(false) compact!: boolean;
  @prop(null) ariaLabelledby;
  @prop(false) isDocumentView: boolean;
  @prop(false) useTruncate: boolean;

  @prop(null) requestCeeb;
  @prop(null) fileHash;
  @prop(null) fileService;
  @prop(null) showOptions;
  @prop(null) decryptData;

  @prop(null) summarize: Function;

  constructor(
    private ecb: ComponentEventBus,
    private dialogServer: DialogService
  ) { }

  public get longText() {
    return checkLongText(this.answer.Text, this.isDocumentView);
  }

  onClick(event) {
    const handleBlur = () => this.onBlur(
      () => event.target.removeEventListener("blur", handleBlur)
    );
    event.target.addEventListener("blur", handleBlur);
  }

  onBlur(cb)  {
    this.answer.Text = this.answer.Text?.trim();
    this.change();
    cb();
  }

  onReadMore() {
    this.dialogServer.open(ViewMore, {
      title: this.question.Text,
      text: this.answer.Text,
    }, {
      closeOnClick: true,
      cssClass: "long-text-preview-dialog",
    });
  }

  onSummarize(event) {
    this.summarize(this.answer.Text);
  }

  change() {
    this.ecb.dispatch("answer-changed");
  }
}
