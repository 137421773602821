import { prop, inject, ComponentEventBus } from "fw";
import { Completer, getTokenWord, replaceVariable, VariablePicked, CompletionItem } from "helpers/auto-complete";
import { FunctionClientModelCompleter } from "helpers/completers/function-client-model";
import { CustomFieldType, defaultCompletionFieldTypes } from "models/contact-organization";
import { FunctionType } from "models/function";
import { PopoverService, PopoverCoordinator } from "service/popover";
import { EditorPopover } from "views/components/editor-popover";
import autosize from "autosize";
import offset from "document-offset";
import { unionBy } from "lodash-es";

let uniqueId = 0;

@inject
export class TextareaField {
  @prop(null) public label!: string;
  @prop("") public placeholder!: string;
  @prop(null) public value;
  @prop(null) public validation!: string;
  @prop(null) public type!: "text";
  @prop("1") public rows!: number;
  @prop(null) public maxlength!: number;
  @prop(false) public showCharactersRemaining!: boolean;
  @prop(false) public showWordCount!: boolean;
  @prop(null) public readonly!: boolean;
  @prop(null) public setfocus;
  @prop(false) public disabled!: boolean;
  @prop(false) public floatingLabel;
  @prop(null) public ariaLabel!: string;
  @prop(null) public helpText!: string;
  @prop(null) public completer!: Completer | Completer[];
  @prop(null) public contactType!: string;
  @prop(null) public meta!: string;
  @prop(() => defaultCompletionFieldTypes) completionTypes: Array<CustomFieldType | FunctionType>;
  @prop(true) public handlebars: boolean;

  private uniqueId = uniqueId++;
  private textareaRef;
  private hinting: boolean = false;
  private functions: CompletionItem[] = null;
  public isFocused: boolean = false;
  public charactersRemaining: number = 0;
  public wordCount: number = 0;

  constructor(
    private componentEventBus: ComponentEventBus,
    private popover: PopoverService,
    private coordinator: PopoverCoordinator
  ) {}

  public makeId() {
    return `${ this.label ? this.label.replace(/\s/g,'') : "" }-taf-${ this.uniqueId }`
  }

  public onFocus() { this.isFocused = true; }
  public onBlur() {
    this.onInput(this.value?.trim());
    this.isFocused = false;
  }

  public async attached() {
    autosize(this.textareaRef);
    this.charactersRemaining = ! this.maxlength ? 0 : this.maxlength - this.textareaRef.value.length;
    this.wordCount = this.countWords(this.textareaRef.value);

    const completer = (!Array.isArray(this.completer) ? [this.completer] : this.completer)
      .find(i => i instanceof FunctionClientModelCompleter);
    this.functions = await completer?.getCompletions("", "", this.completionTypes, this.contactType, this.handlebars);
  }

  public valueChanged(value: string) {
    this.textareaRef.value = value;
    autosize.update(this.textareaRef);
    this.charactersRemaining = ! this.maxlength ? 0 : this.maxlength - this.textareaRef.value.length;
    this.wordCount = this.countWords(this.textareaRef.value);
  }

  private countWords(str: string): number {
    const s = str.trim();

    return s ? s.split(/\s+/).length : 0;
  }

  public onChange() { this.componentEventBus.dispatch("change"); }
  public onKeyEvent(event) { this.componentEventBus.dispatch("keypress", event); }

  public onKeyDown(e) {
    if (this.completer) {
      this.handleKeyEvent(e, "keyDown");
    }
  }

  public onKeyUp(e) {
    if (this.completer) {
      this.handleKeyEvent(e, "keyUp");
    }

    this.componentEventBus.dispatch("keyup");
  }

  public onInput(value: string) {
    this.componentEventBus.dispatch("update:value", value);
  }

  public handleKeyEvent(e: KeyboardEvent, type: string) {
    const key = e.keyCode, enter = 13, up = 38, down = 40;
    const specialPressed = key == enter || key == up || key == down;

    if (type === "keyDown" && this.hinting && specialPressed) e.preventDefault();
    else if (type === "keyUp" && (!this.hinting || !specialPressed)) {
      this.coordinator.closeAll();
      this.hinting = false;
      this.hintVariables();
    }
  }

  private async hintVariables() {
    if (!this.completer) return null;

    const tw = getTokenWord(this.textareaRef.value, this.textareaRef.selectionStart, this.textareaRef.selectionEnd);
    if (!tw) return null;

    const completer = !Array.isArray(this.completer) ? [this.completer] : this.completer;
    const getCompletions = async i => i.getCompletions(tw.token, tw.word, this.completionTypes, this.contactType, this.handlebars);
    const variables = unionBy.apply(this, [
      ...(await Promise.all(completer.map(getCompletions))),
      "token",
    ]);
    if (!variables.length || !variables[0] || (variables.length === 1 && variables[0].token === tw.word)) return null;

    // make a marker where the popover should appear
    const textareaPosition = offset(this.textareaRef);
    const textareaCaret = (await import("textarea-caret")) as any;
    const position = textareaCaret.default(this.textareaRef, this.textareaRef.selectionEnd);
    const marker = document.createElement("span");
    marker.style.top = `${textareaPosition.top + position.height + 10}px`;
    marker.style.left = `${textareaPosition.left + position.left + 10}px`;
    marker.style.position = "absolute";
    document.body.append(marker);

    this.hinting = true;
    const data = { variables: variables };
    const variablePicked = await this.popover.open<VariablePicked>(EditorPopover, data, marker, false, false);
    marker.remove();

    if (variablePicked.result) {
      const { variable } = variablePicked.result;
      const value = this.textareaRef.value;
      const tw = getTokenWord(value, this.textareaRef.selectionStart, this.textareaRef.selectionEnd);
      const { newValue, newPosition } = replaceVariable(variable, value, tw);

      this.textareaRef.focus();
      this.onInput(newValue);

      this.insertValue(newValue, newPosition);
    }
  }

  private async selectFunction() {
    const fnPicked = await this.popover.open<VariablePicked>(
      EditorPopover, { variables: this.functions }
    );
    if (!fnPicked.result) return;

    const fn = fnPicked.result.variable;
    const currentValue = this.textareaRef.value;
    const startPos = this.textareaRef.selectionStart;
    const endPos = this.textareaRef.selectionEnd;
    const newValue = [
        currentValue.substring(0, startPos),
        `{{ ${fn.token} }}`,
        currentValue.substring(endPos, currentValue.length)
    ].join('');

    // the 6 represents the length of the opening and closing brackets with spaces
    this.insertValue(newValue, endPos + fn.token.length + 6);
  }

  private insertValue(value: string, position: number) {
    this.textareaRef.focus();
    this.onInput(value);

    setTimeout(() => {
      this.textareaRef.selectionEnd = position;
      this.valueChanged(value);
      this.hinting = false;
      this.hintVariables();
    }, 1);
  }
}
