import { inject, prop } from "fw";
import { Form } from "fw-model";
import moment from "moment";
import "moment-timezone";

import { DateService } from "service/date-service";
import { preventLeadingSpaces } from "service/keyboard-press";
import { CurrentOrganizationStore } from "state/current-organization";

@inject
export class DatetimeFormField {
  @prop(null) private form!: Form;
  @prop(null) private field;
  @prop("MM/DD/YYYY") private format!: string;
  @prop("toISOString") private syncFormat!: string;
  @prop(true) private useTimezone!: boolean;
  @prop(null) private floatingLabel;
  @prop(null) private autocomplete;
  @prop(null) private ariaLabel;
  @prop(null) private label;
  @prop(false) public required: boolean;
  @prop(false) public disabled: boolean;
  @prop(false) public hideLabel!: boolean;
  @prop(false) public useUtc!: boolean;
  @prop('') public preservedTime!: string;

  private name = "";
  private formValue = "";
  private timezone = "America/Chicago";
  private onlyDateISO: RegExp = /(\d{4})-(\d{2})-(\d{2})$/s;

  constructor(
      private currentOrganizationStore: CurrentOrganizationStore,
      private dateService: DateService
    ) {
    this.timezone = this.currentOrganizationStore.state.organization.Timezone || this.timezone;
  }

  public attached() {
    this.timezone = this.useUtc ? "UTC" : this.timezone;
    this.formChanged();
  }

  onKeyEvent(e: KeyboardEvent) {
    preventLeadingSpaces(e, this.formValue);
  }

  private formChanged() {
    this.name = this.form && this.form.getFieldName ? this.form.getFieldName(this.field) : this.field;

    if (this.form) {
      let date = moment(this.form[this.field], moment.ISO_8601);
      if (this.useTimezone) date = date.tz(this.timezone);
      this.formValue = date.isValid() ? date.format(this.format) : "";
    }
  }

  private valueChange() {
    const value = this.formValue?.trim();
    const parsed = this.dateService.parseDateOnly(value);
    if (parsed) {
      const date = this.useTimezone? moment.tz(parsed, this.timezone): moment(parsed, moment.ISO_8601);
      if (!!this.preservedTime) {
        // add the original time back to the date we just generated
        const parsedTime = this.dateService.parseTimeOnly(this.preservedTime);
        const timeDuration = moment.duration(parsedTime);
        date.add(timeDuration);
      }
      if (this.form && date.isValid()) {
        this.formValue = date.format(this.format);
        const syncFormatValue = this.syncFormat == "toISOString" ? date.toISOString() : date.format(this.syncFormat);
        // Remove empty milliseconds to avoid false positives in form dirtiness checks caused by discrepancies
        // between toISOString format and the format in which we save datetimes with no time component.
        // ("2023-09-03T05:00:00.000Z" vs "2023-09-03T05:00:00Z")
        this.form[this.field] = syncFormatValue.replace(/\.000Z$/, 'Z');
        return;
      }
    }

    this.form[this.field] = value;
  }
}
