import { inject } from "fw";
import { Store, dispatch, handle } from "fw-state";
import { groupBy, difference, orderBy } from "lodash-es";

import { once } from "helpers/once";
import { ContactSegment, toClientData } from "models/contact-segment";
import { ContactSegmentRepository } from "network/contact-segment-repository";
import { LogoutAction, StartAction } from "./actions";
import { FormErrorHandling } from "./error-handling";

import {
  CreateContactSegmentAction,
  UpdateContactSegmentAction
} from "forms/contact-segment";

interface ContactSegmentStoreShape {
  categories: { [categoryName: string]: ContactSegment[] };
  segmentFilterString: string,
  segments: ContactSegment[];
  selectedCategory: string;
}

export class EnsureContactSegmentStoreAction {
  constructor() { }
}

export class ContactSegmentCreatedAction {
  constructor(public segment: ContactSegment) { }
}

export class ContactSegmentUpdatedAction {
  constructor(public segment: ContactSegment) { }
}

export class ContactSegmentsDeletedAction {
  constructor(public segmentIds: string[]) { }
}

export class ContactSegmentDeleteAction {
  constructor(public segmentIds: string[]) { }
}

export class SelectCurrentContactSegmentCategoryAction {
  constructor(public selectedCategory: string) { }
}

export class ContactSegmentCategorizeAction {
  constructor(public segmentIds: string[], public category: string) { }
}

export class SetContactSegmentFilterStringAction {
  constructor(public segmentFilterString: string) { }
}

@inject
export class ContactSegmentStore extends Store<ContactSegmentStoreShape> {
  constructor(private contactSegmentRepository: ContactSegmentRepository) {
    super();
  }

  defaultState() {
    return {
      categories: {},
      segmentFilterString: '',
      segments: [],
      selectedCategory: null,
    };
  }

  private computeCategories(segments: ContactSegment[]): { [categoryName: string]: ContactSegment[] } {
    return groupBy(segments.filter(s => s.category != null), s => s.category);
  }

  @handle(StartAction)
  private handleStartAction(action: StartAction) {
    this.setState(state => ({
      ...this.defaultState(),
      organizationId: action.context.ContactOrganization.id,
      userId: action.context.Me.Id,
    }));
  }

  @handle(LogoutAction)
  private handleLogoutAction() {
    this.setState(s => this.defaultState());
  }

  @handle(EnsureContactSegmentStoreAction)
  private async handleEnsureContactSegmentStoreAction(action: EnsureContactSegmentStoreAction) {
    await once("ensure-contact-segments", async () => {
      const response = await this.contactSegmentRepository.list(null, null, null, null, null, 1000);

      this.setState(state => ({
        ...state,
        segments: orderBy(response.results, [s => s.display_name.toLowerCase()]),
        categories: this.computeCategories(response.results),
      }));
    });
  }

  @handle(CreateContactSegmentAction, FormErrorHandling)
  private async handleCreateContactSegmentAction(
    action: CreateContactSegmentAction
  ) {
    action.form.validate();

    const model = action.form.updatedModel();
    model.client_data = toClientData(model.client_data);
    
    const newSegment = await this.contactSegmentRepository.post(model);

    action.form.applyModel(newSegment);
    action.createdId = newSegment.id;
    const segments = [...this.state.segments, newSegment];

    this.setState(state => ({
      ...state,
      segments: orderBy(segments, [s => s.display_name.toLowerCase()]),
      categories: this.computeCategories(segments)
    }));

    await dispatch(new ContactSegmentCreatedAction(newSegment));
  }

  @handle(SelectCurrentContactSegmentCategoryAction)
  private handleSelectCurrentSegmentCategoryAction(
    action: SelectCurrentContactSegmentCategoryAction
  ) {
    this.setState(state => ({
      ...state,
      selectedCategory: action.selectedCategory
    }));
  }

  @handle(UpdateContactSegmentAction, FormErrorHandling)
  private async handleUpdateContactSegmentAction(
    action: UpdateContactSegmentAction
  ) {
    action.form.validate();

    const model = action.form.updatedModel();
    model.client_data = toClientData(model.client_data);
    const updatedSegment = await this.contactSegmentRepository.put(model);

    action.form.applyModel(updatedSegment);
    const existingSegment = this.state.segments.find(
      s => s.id == updatedSegment.id
    );

    Object.assign(existingSegment, updatedSegment);

    this.setState(state => ({
      ...state,
      segments: orderBy(state.segments, [s => s.display_name.toLowerCase()]),
      categories: this.computeCategories(state.segments)
    }));

    await dispatch(new ContactSegmentUpdatedAction(updatedSegment));
  }

  @handle(ContactSegmentDeleteAction)
  private async handleDelete(action: ContactSegmentDeleteAction) {
    const deleted = this.state.segments.filter(s =>
      action.segmentIds.some(id => id == s.id)
    );

    await this.contactSegmentRepository.deleteSelection({
      ids: deleted.map(s => s.id)
    });
    const segments = difference(this.state.segments, deleted);

    this.setState(state => ({
      ...state,
      segments,
      categories: this.computeCategories(segments)
    }));

    await dispatch(new ContactSegmentsDeletedAction(action.segmentIds));
  }

  @handle(ContactSegmentCategorizeAction)
  private async handleContactSegmentCategorizeAction(
    action: ContactSegmentCategorizeAction
  ) {
    await this.contactSegmentRepository.setCategoryByIds(
      action.segmentIds,
      action.category
    );

    action.segmentIds.forEach(sid => {
      const segment = this.state.segments.find(s => s.id == sid);
      if (segment == null) {
        return;
      }

      segment.category = action.category;
    });

    this.setState(state => ({
      ...state,
      categories: this.computeCategories(state.segments)
    }));
  }

  @handle(SetContactSegmentFilterStringAction)
  private async handleSetContactSegmentFilterStringAction(
    action: SetContactSegmentFilterStringAction
  ) {
    if (!!action.segmentFilterString) {
      this.setState(state => ({
        ...state,
        segmentFilterString: action.segmentFilterString,
      }))
    }
  }
}
