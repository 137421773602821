import { prop, inject } from "fw";
import { dispatch } from "fw-state";
import { Role } from "models/role";
import { EnsureRolesAction, RolesStore } from "state/roles";

@inject
export class LazyRole {
  @prop(null) id!: string;
  @prop("div") rootType!: string;

  private isLoading = false;

  constructor(
    private roleStore: RolesStore,
  ) {}

  async attached() {
    await this.idChanged();
  }

  public get loading(): boolean {
    if (this.role != null) {
      return false;
    }

    return this.isLoading;
  }

  async idChanged() {
    const { rolesHash } = this.roleStore.state;
    if (!rolesHash[this.id]) {
      await dispatch(new EnsureRolesAction([ this.id ]));
    }
  }

  public get role(): Role | null {
    if (this.id == null) {
      return null;
    }

    const { rolesHash } = this.roleStore.state;

    return rolesHash[this.id] ?? null;
  }
}
