import { inject, Navigator } from "fw";
import { dispatch } from "fw-state";

import { PopoverController } from "service/popover";
import { CurrentUserStore } from "state/current-user";
import { CurrentOrganizationStore, SetIsLoading } from "state/current-organization";

import { LogoutAction } from "state/actions";
import { DialogService } from "fw-dialog";
import { Notification } from "service/notification";
import { copy } from "helpers/copy";
import { clearStorages } from "helpers/clear-storages";
import { GenAISettingsDialog } from "views/components/genai-settings-dialog";

@inject
export class UserPopover {
  constructor(
    private nav: Navigator,
    private currentUserStore: CurrentUserStore,
    private currentOrgStore: CurrentOrganizationStore,
    private controller: PopoverController<any>,
    private dialogService: DialogService,
    private notify: Notification,
  ) {}

  private tapCount = 0;
  private tapTimeout: ReturnType<typeof setTimeout> | null = null;

  private async logout() {
    if (window.location.hostname === "localhost") {
      await dispatch(new SetIsLoading(true));
      await dispatch(new LogoutAction());
    }

    clearStorages();

    if (this.currentOrgStore.state.portalContext == null) {
      window.location.reload();
      window.location.href = "/#/login";
      return;
    }

    const {
      OrganizationPortal: { AllowLocalLogin },
      IdentityProviders,
    } = this.currentOrgStore.state.portalContext;

    if (AllowLocalLogin == false && IdentityProviders.length == 1) {
      await dispatch(new SetIsLoading(true));
      await dispatch(new LogoutAction());

      // we don't want to redirect them to login cause it will just log them back in :)
      this.nav.navigate("/logged-out");
    } else {
      window.location.reload();
      window.location.href = "/#/login";
    }
  }

  get nonImpersonatedUserAvailable() {
    return (
      this.currentUserStore.state.loggedIn &&
      this.currentUserStore.state.user != null &&
      this.currentUserStore.state.impersonating == false
    );
  }

  get canEditAccount() {
    return (
      !this.currentUserStore.state.impersonating &&
      !this.currentUserStore.state.isGlobalPrincipal &&
      this.currentUserStore.state.user != null
    );
  }

  get isGlobal() {
    return this.nonImpersonatedUserAvailable && this.currentUserStore.state.isGlobalPrincipal;
  }

  async openAudits() {
    this.controller.close();

    const { AuditsDialog } = await import("views/global-admin/audits-dialog");
    this.dialogService.open(AuditsDialog);
  }

  navTo(where: string) {
    this.nav.navigate(where);
    this.controller.cancel();
  }

  get organization() {
    return this.currentOrgStore.state.organization;
  }

  get isImpersonating() {
    return this.currentUserStore.state.impersonating;
  }

  get isSwitchingOrganization() {
    return this.currentUserStore.state.isSwitchingOrganization;
  }

  async stopImpersonating() {
    this.navTo("stop-impersonating");
  }

  async copyEmailAddress(e: Event) {
    try {
      const target = e.target as Element;
      await copy(target.innerHTML);
      this.notify.notify("Email address successfully copied to clipboard");
    } catch (err) {
      console.error("Failed to copy email address: ", err);
    }
  }

  handleAvatarClick() {
    if (!this.isGlobal) return;
 
    this.tapCount++;
 
    if (this.tapCount === 3) {
      this.toggleExperimentalFeatures();
      this.tapCount = 0; // Reset the counter
      this.controller.close();
      return;
    }
 
    // Reset the tap count after 3 seconds
    if (this.tapTimeout) {
      clearTimeout(this.tapTimeout);
    }
    this.tapTimeout = setTimeout(() => {
      this.tapCount = 0;
    }, 3000);
  }

  toggleExperimentalFeatures() {
    const currentStatus = sessionStorage.getItem("experimentalFeatures") === "true";
    if (currentStatus) {
        sessionStorage.setItem("experimentalFeatures", "false");
        this.notify.notify("Experimental features disabled");
    } else {
        sessionStorage.setItem("experimentalFeatures", "true");

        let defaultsUpdatedCount = 0;

        if (!localStorage.getItem('genai_settings_bedrock_model_key')) {
            localStorage.setItem(
                'genai_settings_bedrock_model_key',
                'sonnet_v2'
            );
            defaultsUpdatedCount++;
        }

        let message = "Experimental features enabled";
        if (defaultsUpdatedCount > 0) {
            message += `. ${defaultsUpdatedCount} default setting${defaultsUpdatedCount > 1 ? 's' : ''} updated.`;
        }

        this.notify.notify(message);
    }
  }

  public get showGenerativeAIFeatures(): boolean {
    return this.currentUserStore.state.isGlobalAdmin && sessionStorage.getItem("experimentalFeatures") === "true";
  }

  async openGenAISettings() {
    this.controller.close();
    this.dialogService.open(GenAISettingsDialog);
  }
}