import { inject, prop, ComponentEventBus } from "fw";
import { TableSectionGroupFilter as TableSectionGroupFilterModel } from "models/application-filters";
import { FormStore } from "state/forms";

@inject
export class TableSectionGroupFilter {
  @prop(null) filter: TableSectionGroupFilterModel;
  @prop(false) public editing: boolean;

  public filterLabel: string = "";

  public constructor(private ceb: ComponentEventBus, private formStore: FormStore) { }

  attached() {
    const form = this.formStore.state.forms.find(f => f.Key === this.filter.formKey);
    if (!form) {
      this.filterLabel = "";
    }

    this.filterLabel = form.Sections.find(s => s.IsTableSection && s.TableSectionOptions.Key === this.filter.sectionKey)?.Title;
  }

  public get proxyFilter(): TableSectionGroupFilterModel {
    return this.filter;
  }

  public set proxyFilter(val: TableSectionGroupFilterModel) {
    this.ceb.dispatch("update:filter", val);
  }
}