import { ICustomFieldDefinition, CustomFieldType } from "models/contact-organization";
import { getSectionMetas } from "models/testscore";
import { GroupFilter } from "models/filter-setup";

export class ContactSegment {
  id: string = null;
  organization_id: string = null;
  contact_type: string = null;
  display_name: string = null;
  key: string = null;
  filter: string = null;
  client_data: string = null;
  category: string = null;
  created_utc: Date = null;
  updated_utc: Date = null;
}

export const FILTER_TYPES = {
  GROUP: "group-filter",
  NESTED_GROUP: "nested-group-filter",
  NESTED_SEARCH_GROUP: "nested-search-group-filter",
  CONTACTS_OPERATOR_FILTERS: "contacts-operator-filter"
} as const;

export const getFilterUpdates = (clientData: GroupFilter, fields: ICustomFieldDefinition[]) => {
  if (!clientData) {
    return { operation: null, filters: [] };
  }

  const { operation, filters = [] } = clientData;
  const updatedFilters = filters.map((filter) => {
    if (filter.type === FILTER_TYPES.GROUP) {
      return {
        type: filter.type,
        data: getFilterUpdates(filter.data, fields)
      };
    }

    const { data } = filter;

    // Check for Engagement Score filter as it is a first class field and doesn't have a field id and is special cased.
    if (filter.type === FILTER_TYPES.CONTACTS_OPERATOR_FILTERS && data.field?.search_field === "score") {
      return filter;
    }

    let { fieldId } = data;

    // code for backward compatibility
    if (!fieldId && data.field) {
      fieldId = data.field.id;
      data.fieldId = fieldId;
    }

    if (!fieldId) {
      return filter;
    }

    const field = fields?.find((f) => f.id === fieldId);
    if (!field) {
      return filter;
    }

    const { options = [], type } = field;
    data.field = field;

    if (filter.type === FILTER_TYPES.NESTED_GROUP) {
      return {
        type: filter.type,
        data: {
          ...data,
          ...getFilterUpdates(data, data.field?.nested_fields)
        }
      };
    }

    if (filter.type === FILTER_TYPES.NESTED_SEARCH_GROUP) {
      const nestedFields = field?.nested_searches?.find(ns => ns.id === data.nestedSearchId)?.fields || [] as ICustomFieldDefinition[];
      const nestedSearch = data.nestedSearch || field.nested_searches?.find(ns => ns.id === data.nestedSearchId);

      return {
        type: filter.type,
        data: {
          ...data,
          ...getFilterUpdates(data, nestedFields as ICustomFieldDefinition[]),
          nestedSearch: nestedSearch,
          nestedSearchId: nestedSearch.id
        }
      };
    }

    switch (type) {
      case CustomFieldType.dropdown:
      case CustomFieldType.funnel:
        data.options = options.map((o) => ({ text: o.name, value: o.name }));
        data.value = data.value || options.find(o => o.id === data.fieldOptionId)?.name;
        data.fieldOptionId = data.field.options?.find(o => o.name === data.value)?.id;
        break;

      case CustomFieldType.boolean:
        data.options = [
          { text: "Yes", value: true },
          { text: "No", value: false },
        ];
        break;

      case CustomFieldType.multiselect:
      case CustomFieldType.tags:
        data.options = options.map((o) => o.name);
        data.values = data.values || data.fieldOptionIds?.map((id) => options.find((o) => o.id === id)?.name);
        data.fieldOptionIds = options.filter((o) => data.values?.includes(o.name))?.map(o => o.id);
        break;

      case CustomFieldType.testscore:
        data.sections = getSectionMetas(field);
        break;
    }

    return filter;
  });

  return { operation, filters: updatedFilters };
};

export const toClientData = (clientData: string): string => {
  const sanitizeFilter = ({ operation, filters = [] }: GroupFilter) => ({
    operation,
    filters: filters.map(({ type, data }) => {
      // nestedSearch, options, sections are being removed because they are extracted by their ids to rehydrate the filters.
      // SonarQube ignore: Ignoring unused properties (field, nestedSearch, options, values)
      // sonarignore-next-line

      const { field, nestedSearch, options, sections, ...cleanedData } = data;

      switch (type) {
        case FILTER_TYPES.GROUP:
          return { type, data: sanitizeFilter(cleanedData) };

        case FILTER_TYPES.NESTED_GROUP:
        case FILTER_TYPES.NESTED_SEARCH_GROUP:
          return { type, data: { ...cleanedData, ...sanitizeFilter(cleanedData) } };

        // Check for Engagement Score filter as it is a first class field and doesn't have a field id and is special cased.
        case FILTER_TYPES.CONTACTS_OPERATOR_FILTERS:
          if (field.search_field === "score") {
            cleanedData.field = field;
          }

          return { type, data: cleanedData };
        default:
          return { type, data: cleanedData };
      }
    }),
  });

  return JSON.stringify(sanitizeFilter(JSON.parse(clientData)));
};
