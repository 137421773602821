import { prop, needs, inject } from "fw";

import { GroupFilter } from "models/filter-setup";

import allFilters from "./filters-index";

@inject
@needs(...allFilters)
export class FiltersPreview {
  @prop(null) public filterContainer!: GroupFilter;
}
