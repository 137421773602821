import { inject, NetworkException } from "fw";
import { Store, handle, dispatch } from "fw-state";

import { FormErrorHandling } from "./error-handling";
import { RoleRepository } from "network/role-repository";

import { Role } from "models/role";
import { AddRoleAction, UpdateRoleAction, DeleteRoleAction } from "forms/role";

import { LogoutAction, StartAction } from "./actions";
import { difference, uniq } from "lodash-es";
import { FeatureFlagService } from "service/feature-flag";
import { wait } from "wait";
import { EnsureListAction } from "./users";
import { createFrom } from "fw-model";
import { SearchAfterListResult } from "models/search-after-list-result";
import { errorCodeMapper } from "helpers/error-code";

export class EnsureRolesAction {
  constructor(public roleIds: string[]) { }
}

export class EnsureRolesPageAction { 
  constructor(
    public pageSize: number,
    public filter: string,
    public search: string,
    public sort: string = null,
    public previousPageToken: string = null,
    public nextPageToken: string = null,
    public fields:string = null,
  ) { }
}

export const defaultRole = createFrom(Role, {
  Id: "",
  Name: "",
  Permissions: []
});

const defaultRolesSort = "name";

interface RolesShape {
  search: string;
  sort: string;
  total: number;
  page: number;
  pageSize: number;

  currentRolesPage: SearchAfterListResult<Role>;
  rolesHash: { [id: string]: Role };
  roles: Role[];
  loaded: boolean;
  loading: boolean;
  errorLoading: boolean;
}

@inject
export class RolesStore extends Store<RolesShape> {
  private idsToLoad: string[] = [];
  private idsToLoadLoading: string[] = [];

  constructor(private roleRepo: RoleRepository, private ffs: FeatureFlagService) {
    super();
  }

  private get ffRolesLazyLoad() {
    return this.ffs.isFeatureFlagEnabled("RolesLazyLoad");
  }

  public getRoleById(id: string): Role | null {
    if (this.ffRolesLazyLoad) {
      return this.state.rolesHash[id];
    }

    return this.state.roles.find(r => r.Id == id) ?? null;
  }

  defaultState() {
    return {
      search: null,
      page: 1,
      pageSize: 20,
      sort: defaultRolesSort,
      loaded: false,
      loading: false,
      total: 0,
      errorLoading: false,

      currentRolesPage: {
        Results: [],
        Total: 0,
        NextPageToken: null,
        PreviousPageToken: null,
        AsyncQueryId: null
      },
      rolesHash: {},
      roles: []
    };
  }

  @handle(StartAction)
  private async handleStart(s: StartAction) {
    if (this.ffRolesLazyLoad) {
      return;
    }

    const { Roles } = s.context;

    this.setState(state => ({
      ...state,
      roles: Roles,
    }));
  }

  @handle(LogoutAction)
  private handleLogout() {
    this.setState(s => this.defaultState());
  }

  @handle(AddRoleAction, FormErrorHandling)
  private async handleAddRoleAction(action: AddRoleAction) {
    action.form.validate();

    const newModel = action.form.updatedModel();
    if (!newModel.Permissions.includes("ViewContacts")) {
      newModel.Permissions.push("ViewContacts");
    }
    try {
      const newRole = await this.roleRepo.post(newModel);
      action.added = newRole;

      if (this.ffRolesLazyLoad) {
        await wait(1000); // wait for elastic search consistency
        await dispatch(new EnsureRolesPageAction(this.state.pageSize, null, null, null, null, null, null));
      } else {
        this.setState(state => ({
          ...state,
          roles: [...state.roles, newRole],
        }));
      }
    } catch(err) {
      if (err instanceof NetworkException) {
        if (err.result.Message && err.result.Message.length) {
          action.form.validationMessages = [err.result.Message];
          action.form.isInvalid = true;
        }
      }
      throw err;
    }
  }

  @handle(UpdateRoleAction, FormErrorHandling)
  private async handleUpdateRoleAction(action: UpdateRoleAction) {
    action.form.validate();

    const newModel = action.form.updatedModel();
    if (!newModel.Permissions.includes("ViewContacts")) {
      newModel.Permissions.push("ViewContacts");
    }

    try {
      const updatedRole = await this.roleRepo.put(newModel, action.force);
      action.updated = updatedRole;

      if (this.ffRolesLazyLoad) {
        const existingRole = this.state.currentRolesPage.Results.find(t => t.Id == updatedRole.Id);
        if (existingRole) {
          Object.assign(existingRole, updatedRole);
        }

        this.setState(
          state => ({
            ...state,
            rolesHash: {
              ...state.rolesHash,
              [updatedRole.Id]: updatedRole
            }
          }
        ));
      } else {
        const existingRole = this.state.roles.find(t => t.Id == updatedRole.Id);
        if (existingRole == null) {
          return;
        }

        Object.assign(existingRole, updatedRole);
        this.setState(s => s);
      }
    } catch (err) {
      if (err instanceof NetworkException) {
        if (err.headers["x-ats-supports-force"] == "true") {
          action.canForce = true;
        } else if (err.result.Message && err.result.Message.length) {
          action.form.validationMessages = [err.result.Message];
          action.form.isInvalid = true;
        }
      }
      throw err;
    }
  }

  @handle(DeleteRoleAction)
  private async handleDeleteRoleAction(action: DeleteRoleAction) {
    if (this.ffRolesLazyLoad) {
      const role = this.state.currentRolesPage.Results.find(r => r.Id == action.roleId) 
        ?? this.state.rolesHash[action.roleId];
      
      await this.roleRepo.del(action.roleId);
      const {[action.roleId]: _, ...rolesHash} = this.state.rolesHash;

      this.setState(state => ({
        ...state,
        currentRolesPage: {
          ...state.currentRolesPage,
          Results: difference(state.currentRolesPage.Results, [role])
        },
        rolesHash: rolesHash
      }));
    } else {
      const role = this.state.roles.find(r => r.Id == action.roleId);
      if (role == null) return;
  
      await this.roleRepo.del(action.roleId);
      this.setState(state => ({
        ...state,
        roles: difference(state.roles, [role]),
      }));
    }

    // Reload users list (currently filtered by recently deleted id)
    await dispatch(new EnsureListAction(null, null));
  }

  @handle(EnsureRolesAction)
  private async handleEnsureRolesAction(action: EnsureRolesAction) {
    if (!this.ffRolesLazyLoad) {
      return;
    }

    if (action.roleIds == null) return;
    const idsToFetch = uniq(action.roleIds.filter(
      id =>
        !!id &&
        this.state.rolesHash[id] == null &&
        !this.idsToLoad.includes(id) &&
        !this.idsToLoadLoading.includes(id)
    ));

    if (idsToFetch.length == 0) {
      return;
    }

    if (this.idsToLoad.length > 0) {
      // push it on to this thing and return..
      this.idsToLoad.push(...idsToFetch);
      return;
    }

    this.idsToLoad.push(...idsToFetch);

    await wait(10); // this is the buffer time, so that multiple components can all get in here
    this.idsToLoadLoading.push(...this.idsToLoad);
    this.idsToLoad = [];
    const newHash: { [id: string]: Role } = {};

    try {
      const currentIdsToLoad = this.idsToLoadLoading.slice();
      const res = await this.roleRepo.getIds(currentIdsToLoad);

      for (const role of res) {
        newHash[role.Id] = role;

        const idx = this.idsToLoadLoading.indexOf(role.Id);
        if (idx >= 0) this.idsToLoadLoading.splice(idx, 1);
      }

      // did anything that got loaded not return from the server?
      // if so, we need to fill it out with the defaultUser
      const idsToBeDefaulted = difference(
        currentIdsToLoad,
        res.map((u) => u.Id)
      );
      for (const id of idsToBeDefaulted) {
        newHash[id] = defaultRole;

        const idx = this.idsToLoadLoading.indexOf(id);
        if (idx >= 0) this.idsToLoadLoading.splice(idx, 1);
      }
    } catch (err) {
      this.idsToLoadLoading = [];
      throw err;
    }

    this.setState(state => ({
      ...state,
      rolesHash: {
        ...state.rolesHash,
        ...newHash
      }
    }));
  }

  private getRequestedPageNumber(action: EnsureRolesPageAction) {
    if (!action.previousPageToken && !action.nextPageToken) {
      return 1;
    }

    if (action.previousPageToken) {
      return this.state.page - 1;
    }

    return this.state.page + 1;
  }
  
  @handle(EnsureRolesPageAction)
  private async handleEnsureProgramsPageAction(action: EnsureRolesPageAction) {
    const changedPgNum =  this.getRequestedPageNumber(action);
    this.setState(state => ({
      ...state,
      loaded: false,
      loading: true,
      errorLoading: false,
    }));

    try {
      const result = await this.roleRepo.search(action.filter, action.search, action.sort, action.previousPageToken, action.nextPageToken, action.fields, action.pageSize);
      this.setState(state => ({
        ...state,
        currentRolesPage: result,
        page: changedPgNum,
        total: result.Total,
        loaded: true,
        loading: false
      }));
    } catch(error) {
      this.setState(state => ({
        ...state,
        currentRolesPage: null,
        page: null,
        loaded: true,
        loading: false,
        errorLoading: true
      }));
    }
  }
}
