import { inject } from "fw";
import { DialogController } from "fw-dialog";
import { GenAIRepository } from "network/gen-ai-repository";
import { nextTick } from "vue";

interface Message {
  text: string;
  isSender: boolean;
  isLoading?: boolean;
}

@inject
export class SupportChatDialog {
  public prompt: string = "";
  public messages: Message[] = [];
  private messagesStorageKey: string = "supportChatMessages";
  public $refs: any;

  constructor(
    private controller: DialogController<void>,
    private genAIRepo: GenAIRepository,
  ) {}

  attached() {
    // temp solution here. DialogService from fw-dialog needs to be extended to accept classes for the fw-dialog container
    const chatDialog = document.querySelector('.c-ai-chat-dialog');
    if (chatDialog && chatDialog.parentElement) {
      const parentDialog = chatDialog.parentElement;
      if (parentDialog.classList.contains('fw-dialog')) {
        parentDialog.classList.add('fw-dialog--bottom-right');
      }
    }

    const savedMessages = sessionStorage.getItem(this.messagesStorageKey);
    if (savedMessages) {
      this.messages = JSON.parse(savedMessages);
    } else {
      this.messages.push({
        text: "Welcome! I’m here to assist you with using the portal. Let me know how I can help.",
        isSender: false,
      });
    }

    this.saveMessages();
    this.scrollToBottom();
  }

  close() {
    this.controller.close();
  }

  async sendMessage() {
    if (this.prompt.trim() === "") return;
  
    // Add sender's message to the chat
    this.messages.push({ text: this.prompt, isSender: true });
    const latestPrompt = this.prompt;
    this.prompt = "";
    this.saveMessages();
    this.scrollToBottom();
  
    // Add loading placeholder for the response
    const loadingMessageIndex = this.messages.push({
      text: "",
      isSender: false,
      isLoading: true,
    }) - 1;
    this.saveMessages();
    this.scrollToBottom();
  
    try {
      // Retrieve and display the actual response
      let fullResponse = '';
      await this.genAIRepo.querySupportKnowledgeBase(
        latestPrompt,
        (response: string) => {
          // For some reason, RetrieveAndGenerateStream starts response with /n and I can't get it to stop.
          if (!fullResponse) {
            response = response.replace(/^\n/, '');
          }
          fullResponse += response;
          this.messages.splice(loadingMessageIndex, 1, {
            text: fullResponse,
            isSender: false,
            isLoading: false,
          });
          this.scrollToBottom();
        }
      );
    } catch (error) {
      console.error("Error retrieving response:", error);
      this.messages.splice(loadingMessageIndex, 1, {
        text: "An error occurred while retrieving the response. Please try again.",
        isSender: false,
        isLoading: false,
      });
    }
  
    this.saveMessages();
    this.scrollToBottom();
  }
  
  private saveMessages() {
    sessionStorage.setItem(this.messagesStorageKey, JSON.stringify(this.messages));
  }
  
  private async scrollToBottom() {
    await nextTick(); // Wait for the DOM to fully update
    const messagesContainer = this.$refs.messagesContainer;
    if (messagesContainer) {
      messagesContainer.scrollTop = messagesContainer.scrollHeight;
    }
  }
}
