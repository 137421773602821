import { ContainerInstance } from "fw";
import { FormForType, formFor, Validators } from "fw-model";

import { isValidSendingEmail, noNewLines } from "forms/validators";
import { MarketingEmailTemplate } from "models/marketing-email-template";
import { FeatureFlagService } from "service/feature-flag";

const { isEmail, wrap } = Validators;

export type FormForMarketingEmailTemplate = FormForType<MarketingEmailTemplate>;

export class CreateMarketingEmailTemplateAction {
  public created: MarketingEmailTemplate = null;
  constructor(public form: FormForMarketingEmailTemplate) {}
}

export class UpdateMarketingEmailTemplateAction {
  public updated: MarketingEmailTemplate = null;
  constructor(public form: FormForMarketingEmailTemplate) {}
}

export class DeleteMarketingEmailTemplateAction {
  constructor(public ids: number | number[], public all?: boolean, public isDeleted?: boolean, public search?: string) {}
}

export class CopyMarketingEmailTemplateAction {
  constructor(public ids: number | number[], public all?: boolean, public isDeleted?: boolean, public search?: string) {}
}

export class ActivateMarketingEmailTemplateAction {
  constructor(public ids: number | number[], public all?: boolean, public isDeleted?: boolean, public search?: string) {}
}

export class DeactivateMarketingEmailTemplateAction {
  constructor(public ids: number | number[], public all?: boolean, public isDeleted?: boolean, public search?: string) {}
}

let dmarcSendingDomainFF: boolean = false;
const isDmarcSendingDomainFF = (): boolean => {
  if (!!dmarcSendingDomainFF) return true;
  dmarcSendingDomainFF = ContainerInstance.get(FeatureFlagService).isFeatureFlagEnabled("MarketingSendingDomainsDmarcOUT2435Feb2024");
  return dmarcSendingDomainFF;
}

let categorizeMarketingEmailTemplatesFF: boolean = false;
const isCategorizeMarketingEmailTemplatesFF = (): boolean => {
  if (!!categorizeMarketingEmailTemplatesFF) return true;
  categorizeMarketingEmailTemplatesFF = ContainerInstance.get(FeatureFlagService).isFeatureFlagEnabled(
    "CategorizeMarketingEmailTemplatesOUT6320Dec2024"
  );
  return categorizeMarketingEmailTemplatesFF;
};

export const createMarketingEmailTemplateFormCreator = formFor(MarketingEmailTemplate, s => {
  s.requiredField(et => et.Name, "Name");
  if (isCategorizeMarketingEmailTemplatesFF()) {
    s.requiredField(et => et.AudienceType, "Audience Type");
  }
  s.field(et => et.Subject, "Subject", wrap(noNewLines));
  s.field(et => et.FromName, "From name");
  s.field(et => et.ReplyToName, "Reply to name");
  if (isDmarcSendingDomainFF()) {
    s.field(et => et.FromAddress, "From address", wrap(isValidSendingEmail));
    s.field(et => et.ReplyToAddress, "Reply to address", wrap(isValidSendingEmail));
  } else {
    s.field(et => et.FromAddress, "From address", wrap(isEmail));
    s.field(et => et.ReplyToAddress, "Reply to address", wrap(isEmail));
  }
  s.field(et => et.Alignment, "Alignment");
  s.requiredField(et => et.EmailTemplateDesignId, "Design");
  s.requiredField(et => et.ContentType, "Content type");
  s.field(et => et.Html, "Html content");
  s.field(et => et.Text, "Text content");
  s.field(et => et.Markup, "Markup content");
});

export const updateMarketingEmailTemplateFormCreator = formFor(MarketingEmailTemplate, s => {
  s.requiredField(et => et.Id, "Id");
  s.requiredField(et => et.Name, "Name");
  if (isCategorizeMarketingEmailTemplatesFF()) {
    s.field(et => et.AudienceType, "Audience Type");
  }
  s.field(et => et.Subject, "Subject", wrap(noNewLines));
  s.field(et => et.FromName, "From name");
  s.field(et => et.ReplyToName, "Reply to name");
  if (isDmarcSendingDomainFF()) {
    s.field(et => et.FromAddress, "From address", wrap(isValidSendingEmail));
    s.field(et => et.ReplyToAddress, "Reply to address", wrap(isValidSendingEmail));
  } else {
    s.field(et => et.FromAddress, "From address", wrap(isEmail));
    s.field(et => et.ReplyToAddress, "Reply to address", wrap(isEmail));
  }
  s.field(et => et.Alignment, "Alignment");
  s.requiredField(et => et.EmailTemplateDesignId, "Design");
  s.requiredField(et => et.ContentType, "Content type");
  s.field(et => et.Html, "Html content");
  s.field(et => et.Text, "Text content");
  s.field(et => et.Markup, "Markup content");
});
