import { DataDictionaryField } from "models/data-dictionary";
import { CompletionItem } from "./auto-complete";
import { FILE_SIZES } from "views/marketing/bulk-messages/manage-message/build-email.constants";

export const divideWords = (str: string): string => {
  const noCamel = str.replace(/([A-Z])/g, ' $1');
  const newStr = noCamel.replace(/\s|_/g, " ");

  return newStr.charAt(0).toUpperCase() + newStr.slice(1);
}

export const getFieldsFromProperties  = (properties: CompletionItem[], field: DataDictionaryField) =>  {
  return properties.reduce((previousValue: DataDictionaryField[], currentValue: CompletionItem): DataDictionaryField[] => {
    const newField: DataDictionaryField =  new DataDictionaryField();
    newField.Category = field.Category;
    newField.Path = `${field.Path}.${currentValue.token}`;
    newField.Label = `${field.Label ? `${field.Label} - ` : "" }${divideWords(currentValue.token)}`;

    if (currentValue.properties?.length) {
      return [
        ...previousValue,
        ...getFieldsFromProperties(currentValue.properties, newField),
      ]
    }

    return [
      ...previousValue,
      newField
    ];
  }, []);
}

export const stripLinkHref  =  (html) =>  {
  if (!html) {
    return "";
  }

  const tmpElement = document.createElement("div");
  tmpElement.innerHTML = html;
  tmpElement.querySelectorAll(".ck-content a[href] , .ck-content + * a[href]").forEach((link) => {
    link.setAttribute("href", "javascript:void(0);");
  });

  tmpElement.querySelectorAll("div[data-oembed-url]").forEach((div) => {
    const href = div.getAttribute("data-oembed-url");
    div.querySelector("a[href='javascript:void(0);']").setAttribute("href", href);
  });

  return tmpElement.innerHTML;
}