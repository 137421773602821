import { inject, prop, needs, ComponentEventBus, NetworkException } from "fw";
import { Notification } from "service/notification";
import { Pager } from "./pager";
import { debounce } from "lodash-es";

export interface SearchResults<T> {
  total: number;
  list: T[];
  previousPageToken?: string;
  nextPageToken?: string;
}

export interface LoadDataContext {
  filter: string;
  search: string;
  sort: string;
  pageSize: number;
  page: number;
  previousPageToken: string;
  nextPageToken: string;
  fields: string;
}

@inject
@needs(Pager)
export class ItemSearcher<T> {
  @prop(null) private filter!: string;
  @prop(null) private sort!: string;
  @prop(null) private selectedIds!: string[];
  @prop(null) public loadData: (context: LoadDataContext) => Promise<SearchResults<T>>;
  @prop(10) public pageSize: number;
  @prop(null) public fields: string;
  @prop(null) public placeholder!: string;
  @prop(false) public allowUnassigned!: boolean;
  @prop(null) public unassignedPlaceholder!: string;
  @prop("No results found") public noneFoundMessage!: string;
  @prop(0) public minChars!: number;
  @prop("Option selector dialog") public ariaLabel: string;
  @prop(false) public hideSearchForSinglePage: string;

  public searching: boolean = false;
  public paging: boolean = false;
  public onPage: number = 1;
  public itemPage: T[] = [];
  public total: number = 0;
  public totalWithoutSearchTerm: number = 0;
  public loaded: boolean = false;
  private searchValue: string = "";
  private previousPageToken: string = null;
  private nextPageToken: string = null;
  private debouncedSearch: () => Promise<void> = null;

  public constructor(
    private ceb: ComponentEventBus,
    private notifier: Notification
  ) { }

  public async attached() {
    this.debouncedSearch = debounce(async () => {
      await this.doSearch();
    }, 250);

    await this.doSearch();
  }

  public get searchTerm(): string {
    return this.searchValue;
  }

  public get showSearchBox(): boolean {
    return !this.hideSearchForSinglePage || this.totalWithoutSearchTerm > this.pageSize;
  }

  public set searchTerm(v: string) {
    this.searchValue = v;
    this.onPage = 1;
    this.sort = null;
    this.previousPageToken = null;
    this.nextPageToken = null;
    this.debouncedSearch();
  }

  private async doSearch() {
    if (this.minChars && this.searchTerm && this.searchTerm.length < this.minChars) {
      return;
    }

    if (this.searching) {
      return;
    }

    this.searching = true;
    try {
      const res: SearchResults<T> = await this.loadData(this.loadDataContext);
      this.nextPageToken = res.nextPageToken;
      this.previousPageToken = res.previousPageToken;
      this.total = this.allowUnassigned && !this.searchTerm ? res.total + 1 : res.total;
      if(this.totalWithoutSearchTerm) {
        this.totalWithoutSearchTerm = this.searchTerm ? this.totalWithoutSearchTerm : res.total;
      } else {
        this.totalWithoutSearchTerm = res.total;
      }
      this.itemPage = res.list;
      this.loaded = true;
    } catch (e) {
      let message = "Error loading records";
      if (e instanceof NetworkException && e.result?.Message) {
        message += `: ${e.result?.Message}`;
      }

      this.notifier.error(message);
    } finally {
      this.searching = false;
      this.paging = false;
    }
  }

  private get loadDataContext(): LoadDataContext {
    return {
      filter: this.filter,
      search: this.searchTerm,
      sort: this.sort,
      page: this.onPage,
      pageSize: this.showUnassigned ? this.pageSize - 1 : this.pageSize,
      previousPageToken: this.previousPageToken,
      nextPageToken: this.nextPageToken,
      fields: this.fields
    };
  }

  public async next() {
    this.onPage += 1;
    this.previousPageToken = null;
    this.paging = true;
    await this.doSearch();
  }

  public async previous() {
    this.onPage = Math.max(this.onPage - 1, 1);
    this.nextPageToken = null;
    this.paging = true;
    await this.doSearch();
  }

  public selectItem(item: T[]) {
    this.ceb.dispatch("select", item);
  }

  public isSelected(id: string): boolean {
    return this.selectedIds?.includes(id);
  }

  public get showUnassigned(): boolean {
    return this.allowUnassigned && !this.searchTerm && this.onPage === 1;
  }

  public async filterChanged(filter: string) {
    this.doSearch();
  }
}
