import { inject } from "fw";
import { createFrom, createFromArray } from "fw-model";

import { ATS, IListResults } from "./ats";
import { encodeMarketingEmailTemplate } from "./encode";
import { MarketingEmailTemplate } from "models/marketing-email-template";

@inject
export class MarketingEmailTemplateRepository {
  constructor(private network: ATS) {}

  async index() {
    const res = await this.network.marketing.get<MarketingEmailTemplate[]>("email_templates");
    return createFromArray(MarketingEmailTemplate, res.body);
  }

  async list(
    q: string = null,
    f: string = null,
    aggs: string = null,
    sort: string = null,
    page = 1,
    limit = 10,
  ): Promise<IListResults<MarketingEmailTemplate>> {
    return this.network.marketing.list("email_templates", MarketingEmailTemplate, q, f, aggs, sort, page, limit);
  }

  async show(id: number) {
    const res = await this.network.marketing.get<MarketingEmailTemplate>(`email_templates/${id}`);
    return createFrom(MarketingEmailTemplate, res.body);
  }

  async post(template: MarketingEmailTemplate) {
    const encoded = encodeMarketingEmailTemplate(template);
    const res = await this.network.marketing.post<MarketingEmailTemplate>("email_templates", encoded);
    return createFrom(MarketingEmailTemplate, res.body);
  }

  async put(template: MarketingEmailTemplate) {
    const encoded = encodeMarketingEmailTemplate(template);
    const res = await this.network.marketing.put<MarketingEmailTemplate>(`email_templates/${template.Id}`, encoded);
    return createFrom(MarketingEmailTemplate, res.body);
  }

  async destroy(ids: number | number[], all: boolean = false, active: boolean = true, search: string = "") {
    const idsStr = Array.isArray(ids) ? ids.join() : ids;

    await this.network.marketing.delete<MarketingEmailTemplate[]>("email_templates", {
      Ids: idsStr,
      All: all,
      Active: active,
      Search: search
    });
  }

  async copy(ids: number | number[], all: boolean = false, active: boolean = true, search: string = "") {
    const idsStr = Array.isArray(ids) ? ids.join() : ids;

    const res = await this.network.marketing.post<MarketingEmailTemplate[]>("email_templates/copy", {
      Ids: idsStr,
      All: all,
      Active: active,
      Search: search
    });

    return createFromArray(MarketingEmailTemplate, res.body);
  }

  async activate(ids: number | number[], all: boolean = false, active: boolean = true, search: string = "") {
    const idsStr = Array.isArray(ids) ? ids.join() : ids;

    await this.network.marketing.post<MarketingEmailTemplate[]>("email_templates/activate", {
      Ids: idsStr,
      All: all,
      Active: active,
      Search: search
    });
  }

  async deactivate(ids: number | number[], all: boolean = false, active: boolean = true, search: string = "") {
    const idsStr = Array.isArray(ids) ? ids.join() : ids;

    await this.network.marketing.post<MarketingEmailTemplate[]>("email_templates/deactivate", {
      Ids: idsStr,
      All: all,
      Active: active,
      Search: search
    });
  }
}
