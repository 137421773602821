import { inject } from "fw";

import { RolesStore } from "state/roles";
import { CurrentUserStore } from "state/current-user";
import { CurrentOrganizationStore } from "state/current-organization";
import { CurrentContactOrganizationStore} from "state/current-contact-organization";
import { ApplicationProperty } from "models/application-settings";
import { IContactRelationshipGroupModel, IUserRelationshipGroupModel } from "models/contact";
import { ApplicationRestriction, ApplicationRestrictionType } from "models/role";
import { PathInspector } from "./path-inspector";
import { FeatureFlagService } from "./feature-flag";
import { Permissions } from "./permission";

const isApplicationPropertyList = (list: any[]): list is ApplicationProperty[] => {
  return list && list.length > 0 && list[0] instanceof ApplicationProperty;
}

@inject
export class DataPolicyService {
  constructor(
    private currentUserStore: CurrentUserStore,
    private currentOrganizationStore: CurrentOrganizationStore,
    private currentContactOrganizationStore: CurrentContactOrganizationStore,
    private pathInspector: PathInspector,
    private permissions: Permissions,
    private rolesStore: RolesStore,
    private featureFlagService: FeatureFlagService
  ) { }
  public contactRestrictedFieldIds(contactType: string) {
    if (!contactType)
      return [];
    const roleSettings = this.currentUserStore.state.roleSettings;
    if (!roleSettings?.data_policies || roleSettings.data_policies.length === 0)
      return [];
    return roleSettings.data_policies.find(d => d.contact_type === contactType)?.restricted_field_ids || [];
  }

  public isRelationshipRestricted(relationship: IContactRelationshipGroupModel | IUserRelationshipGroupModel) {
    const { roleSettings } = this.currentUserStore.state;
    if (roleSettings?.data_policies?.length > 0) {
      const relationshipDataPolicy = roleSettings.data_policies.find(d => d.contact_type === relationship.type);
      if (relationship.type && relationshipDataPolicy?.is_hidden) {
        return true;
      }

      const contactType = relationship.field?.contact_type;
      if (!contactType) {
        return false;
      }

      const dataPolicy = roleSettings.data_policies.find(d => d.contact_type === contactType);
      if (!dataPolicy) {
        return false;
      }

      if (dataPolicy.is_hidden) {
        return true;
      }

      return dataPolicy.restricted_field_ids?.length > 0 && dataPolicy.restricted_field_ids.indexOf(relationship.field.id) > -1;
    }

    return false;
  }

  public isContactFieldRestricted(contactType: string, fieldId: string) {
    if (!contactType) {
      return false;
    }

    const roleSettings = this.currentUserStore.state.roleSettings;
    if (roleSettings?.data_policies?.length > 0) {
      const dataPolicy = roleSettings.data_policies.find(d => d.contact_type === contactType);
      if (!dataPolicy) {
        return false;
      }

      if (dataPolicy.is_hidden) {
        return true;
      }

      return dataPolicy.restricted_field_ids?.length > 0 && dataPolicy.restricted_field_ids.indexOf(fieldId) > -1;
    }

    return false;
  }

  public isContactFieldNameRestricted(contactType: string, fieldName: string) {
    if (!contactType)
      return false;
    const roleSettings = this.currentUserStore.state.roleSettings;
    if (roleSettings?.data_policies?.length > 0) {
      const dataPolicy = roleSettings.data_policies.find(d => d.contact_type === contactType);
      if (!dataPolicy)
        return false;
      if (dataPolicy.is_hidden)
        return true;

      if (!dataPolicy.restricted_field_ids || dataPolicy.restricted_field_ids.length === 0)
        return false;

      const field = this.currentContactOrganizationStore.state.organization.fields.find(f =>
        f.contact_type === contactType && f.name === fieldName
      );
      if (!field)
        return false;

      return dataPolicy.restricted_field_ids.indexOf(field.id) > -1;
    }
    return false;
  }

  public isContactPartialResults(contactType: string) {
    if (!contactType)
      return false;
    const roleSettings = this.currentUserStore.state.roleSettings;
    if (roleSettings?.data_policies?.length > 0) {
      const dataPolicy = roleSettings.data_policies.find(d => d.contact_type === contactType);
      if (!dataPolicy)
        return false;
      if (dataPolicy.is_hidden || dataPolicy.segment_id?.length > 0)
        return true;
    }
    return false;
  }

  get activeSeasonDataPolicy() {
    const { isGlobalPrincipal, user } = this.currentUserStore.state;
    if (isGlobalPrincipal || !user) return null;

    const { organization } = this.currentOrganizationStore.state;

    const membership = user.Memberships.find(m => m.OrganizationId == organization.Id);
    if (!membership) return null;

    const role = this.rolesStore.getRoleById(membership.RoleId);
    return role.DataPolicies && role.DataPolicies[organization.ActiveSeasonId];
  }

  get applicationRestrictions(): ApplicationRestriction[] {
    const dataPolicy = this.activeSeasonDataPolicy;
    let restrictions = dataPolicy?.ApplicationRestrictions ?? [];
    if (this.permissions.all("ManageGlobalDataRestrictions")) {
        return restrictions;
    }

    const ffGlobalDataRestrictions = this.featureFlagService.isFeatureFlagEnabled("GlobalDataRestrictions");
    if (!ffGlobalDataRestrictions) {
        return restrictions;
    }
    
    const globalDataRestrictions = this.currentOrganizationStore.state.season?.DataPolicy?.ApplicationRestrictions ?? [];
    return [...restrictions, ...globalDataRestrictions];
  }

  get applicationPropertyPaths() {
    const applicationRestrictions = this.applicationRestrictions;
    if (this.applicationRestrictions.length == 0)
      return [];

    const propertyRestrictions = applicationRestrictions.filter(p => p.Type == "ApplicationProperty");
    if (!propertyRestrictions || propertyRestrictions.length === 0)
      return [];

    if (propertyRestrictions.length === 1)
      return propertyRestrictions[0].Paths;

    const paths = propertyRestrictions.map(r => r.Paths).reduce((a, b) => a.concat(b));
    return paths && paths.length > 0 ? paths : [];
  }

  get formStepPaths() {
    const applicationRestrictions = this.applicationRestrictions;
    if (this.applicationRestrictions.length == 0) return [];

    const formRestrictions = applicationRestrictions.filter(p => p.Type == "FormStep");
    if (!formRestrictions || formRestrictions.length === 0) return [];

    if (formRestrictions.length === 1) return formRestrictions[0].Paths;

    const paths = formRestrictions.map(r => r.Paths).reduce((a, b) => a.concat(b));
    return paths == null || paths.length === 0 ? [] : paths;
  }

  get applicantPropertyPaths() {
    const fieldIds = this.contactRestrictedFieldIds('applicant');
    if (fieldIds.length == 0) return [];

    const paths = this.currentContactOrganizationStore.state.organization.fields.filter(r => fieldIds.includes(r.id)).map(r => r.name);
    return paths && paths.length > 0 ? paths : [];
  }

  public isRestricted(path: string) {
    if (!path)
      return false;

    const applicationRestrictions = this.applicationRestrictions || [];
    const applicantPropertyPaths = this.applicantPropertyPaths || [];
    // if no policies, you can see it
    if (applicationRestrictions.length == 0 && applicantPropertyPaths.length == 0)
      return false;

    const pathResult = this.pathInspector.inspect(path);

    switch (pathResult?.kind) {
      case "form":
        const restrictions = applicationRestrictions.filter(p =>
          p.Type == "FormStep" || p.Type == "Evaluation" || p.Type == "ReferenceForm"
        );
        if (!(restrictions?.length > 0))
          return false;

        const paths = restrictions.length === 1
          ? restrictions[0].Paths
          : restrictions.map(r => r.Paths).reduce((a, b) => a.concat(b));

        const formPaths = paths && paths.length > 0 ? paths : [];
        if (formPaths.length === 0)
          return false;

        const entireForm = `${pathResult.formKey}.*`;
        const subpath = path.startsWith("forms.") ? path.substring(6) : path;
        return !!formPaths.find(p => p == subpath || p == entireForm);

      case "application":
        const propertyPaths = this.getPathsByType(applicationRestrictions, "ApplicationProperty");
        return !!propertyPaths.find(p => p == pathResult.key);

      case "applicant-property":        
        return !!applicantPropertyPaths.find(p => p == pathResult.key);

      case "applicant-identity":
        return !!applicationRestrictions.find(p => p.Type == "ApplicantIdentity");

      case "applicant-profile-image":
        return !!applicationRestrictions.find(p => p.Type == "ApplicantProfileImage");

    }

    return false;
  }

  private getPathsByType(applicationRestrictions: ApplicationRestriction[], type: ApplicationRestrictionType): string[] {
    const restrictions = applicationRestrictions.filter(p => p.Type == type);
    if (!(restrictions?.length > 0)) return [];

    const paths = restrictions.length === 1
      ? restrictions[0].Paths
      : restrictions.map(r => r.Paths).reduce((a, b) => a.concat(b));

    return paths || [];
  }

  applyList<T>(list: T[]): T[] {
    if (!list || list.length === 0) return list;
    for (const ar of this.applicationRestrictions) {
      list = this.runApplicationRestriction(ar, list);
    }

    return list;
  }

  private runApplicationRestriction(restriction: ApplicationRestriction, list: any[]): any[] {
    // TODO: once we have some more types in here that we need to handle on the client side..
    // extract these out to classes...
    switch (restriction.Type) {
      case "ApplicationProperty":
        if (isApplicationPropertyList(list)) {
          return (list as ApplicationProperty[]).filter(item => {
            return !restriction.Paths.includes(item.Key)
          });
        }
        break;
    }

    return list;
  }
}
