import { needs, inject, Navigator } from "fw";
import { DialogService } from "fw-dialog";
import { dispatch } from "fw-state";

import {
  DataClickEvent,
  ChartDataSource,
} from "shared/report-runtime";
import {
  CreateReportDefinitionAction,
  UpdateReportDefinitionAction,
  FormForReportDefinition,
  widgetDefinitionFormCreator,
  reportDefinitionFormCreator,
  fieldGroupFormCreator,
} from "forms/report-definition";
import { FeatureFlagService } from "service/feature-flag";

import { WidgetFormAndWidgetData, ChartsEditor, FieldGroupAndWidgetData } from "views/reports/charts-editor";
import { ChartDataClickService } from "service/chart-data-click";
import { OrganizationModuleService } from "service/organization-module";
import { SetFilterAction } from "state/applicants";
import { CurrentUserStore } from "state/current-user";
import {
  ReportDefinitionsStore,
  EnsureResolvedDashboardAction,
  DeleteReportDefinitionAction,
  RunReportAction
} from "state/report-definitions";
import { ReportChart } from "views/reports/report-chart";
import { SetSelectedContactTypeAction } from "state/current-user-settings";
import { SetContactsFilterAction } from "state/contacts";
import { TaskListDueDateFilter } from "models/task-filters";
import { CalendarEventTypes } from "models/calendar-event";
import { UserTask } from "models/user-task";
import {
  EnsureUserTaskStoreAction,
  ChangeTaskFiltersAction,
  TaskListType
} from "state/user-tasks";
import { EditTask } from "views/tasks/edit-task";
import { ReportService } from "service/report";
import { createFilter, RawFilter } from "models/filter-setup";
import { ProgramFilter } from "models/application-filters";
import { ForceRefreshDataDictionaryFieldsAction } from "state/data-dictionary";
import { CurrentOrganizationStore } from "state/current-organization";
import { Notification } from "service/notification";

@inject
@needs(ReportChart, ChartsEditor)
export class Dashboard {
  public editing: boolean = false;
  private isNew: boolean = false;
  private isPersonalNew: boolean = false;

  public charts: WidgetFormAndWidgetData[] = [];
  public reportForm: FormForReportDefinition = null;
  public saving = false;

  public loading = false;
  public fieldGroupsForms: FieldGroupAndWidgetData[] = [];

  public sorting = false;

  constructor(
    private nav: Navigator,
    private currentUserStore: CurrentUserStore,
    private reportDefinitionsStore: ReportDefinitionsStore,
    private reportService: ReportService,
    private chartDataClick: ChartDataClickService,
    private dialogService: DialogService,
    public organizationModuleService: OrganizationModuleService,
    private currentOrganizationStore: CurrentOrganizationStore,
    private notification: Notification,
    private ffs: FeatureFlagService
  ) { }

  public get dashboardReportDefinition() {
    return this.reportDefinitionsStore.state.dashboardDefinition;
  }

  public get hasDashboard() {
    return this.reportDefinitionsStore.state.resolvedDashboardId != null;
  }

  public get isPersonal() {
    if (this.dashboardReportDefinition == null) return false;
    return this.dashboardReportDefinition.MetaData.DashboardUserId != null;
  }

  get fieldGroups() {
    return !this.loading? this.reportDefinitionsStore.state.currentReportResult?.FieldGroups: [];
  }

  get results() {
    return !this.loading? this.reportDefinitionsStore.state.currentReportResult?.Results: [];
  }

  public async attached() {
    this.loading = true;
    await dispatch(new EnsureResolvedDashboardAction());
    this.loading = false;
    this.runReport();
  }

  private async runReport() {
    if (this.dashboardReportDefinition == null) return;

    this.loading = true;
    await dispatch(new RunReportAction(this.dashboardReportDefinition.Id));
    this.loading = false;
  }

  public edit() {
    this.isNew = false;
    this.reportForm = reportDefinitionFormCreator(
      this.dashboardReportDefinition
    );
    this.charts = this.dashboardReportDefinition?.Widgets?.map(c => ({
      widgetForm: widgetDefinitionFormCreator(c),
      data: null,
      dataLoading: true,
    }));

    this.fieldGroupsForms = this.dashboardReportDefinition.FieldGroups.map(r => {
      return {
        fieldGroupForm: fieldGroupFormCreator(r),
        list: r.List.map(item => {
          return { widgetForm: widgetDefinitionFormCreator(item), data: null, dataLoading: true };
        }),
      };
    });

    this.editing = true;
  }

  public cancel() {
    this.isNew = false;
    this.isPersonalNew = false;
    this.editing = false;
    this.reportForm = null;
    this.charts = [];
  }

  public async save() {
    this.saving = true;
    try {
      this.reportForm.FieldGroups = this.fieldGroupsForms.map(fg => {
        fg.fieldGroupForm.List = fg.list.map(c => c.widgetForm);
        return fg.fieldGroupForm;
      });

      if (this.isNew) {
        var dashboardUserId = this.currentUserStore.state.user.Id || (
          this.currentUserStore.state.isGlobalPrincipal && !this.currentUserStore.state.impersonating
          ? this.currentUserStore.state.principal.Id: null)
        const action = new CreateReportDefinitionAction(
          this.reportForm,
          !this.isPersonalNew,
          this.isPersonalNew ? dashboardUserId : null
        );

        await dispatch(action);
        this.reportForm.Id = action.createdId;
      } else {
        const action = new UpdateReportDefinitionAction(this.reportForm);
        await dispatch(action);
      }

      if (this.ffs.isFeatureFlagEnabled("ElectiveIndexing")) {
        const taskRequestId = await this.reportService.indexNotIndexedFields(this.reportForm.updatedModel());
        if(taskRequestId) {
          await dispatch(new ForceRefreshDataDictionaryFieldsAction());
        }
      }

      await this.runReport();

      this.cancel();
    } catch (err) {
      console.log(err);
      if(err.message !== "Not Valid") {
        this.notification.error("Error saving dashboard");
      }
    }

    this.saving = false;
  }

  public startNew() {
    this.isNew = true;
    this.reportForm = reportDefinitionFormCreator(null);
    this.reportForm.Name = "Dashboard";
    this.editing = true;
  }

  public async dataClick(data: DataClickEvent) {
    if (data == null) {
      return;
    }

    const groupFilter = this.chartDataClick.process(data);
    if(this.dashboardReportDefinition.Filter){
      const filterSplitted = this.dashboardReportDefinition.Filter.split(":");
      if(filterSplitted.length == 2 && filterSplitted[0] == "programId"){
        groupFilter.filters.push(createFilter(
          ProgramFilter,
          s => (s.programId = filterSplitted[1])
        ));
      } else {
        groupFilter.filters.push(createFilter(
          RawFilter,
          s => (s.filter = this.dashboardReportDefinition.Filter)
        ));
      }


    }

    switch (data.WidgetData.Definition.DataSource) {
      case ChartDataSource.Admissions:
        if (!groupFilter) {
          return;
        }
        await dispatch(new SetFilterAction(groupFilter, true));
        this.nav.navigate("/applicants");

        break;

      case ChartDataSource.Contacts:
        if (!groupFilter) {
          return;
        }
        const contactType = data.WidgetData.Definition.ContactType;
        await dispatch(new SetSelectedContactTypeAction(contactType, groupFilter));
        await dispatch(new SetContactsFilterAction(contactType, groupFilter, true));
        if (this.currentOrganizationStore.state.customization.contactsEnabled)
          this.nav.navigate("/contacts");

        break;

      case ChartDataSource.Tasks:
        let tasksFilter: string = null;
        switch (data.WidgetData.WidgetType) {
          case "tasks":
            switch (data.Data.type) {
              case "overdue":
                tasksFilter = TaskListDueDateFilter.Overdue;
                break;
              case "today":
                tasksFilter = TaskListDueDateFilter["Due Today"];
                break;
              case "soon":
                tasksFilter = TaskListDueDateFilter["Due Soon"];
                break;
            }

            await dispatch(new ChangeTaskFiltersAction(groupFilter, tasksFilter, TaskListType.MyOpen, true));
            this.nav.navigate("/tasks");

            break;

          case "grid-view":
            await dispatch(new EnsureUserTaskStoreAction());
            var result = await this.dialogService.open<UserTask>(EditTask, {
              id: data.Data.id
            }, {
              ariaLabel: "Task dialog",
            });
            if (!result.canceled)
              await this.runReport();

            break;
        }

        break;

      case ChartDataSource.Events:
        const eventId = data?.Data?.id;

        if (this.currentOrganizationStore.state.customization.calendarEnabled && !!eventId) {
          switch (data?.WidgetData?.Definition?.DataContext) {
            case CalendarEventTypes.Series:
              this.nav.navigate(`/calendar/events/${eventId}/details`);
              break;

            case CalendarEventTypes.Instance:
              const [ instanceId, seriesId ] = eventId.split('_');
              if (!!instanceId && !!seriesId) {
                this.nav.navigate(`/calendar/events/${seriesId}/${instanceId}/details`);
              }
              break;
          }
        }

        break;
    }
  }

  public personalize() {
    // make copy of current dashboard
    this.isNew = true;
    this.isPersonalNew = true;

    this.reportForm = reportDefinitionFormCreator(
      this.dashboardReportDefinition
    );

    this.fieldGroupsForms = this.dashboardReportDefinition.FieldGroups.map(r => {
      return {
        fieldGroupForm: fieldGroupFormCreator(r),
        list: r.List.map(item => {
          return { widgetForm: widgetDefinitionFormCreator(item), data: null, dataLoading: true };
        }),
      };
    });

    this.reportForm.Name = "Personal Dashboard";
    this.editing = true;
  }

  public async revert() {
    if (!this.isPersonal) return;
    this.saving = true;

    await dispatch(
      new DeleteReportDefinitionAction([this.dashboardReportDefinition.Id])
    );
    await dispatch(new EnsureResolvedDashboardAction(true));
    await this.runReport();

    this.saving = false;
    this.cancel();
  }

  public get organizationName(): string {
    return this.currentOrganizationStore.state.organization?.Name;
  }
}
