import { inject } from "fw";
import { createFrom, createFromArray } from "fw-model";

import { ATS } from "./ats";

import { Role } from "models/role";
import { SearchAfterListResult } from "models/search-after-list-result";

@inject
export class RoleRepository {
  constructor(private s: ATS) { }

  public async getIds(ids: string[]) {
    const res = await this.s.post<Role[]>("role/batch", ids, {
      skipMissing: true,
    });
    return createFromArray(Role, res.body);
  }

  public async search(
    filter: string,
    search: string,
    sort: string = null,
    previousPageToken: string = null,
    nextPageToken: string = null,
    fieldsPaths: string = null,
    pageSize: number = 200
  ) {
    const res = await this.s.get<SearchAfterListResult<Role>>("role/search", {
      filter,
      search,
      sort,
      pageSize,
      previousPageToken,
      nextPageToken,
      fieldsPaths
    });
    return createFrom(SearchAfterListResult<Role>, res.body);
  }

  async list() {
    const res = await this.s.get<any[]>("role");
    return createFromArray(Role, res.body);
  }

  async post(role: Role) {
    const res = await this.s.post("role", role);
    return createFrom(Role, res.body);
  }

  async put(role: Role, force: boolean = false) {
    const res = await this.s.put(`role/${role.Id}${force ? "?force=true" : ""}`, role);
    return createFrom(Role, res.body);
  }

  async del(id: string) {
    await this.s.delete(`role/${id}`);
  }
}
