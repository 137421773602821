import { inject, prop, ComponentEventBus } from "fw";
import { groupBy } from "lodash-es";

import { FormTypeCode } from "models/form";
import { calculatedFieldPath, formPath, questionPath } from "models/role";
import { FormStore } from "state/forms";
import { DataPolicyService } from "service/data-policy";
import { PopoverService } from "service/popover";
import { FeatureFlagService } from "service/feature-flag";

import {
  OptionChoice,
  CategoryOptionChoice,
  OptionChooserPopover,
  Options
} from "views/reports/charts/option-chooser-popover";
import { DataDictionaryService } from "service/data-dictionary";
import { DataDictionaryField, DataDictionaryFieldDataSource, DataDictionaryIndexStatus } from "models/data-dictionary";
import { CategoryDataDictionaryFields, DataDictionaryFieldSelectorOptions, DataDictionaryFieldSelectorPopover } from "../data-dictionary-field-selector-popover";

@inject
export class FormFieldSelector {
  @prop(null) public fieldPath!: string;
  @prop(null) public formKey!: string;
  @prop(null) public sectionKey!: string;
  @prop(null) public validation;

  public fieldHash: { [path: string]: string } = {};
  public formHash: { [path: string]: string } = {};
  private fieldOptions: CategoryDataDictionaryFields[] = [];
  private selectedFieldOption: DataDictionaryField = null;
  private fieldPathOptions: CategoryOptionChoice<string>[] = [];

  // regex expression that matches any text that ends with .rows[] (e.g., address.rows[]) or .rows.[\w] (e.g., address.rows.someword) but not .rows
  private isTableSectionPathRegex = /\.rows(\[\]|\.\w+){1}$/;///\.rows(\.\w+)?$/;

  constructor(
    private ceb: ComponentEventBus,
    private dataDictionaryService: DataDictionaryService,
    private dataPolicy: DataPolicyService,
    private formStore: FormStore,
    private popover: PopoverService,
    private ffs: FeatureFlagService
  ) { }

  public async attached() {
    if (this.ffs.isFeatureFlagEnabled("ElectiveIndexing")) {
      await this.updateFields();
    } else {
      this.updateFieldsPreElectiveIndexingFF();
    }
  }

  private async updateFields() {
    const ffTableSectionRowLevelSearching = this.ffs.isFeatureFlagEnabled("TableSectionRowLevelSearching");
    let formFields = await this.dataDictionaryService.getByPrefix("forms.");
    const onlyTableSectionFields = ffTableSectionRowLevelSearching && this.formKey && this.sectionKey;
    if (onlyTableSectionFields) {
      formFields = formFields.filter(f => f.Path?.startsWith(`forms.${this.formKey}.${this.sectionKey}`) 
      || (!f.FormId) && f.FormKey === this.formKey);
    }

    if (formFields.length === 0) {
      this.fieldHash = {};
      this.formHash = {};
      return;
    }

    this.selectedFieldOption = formFields.find(f => f.Path === this.fieldPath);
    const fieldHash: { [path: string]: string } = {};
    const formHash: { [path: string]: string } = {};
    this.fieldOptions = [
      { category: "", fields: [] },
    ];

    const formStepPathRestrictions = this.dataPolicy.formStepPaths.map(p => p.startsWith("forms") ? p : `forms.${p}`);
    const groupedFormFieldsByFormKey = groupBy(formFields, f => f.FormKey);
    for (const formKey in groupedFormFieldsByFormKey) {
      // check if entire form is restricted
      if (!formKey || formStepPathRestrictions.includes(`forms.${formKey}.*`)) {
        continue;
      }

      const fields = groupedFormFieldsByFormKey[formKey];
      const formPlaceHolderField = fields.find(f => f.FormKey == formKey && !f.FormId)
      if (!formPlaceHolderField) {
        continue;
      }

      const cat: CategoryDataDictionaryFields = { category: formPlaceHolderField.Label, fields: [] };
      for (const field of fields) {
        if(ffTableSectionRowLevelSearching && !onlyTableSectionFields && this.isTableSectionCellField(field)) {
          continue;
        }

        if(ffTableSectionRowLevelSearching && field.IsTableSection && field.JsonDataType === "array" && !this.sectionKey) {
          cat.fields.push(field);
        }

        if (field.IndexStatus === DataDictionaryIndexStatus.Unavailable) {
          continue;
        }

        if (!field.FormId || formStepPathRestrictions.includes(field.Path)) {
          continue;
        }

        // Filter out child fields (e.g., address.state) except for table section fields
        if (field.ParentPath !== formPlaceHolderField.Path && !this.isTableSectionPathRegex.test(field.ParentPath)) {
          continue;
        }

        fieldHash[field.SearchPath ?? field.Path] = field.Label;
        formHash[field.SearchPath ?? field.Path] = formPlaceHolderField.Label;

        cat.fields.push(field);
      }

      if (cat.fields.length > 0) {
        this.fieldOptions.push(cat);
      }
    }

    this.fieldHash = fieldHash;
    this.formHash = formHash;
  }

  private updateFieldsPreElectiveIndexingFF() {
    const { forms } = this.formStore.state;
    if (!forms || forms.length === 0) {
      this.fieldHash = {};
      this.formHash = {};
      return;
    }

    const fieldHash: { [path: string]: string } = {};
    const formHash: { [path: string]: string } = {};
    this.fieldPathOptions = [
      { category: "", options: [] },
    ];

    // forms
    const formStepPathRestrictions = this.dataPolicy.formStepPaths.map(p => p.startsWith("forms") ? p : `forms.${p}`);
    for (const form of forms) {
      if (form.MetaData.Type != FormTypeCode.Applicant) continue;

      // check if entire form is restricted
      if (formStepPathRestrictions.indexOf(formPath(form, "forms", true)) !== -1) continue;

      const cat: CategoryOptionChoice<string> = { category: form.Name, options: [] };

      for (const section of form.Sections) {
        for (const question of section.Questions) {
          const path = questionPath(form, section, question, "forms");
          if (formStepPathRestrictions.indexOf(path) !== -1)
            continue;

          fieldHash[path] = question.Label || question.Key;
          formHash[path] = form.Name;

          cat.options.push({
            text: question.Label || question.Key,
            value: path,
          });
        }
      }

      // calc fields
      for (const calcField of form.CalculatedFields) {
        const path = calculatedFieldPath(form, calcField, "forms");
        if (formStepPathRestrictions.indexOf(path) !== -1)
          continue;

        fieldHash[path] = calcField.Label;
        formHash[path] = form.Name;

        cat.options.push({
          text: calcField.Label,
          value: path,
        });
      }

      if (cat.options.length > 0) {
        this.fieldPathOptions.push(cat);
      }
    }

    this.fieldHash = fieldHash;
    this.formHash = formHash;
  }

  private topLevelFormFieldsFilter = (f: DataDictionaryField) => {
    return f.IndexStatus === DataDictionaryIndexStatus.Indexing
      || f.IndexStatus === DataDictionaryIndexStatus.Indexed
      || f.IsTableSection;
  }

  private isTableSectionCellField(f: DataDictionaryField) {
    // table section cell fields but not their system subfields
    return f.ParentPath?.endsWith(".rows[]") && f.DataSource !== DataDictionaryFieldDataSource.SystemFieldType;
  }

  public async chooseField() {
    if (this.ffs.isFeatureFlagEnabled("ElectiveIndexing")) {
      const ffTableSectionRowLevelSearching = this.ffs.isFeatureFlagEnabled("TableSectionRowLevelSearching");
      const options: DataDictionaryFieldSelectorOptions = {
        filter: ffTableSectionRowLevelSearching
          ? this.topLevelFormFieldsFilter
          : (f: DataDictionaryField) => f.IndexStatus === DataDictionaryIndexStatus.Indexing || f.IndexStatus === DataDictionaryIndexStatus.Indexed,
        options: this.fieldOptions,
        selectedOption: this.selectedFieldOption
      };
      const res = await this.popover.open<DataDictionaryField>(DataDictionaryFieldSelectorPopover, options);
      if (res.canceled) {
        return;
      }

      this.selectedFieldOption = res.result;
      this.ceb.dispatch("field-set", res.result.SearchPath ?? res.result.Path);
    } else {
      const options: Options = {
        hideSearch: true,
        options: this.fieldPathOptions
      }
      const res = await this.popover.open<OptionChoice<string>>(OptionChooserPopover, options);
      if (res.canceled) {
        return;
      }

      this.ceb.dispatch("field-set", res.result.value);
    }
  }

  public get label(): string {    
    return this.fieldPath != null ? this.formHash[this.fieldPath] : '';
  }
}
