import { GridColumnForm } from "forms/grid-column";
import { inject } from "fw";
import { dispatch, handle, Store } from "fw-state";
import { ProgramSettings } from "models/program-settings";
import { ProgramSettingsRepository } from "network/program-settings-repository";
import { FormErrorHandling } from "./error-handling";
import { SetSeasonSettingAction } from "./current-user-settings";
import { GridColumn } from "models/grid-column";
import { StartAction } from "./actions";

interface ProgramSettingsStoreShape {
  seasonId: string;
  programSettings: ProgramSettings;
}

export class UpdateProgramGridColumnsAction {
  constructor(public columns: GridColumnForm[],
    public isDefault: boolean = false) { }
}

export class GridProgramColumnsChangedAction {
  constructor(public original: GridColumn[], public modified: GridColumn[]) { }
}

export class DefaultProgramColumnsChangedAction {
  constructor(public modified: GridColumn[]) { }
}

@inject
export class ProgramSettingsStore extends Store<
  ProgramSettingsStoreShape
> {

  constructor(
    private programSettingsRepository: ProgramSettingsRepository,
  ) {
    super();
  }

  protected defaultState() {
    return {
      seasonId: null,
      programSettings: null
    };
  }

  @handle(StartAction)
  private async handleStart(s: StartAction) {
    this.setState(state => ({
      seasonId: s.context.Season.Id,
      programSettings: s.context.Season.ProgramSettings
    }));
  }

  @handle(UpdateProgramGridColumnsAction, FormErrorHandling)
  private async handleUpdateProgramGridColumnsAction(
    action: UpdateProgramGridColumnsAction,
  ) {
    for (const f of action.columns) {
      f.validate();
    }

    const columns = action.columns.map(a => a.updatedModel());
    const value = action.isDefault
      ? null
      : columns
    const saveAction = new SetSeasonSettingAction('program.grid.columns', value);
    await dispatch(saveAction);

    const { GridColumns } = this.state.programSettings;
    if (action.isDefault) {
      await this.programSettingsRepository.putProgramGridColumns(
        columns
      );
      this.setState(state => ({
        ...state,
        programSettings: {
          ...state.programSettings,
          GridColumns: columns,
          DefaultGridColumns: columns,
        }
      }));
      await dispatch(new DefaultProgramColumnsChangedAction(columns));
    }
    else {
      this.setState(state => ({
        ...state,
        programSettings: {
          ...state.programSettings,
          GridColumns: columns,
        }
      }));
    }
    await dispatch(new GridProgramColumnsChangedAction(GridColumns, columns));
  }
}
