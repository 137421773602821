import { inject } from "fw";
import { DialogController } from "fw-dialog";
import { BedrockFoundationModel } from "models/gen-ai";
import { GenAIRepository } from "network/gen-ai-repository";
import { Notification } from "service/notification";

interface StorageItem {
    key: string;
    value: string;
}

// List of recognized Bedrock settings keys (besides model key which is handled separately)
const KNOWN_GENAI_SETTINGS_KEYS: string[] = 
[
    "genai_settings_bedrock_model_key"
];

@inject
export class GenAISettingsDialog {
    private storageItems: StorageItem[] = [];
    private modelOptions: BedrockFoundationModel[] = [];
    private selectedModelKey: string = '';
    
    constructor(
        private controller: DialogController<void>,
        private notifier: Notification,
        private genAIRepo: GenAIRepository
    ) {}

    async attached() {
        this.loadBedrockStorageItems();
        this.modelOptions = await this.genAIRepo.listAvailableFoundationModels();
        
        const savedKey = localStorage.getItem('genai_settings_bedrock_model_key');
        this.selectedModelKey = savedKey && this.modelOptions.some(m => m.Key === savedKey)
            ? savedKey 
            : this.modelOptions[0]?.Key;
    }

    close() {
        this.controller.close();
    }

    private loadBedrockStorageItems() {
        // Most bedrock settings are displayed as simple text fields/areas in the UI.
        // The model setting is filtered out here because it gets special UI treatment with a dropdown of predefined options.
        this.storageItems = Object.keys(localStorage)
            .filter(key => 
                KNOWN_GENAI_SETTINGS_KEYS.includes(key)&&
                key !== "genai_settings_bedrock_model_key"
            )
            .map(key => ({
                key,
                value: localStorage.getItem(key) ?? "",
            }));
    }

    saveChanges() {
        this.storageItems.forEach(item => {
            localStorage.setItem(item.key, item.value);
        });

        localStorage.setItem('genai_settings_bedrock_model_key', this.selectedModelKey);
        
        this.controller.close();
        this.notifier.notify("GenAI settings saved");
    }
}