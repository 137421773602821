import { prop, ComponentEventBus, inject } from "fw";
import offset from "document-offset";
import { unionBy } from "lodash-es";

import {
  Completer,
  getTokenWord,
  replaceVariable,
  VariablePicked,
} from "helpers/auto-complete";
import { CustomFieldType, defaultCompletionFieldTypes } from "models/contact-organization";
import { FunctionType } from "models/function";
import {
  PopoverService,
  PopoverCoordinator,
} from "service/popover";
import { EditorPopover } from "views/components/editor-popover";

let uniqueId: number = 0;

@inject
export class TextFieldAdorned {
  constructor(
    private ceb: ComponentEventBus,
    private coordinator: PopoverCoordinator,
    private popover: PopoverService,
  ) { }

  @prop('') public adornment!: string;
  @prop('') public label!: string;
  @prop(false) public hideLabel!: boolean;
  @prop(false) public hideLock!: boolean;
  @prop('') public placeholder!: string;
  @prop('') public value!: string;
  @prop('') public validation!: string;
  @prop(null) public type!: 'email' | 'text' | 'url';
  @prop(null) public min!: number;
  @prop(null) public max!: number;
  @prop(null) public maxlength!: number;
  @prop(false) public readonly!: boolean;
  @prop(false) public required: boolean;
  @prop(false) public disabled!: boolean;
  @prop(false) public floatingLabel!: boolean;
  @prop('') public ariaLabel!: string;
  @prop('') public helpText!: string;
  @prop('') public autocomplete!: string;
  @prop(null) public completer!: Completer | Completer[];
  @prop('') public contactType!: string;
  @prop('') public meta!: string;
  @prop(() => defaultCompletionFieldTypes) completionTypes: Array<CustomFieldType | FunctionType>;
  @prop(true) public handlebars: boolean;
  @prop(false) public showClear!: boolean;

  private uniqueId: number = uniqueId++;
  private isFocused: boolean = false;
  private inputEl: HTMLInputElement = null;
  private hinting: boolean = false;

  private get unadornedValue(): string {
    return this.value?.split(this.adornment)[0];
  };

  public get locked(): boolean {
    return this.disabled || this.readonly;
  }

  public get inputAriaLabel(): string {
    let label = [];

    if (this.ariaLabel) {
      label.push(this.ariaLabel);
    }
    if (this.locked) {
      label.push('Field Locked.');
    }
    return label.join(' ');
  }

  public get labelAsId(): string {
    return `${this.label ? this.label.replace(/\s/g, '') : ''}`;
  }

  public makeId(): string {
    return `${this.labelAsId}-tf-${this.uniqueId}`;
  }

  public makeLabelId(): string {
    return `${this.labelAsId}-label-tf-${this.uniqueId}`;
  }

  public makeErrorId(): string {
    return`validation-error-${this.uniqueId}`;
  }

  public onInput(value): void {
    let adornedValue = '';
    if (!!value) {
      adornedValue = `${value}${this.adornment}`;
    }
    this.ceb.updateModel(adornedValue);
    this.ceb.dispatch('update:value', adornedValue);
  }

  public onFocus(e): void {
    this.isFocused = true;
    this.ceb.dispatch('focus', e);
  }

  public onBlur(e): void {
    this.isFocused = false;
    this.ceb.dispatch('blur', e);
  }

  public onChange(): void {
    this.ceb.dispatch('change');
  }

  public onKeyEvent(e): void {
    this.ceb.dispatch('keypress', e);
  }

  public onKeyUp(e): void {
    if (this.completer) {
      this.handleKeyEvent(e, 'keyUp');
    }

    this.ceb.dispatch('keyup');
  }

  public onEnterClick(): void {
    this.inputEl.blur();
  }

  public handleKeyEvent(e: KeyboardEvent, type: string): void {
    const key = e.keyCode,
      enter = 13,
      up = 38,
      down = 40,
      tab = 9;
    const specialPressed =
      key == enter || key == up || key == down || key == tab;

    if (type === 'keyDown' && this.hinting && specialPressed)
      e.preventDefault();
    else if (type === 'keyUp' && (!this.hinting || !specialPressed)) {
      this.coordinator.closeAll();
      this.hinting = false;
      this.hintVariables();
    }
  }

  public clear(): void {
    this.ceb.dispatch('update:value', null);
    this.ceb.dispatch('clear');
  }

  private async hintVariables(): Promise<void> {
    if (!this.completer) return null;

    const tw = getTokenWord(
      this.inputEl.value,
      this.inputEl.selectionStart,
      this.inputEl.selectionEnd
    );
    if (!tw) return null;

    const completer = !Array.isArray(this.completer)
      ? [this.completer]
      : this.completer;
    const getCompletions = async (i) =>
      await i.getCompletions(
        tw.token,
        tw.word,
        this.completionTypes,
        this.contactType,
        this.handlebars
      );
    const variables = unionBy.apply(this, [
      ...(await Promise.all(completer.map(getCompletions))),
      'token',
    ]);
    if (!variables.length || !variables[0] || (variables.length === 1 && variables[0].token === tw.word)) return null;

    // make a marker where the popover should appear
    const textPosition = offset(this.inputEl);
    const textareaCaret = (await import('textarea-caret')) as any;
    const position = textareaCaret.default(
      this.inputEl,
      this.inputEl.selectionEnd
    );
    const marker = document.createElement('span');
    marker.style.top = `${textPosition.top + position.height + 10}px`;
    marker.style.left = `${textPosition.left + position.left + 10}px`;
    marker.style.position = 'absolute';
    document.body.append(marker);

    this.hinting = true;
    const data = { variables: variables };
    const variablePicked = await this.popover.open<VariablePicked>(
      EditorPopover,
      data,
      marker,
      false,
      false
    );
    marker.remove();

    if (variablePicked.result) {
      const { variable } = variablePicked.result;
      const value = this.inputEl.value;
      const tokenWord = getTokenWord(
        value,
        this.inputEl.selectionStart,
        this.inputEl.selectionEnd
      );
      const { newValue, newPosition } = replaceVariable(
        variable,
        value,
        tokenWord
      );

      this.inputEl.focus();
      this.onInput(newValue);

      // put cursor at the end of the replaced text
      setTimeout(() => {
        this.inputEl.selectionEnd = newPosition;
        this.hinting = false;
        this.hintVariables();
      }, 1);
    }
  }
}
