import { prop, inject, ComponentEventBus } from "fw";
import { Form } from "fw-model";
import { Completer } from "helpers/auto-complete";
import { CustomFieldType, defaultCompletionFieldTypes } from "models/contact-organization";
import { FunctionType } from "models/function";

@inject
export class TextFormFieldAdorned {
  @prop('') public adornment!: string;
  @prop(null) public form!: Form;
  @prop('') public field: string;
  @prop('') public label!: string;
  @prop(false) public hideLabel!: boolean;
  @prop('') public ariaLabel!: string;
  @prop(null) public type!: 'email' | 'text' | 'url';
  @prop(null) public setfocus;
  @prop(false) public readonly!: boolean;
  @prop(null) public maxlength!: number;
  @prop(false) public disabled!: boolean;
  @prop(false) public floatingLabel!: boolean;
  @prop('') public autocomplete!: string;
  @prop('') public placeholder!: string;
  @prop(false) public required: boolean;
  @prop(null) public completer!: Completer | Completer[];
  @prop('') public contactType!: string;
  @prop('') public helpText!: string;
  @prop(() => defaultCompletionFieldTypes) completionTypes: Array<CustomFieldType | FunctionType>;

  private name: string = '';
  private $refs: any;

  constructor(private ceb: ComponentEventBus) {}

  public attached() {
    this.formChanged();
    this.setInputPadding();
  }

  private set unadornedValue(value: string) {
    this.form[this.field] = this.adornValue(value);
  };

  private get unadornedValue(): string {
    return this.form[this.field]?.split(this.adornment)[0];
  };

  private adornValue(value: string): string {
    let adornedValue = '';
    if (!!value) {
      adornedValue = `${value}${this.adornment}`;
    }
    return adornedValue;
  };

  private setInputPadding() {
    const adornmentEl = this.$refs['adornmentEl'];
    const textFieldEl = this.$refs['textFieldEl']?.$el.querySelector('.mdl-textfield__input');
    if (!!textFieldEl) {
      textFieldEl.style.paddingRight = `${adornmentEl?.offsetWidth + 10}px`;
    }
  }

  private formChanged() {
    this.name = this.form && this.form.getFieldName ? this.form.getFieldName(this.field) : this.field;
  }

  private onChange() {
    this.ceb.dispatch('change');
  }

  private onKeyUp() {
    this.ceb.dispatch('keyup');
  }

  private onKeyDown() {
    this.ceb.dispatch('keydown');
  }

  private onUpdateValue(value) {
    this.ceb.dispatch('update:value', this.adornValue(value));
  }
}
