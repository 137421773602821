import { inject, Navigator } from "fw";
import { dispatch } from "fw-state";

import { AppRepository, OrganizationWithDomainView } from "network/app-repository";
import { UserRepository } from "network/user-repository";
import { CurrentUserStore } from "state/current-user";
import { CurrentOrganizationStore } from "state/current-organization";
import { SwitchOrganizationAction } from "state/actions";

@inject
export class SwitchOrganizations {
  private userOrganizations: OrganizationWithDomainView[] = null;
  private isLoading = false;

  constructor(
    private appRepo: AppRepository,
    private currentUserStore: CurrentUserStore,
    private userRepo: UserRepository,
    private nav: Navigator,
    private currentOrganizationStore: CurrentOrganizationStore
  ) {}

  public async attached() {
    if (!this.currentUserStore.state.canSwitchOrgs) {
      this.nav.navigate("/");
    } else {
      const allowedOrgs = await this.appRepo.allowedUserOrganizations();
      this.userOrganizations = allowedOrgs.OrganizationWithDomainViews;
    }
  }

  private async switchToOrganization(domain: string, organizationId: string) {
    if (this.currentUserStore.state.isLoggedViaVanityDomain) {
      this.isLoading = true;
      await dispatch(new SwitchOrganizationAction(organizationId));
      this.isLoading = false;
      this.nav.navigate("/");
      return;
    }

    const bearerToken = await this.userRepo.switchOrganization(organizationId);
    if (
      this.currentUserStore.state.isGlobalPrincipal == true ||
      this.currentUserStore.state.isGlobalAdmin == true
    ) {
      var hostname = `${
        window.location.hostname == "localhost" ? "http://" : "https://"
      }${domain}/#/apiKey=${encodeURIComponent(bearerToken)}`;
      window.setTimeout(async () => {
        window.open(hostname);
      }, 2000);
    } else {
      window.location.href = `${
        window.location.hostname == "localhost" ? "http://" : "https://"
      }${domain}/#/apiKey=${encodeURIComponent(bearerToken)}`;
      window.setTimeout(async () => {
        window.location.reload();
      }, 2000);
    }
  }
}
