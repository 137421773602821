import { inject } from "@derekpitt/fw";
import { DialogController } from "fw-dialog";

type Args = {
  title: string;
  text: string;
};

@inject
export class ViewMore {
  private title: string;
  private text: string;

  constructor(private controller: DialogController<string>) {}

  activate({ title, text }: Args) {
    this.title = title;
    this.text = text;
  }

  close() {
    this.controller.cancel();
  }
}
