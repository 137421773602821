import { inject, needs, prop, ComponentEventBus } from "@derekpitt/fw";

import { FormResolverService } from "../form-resolver-service";
import {
  FormAnswer,
  Form,
  CalculatedField,
  ComputedCalculatedField,
  ComputedSection,
  TableSectionRowFormData,
} from "../models";
import type {
  FileHash,
  FileService,
  CeebRequest,
  DecryptData,
} from "../models";
import { Question } from "./question";
import { validate, isAnswered } from "../validation";
import { SectionTable } from "./section-table";
import { Restrictable } from "./restrictable";

let uniqueId = 0;

const rx_url: RegExp = new RegExp(
  [
    "(?<!<a\\s[^>]*?)",
    "((?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?",
    "(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)",
    "(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])",
    "(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])",
    "(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}",
    "(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|",
    "(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)",
    "(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*",
    "(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?)",
    "(?![^<]*<\\/a>)",
  ].join(""),
  "gi",
);

@inject
@needs(Question, SectionTable, Restrictable)
export class FormWithAnswers {
  @prop(null)
  form: Form;

  @prop(null)
  answers: FormAnswer[];

  @prop(null)
  seenKeys: string[];

  @prop(null)
  baseAnswers: FormAnswer[]; // this is to be used with section tables, to mix in answers from above

  @prop(false)
  readonly: boolean;

  @prop(null)
  fileHash: FileHash;

  @prop(null)
  fileService: FileService;

  @prop(null)
  requestCeeb: CeebRequest;

  @prop(null)
  decryptData: DecryptData;

  @prop(false)
  displayonly: boolean;

  @prop(true)
  showValidation: boolean;

  @prop(true)
  showOptions: boolean;

  @prop(() => [])
  restrictions: string[];

  @prop(undefined)
  programPropertiesHash: {
    [questionKey: string]: string | boolean;
  };

  @prop(null)
  summarize: Function;

  private resolved: ComputedSection[] = null;
  private myself = FormWithAnswers;
  private answerHash = {};

  // maybe don't need these for everything...
  private calcFieldHash: { [key: string]: CalculatedField } = {};
  private calcFields: ComputedCalculatedField[] = [];
  private uniqueId = uniqueId++;

  constructor(
    private ceb: ComponentEventBus,
    private formResolver: FormResolverService,
  ) { }

  uniqueIdPrefix() {
    return `${this.form.Key}${this.uniqueId}-`;
  }

  setupAnswerHash() {
    const tempAnswerHash: { [key: string]: FormAnswer } = {};

    if (this.answers != null) {
      this.answers.forEach(a => {
        if (a.QuestionKey != null) {
          tempAnswerHash[a.QuestionKey] = a;
        } else if (a.SectionKey != null) {
          tempAnswerHash[a.SectionKey] = a;
        }
      });
    }

    if (this.form == null) return tempAnswerHash;

    this.form.Sections.forEach(section => {
      if (section.IsTableSection) {
        const answer = new FormAnswer();

        const {
          Key,
          MinRowCount,
          NamedRows,
        } = section.TableSectionOptions;
        answer.SectionKey = Key;
        answer.SectionAnswers = [];

        // TODO: we need to handle when form version changes and we update the row
        // count and or the named rows...

        const currentAnswer = tempAnswerHash[Key];

        const resolveAnswers = (row: TableSectionRowFormData) => {
          for (const question of section.Questions) {
            if (row.Answers.find(a => a.QuestionKey == question.Key) == null) {
              const newAnswer = new FormAnswer();
              newAnswer.QuestionKey = question.Key;

              if (this.showValidation)
                validate(this.form, question, newAnswer);

              row.Answers.push(newAnswer);
            }
          }
        };

        if (NamedRows.length > 0) {
          for (const nr of NamedRows) {
            const newRow = new TableSectionRowFormData();
            newRow.Answers = [];
            newRow.RowKey = nr.Key;
            answer.SectionAnswers.push(newRow);

            if (currentAnswer != null) {
              const currentSectionAnswer = currentAnswer.SectionAnswers.find(aa => aa.RowKey == nr.Key);
              if (currentSectionAnswer == null)
                currentAnswer.SectionAnswers.push(newRow);
            }
          }
        } else if (MinRowCount > 0) {
          for (let i = 0; i < MinRowCount; i++) {
            const newRow = new TableSectionRowFormData();
            newRow.Answers = [];
            answer.SectionAnswers.push(newRow);
          }
        }

        if (currentAnswer == null) {
          tempAnswerHash[Key] = answer;
        }

        for (const sa of tempAnswerHash[Key].SectionAnswers) {
          resolveAnswers(sa);
        }
      } else {
        section.Questions.forEach(q => {
          const answer = new FormAnswer();
          answer.QuestionKey = q.Key;

          if (this.showValidation)
            validate(this.form, q, answer);

          if (tempAnswerHash[q.Key] == null) {
            tempAnswerHash[q.Key] = answer;
          }
        });
      }
    });

    this.answerHash = tempAnswerHash;
  }

  formChanged() {
    this.created();
  }

  answersChanged() {
    this.setupAnswerHash();
  }

  async answerChanged(dispatchAnswerChanged = true) {
    // re eval the question list
    const { sections, calculatedValues } = await this.formResolver.resolveForm(
      this.form,
      this.answerHash,
      this.baseAnswers,
      this.programPropertiesHash,
    );

    const seenQuestionKeys: string[] = [];
    const answers: FormAnswer[] = [];

    sections.forEach(s => {
      const section = this.form.Sections[s.sectionIndex];
      if (section.IsTableSection) {
        // need more
        const answer = this.answerHash[section.TableSectionOptions.Key];
        const prefix = section.TableSectionOptions.Key;
        const isNamed = section.TableSectionOptions.NamedRows.length > 0;

        answer.SectionAnswers.forEach((a, rowIndex) => {
          let key = prefix;
          const rowsPrefix = ".rows";
          if (isNamed) {
            key += rowsPrefix + "." + a.RowKey;
          } else {
            key += rowsPrefix + "[" + rowIndex + "]";
          }

          // TODO: something with row keys...
          a.Answers.forEach(aa => {
            const question = section.Questions.find(
              q => q.Key == aa.QuestionKey,
            );

            if (question != null) {
              seenQuestionKeys.push(key + "." + aa.QuestionKey);
            }
          });
        });

        answers.push(answer);
      } else {
        s.questions.forEach(q => {
          const question = section.Questions[q.index];

          seenQuestionKeys.push(question.Key);
          answers.push(this.answerHash[question.Key]);
        });
      }
    });

    console.log("new answers", answers, seenQuestionKeys);
    this.ceb.dispatch("update:answers", answers);
    this.ceb.dispatch("update:seenKeys", seenQuestionKeys);

    this.resolved = sections;
    this.calcFields = calculatedValues;

    this.ceb.dispatch("calculated-change", calculatedValues);

    if (dispatchAnswerChanged) {
      this.ceb.dispatch("answer-changed");
    }
  }

  created() {
    if (this.form == null) return;

    this.setupAnswerHash();

    const calcFieldHash: { [id: string]: CalculatedField } = {};
    this.form.CalculatedFields.forEach(cf => (calcFieldHash[cf.Key] = cf));
    this.calcFieldHash = calcFieldHash;

    this.answerChanged(false);
  }

  linkify(text) {
    return text.replace(rx_url, '<a href="$1" target="_blank">$1</a>');
  }
}
