import { inject } from "fw";
import { Store, handle, dispatch } from "fw-state";
import { createFrom } from "fw-model";

import {
  LogoutAction,
  StartAction,
  ApplyUserChangeAction,
  RemoveUsersFromTeams,
  ApplyFeatureFlagsAction,
  UpdateInviteCountAction,
  WatchTaskAction,
  RefreshFileProviderAction
} from "./actions";
import { User, UserInvitation } from "models/user";

import { UserRepository } from "network/user-repository";
import { InviteRepository } from "network/invite-repository";
import { OrganizationRepository } from "network/organization-repository";
import { wait } from "wait";
import { FormErrorHandling } from "./error-handling";

import {
  AddInviteAction,
  ResendInvitesAction,
  DeleteInvitesAction,
  UpdateInviteRoleAction
} from "forms/invite-user";
import {
  UpdateUserMembershipAction,
  UpdateUserCollaborationModulesAction,
  AddUserAction,
  UpdateUserInSeasonAction
} from "forms/user";
import { difference } from "lodash-es";
import type { FeatureFlagSet } from "models/feature-flag-set";
import { TeamMember } from "../models/team";
import { EntitySelection } from "models/application-client-model";
import { ExportDefinition, ExportFormatTypeCode } from "models/export-definition";
import { TaskRequest } from "models/task-request";
import { ExportRepository } from "network/export-repository";
import { EnsureUserInsideTeamAction } from "./teams";
import uniq from "lodash/uniq";
import { EnsureRolesAction } from "state/roles";
import { ConditionBuilderExpression } from "views/components/condition-builder-expression";
import { FeatureFlagService } from "service/feature-flag";

interface UserShape {
  filter: string;
  filterArg?: string;
  filterType?: string;
  search: string;
  page: number;
  pageSize: number;
  sort: string;
  total: number;

  currentUserPage: User[];
  userHash: { [id: string]: User };
  users: User[];
  loaded: boolean;
  loading: boolean;

  invites: UserInvitation[];
}

export class ExportUsersAction {
  public taskId: string = null;

  constructor(
    public selection: EntitySelection,
    public format = ExportFormatTypeCode.JSON,
    public exportDefinition: ExportDefinition = null,
    public exportDefinitionId: string = null,
    public fileProviderId: string = null,
    public fileProviderFolder: string = null,
  ) { }
}

export class UsersDeleteAction {
  constructor(
    public userIds: string[],
    public organizationId: string,
    public collaborationOnly = false
  ) { }
}

export class EnsureUsersAction {
  constructor(public userIds: string[]) { }
}

export class EnsureListAction {
  constructor(
    public filter: string,
    public search: string,
    public sort: string = null
  ) { }
}

export class RefreshListAction { }
export class NextPageAction { }
export class PrevPageAction { }

export class SetFilterAction {
  // clear others will set other surrounding state
  // to null so the filter container can stand alone
  constructor(public filter: string, public filterArg, public filterType, public clearOthers = false) { }
}

const defaultUserSort = "lastName firstName";

export class UpdateUserTeamIdsAction {
  constructor(
    public teamId: string,
    public userId: string,
    public seasonId: string,
    public type: "update" | "remove"
  ) { }
}

export class ToggleUsersSortAction {
  constructor(public sort: string) { }
}

export const defaultUser = createFrom(User, {
  Id: "",
  EmailAddress: "",
  FirstName: null,
  LastName: null,
  FullName: null,
  DateLastLogin: null,
  IsGlobalAdmin: false,
  AvatarFileId: null,
});


// outside of UsersStore to prevent vue from tracking..
let idsToLoad: string[] = [];
let idsToLoadLoading: string[] = [];

@inject
export class UsersStore extends Store<UserShape> {

  private orgId: string = null;
  private seasonId: string = null;

  constructor(
    private userRepo: UserRepository,
    private inviteRepo: InviteRepository,
    private orgRepo: OrganizationRepository,
    private exportRepo: ExportRepository,
    private ffs: FeatureFlagService
  ) {
    super();
  }

  defaultState() {
    return {
      filter: null,
      filterArg: null,
      filterType: null,
      search: null,
      page: 1,
      pageSize: 20,
      sort: defaultUserSort,
      loaded: false,
      loading: false,
      total: 0,

      currentUserPage: [],
      userHash: {},
      invites: [],
      users: []
    };
  }

  private getLoadedUsers(filter: (u: User) => boolean = null): User[] {
    const users: User[] = [];

    users.push(
      ...(filter != null
        ? this.state.currentUserPage.filter(u => filter(u))
        : this.state.currentUserPage)
    );

    for (let id in this.state.userHash) {
      if (filter == null || filter(this.state.userHash[id]))
        users.push(this.state.userHash[id]);
    }

    return users;
  }

  private getWholeFilter(filter: string, searchValue: string): string {
    if (searchValue && filter) {
      return `${filter} ${searchValue}`;
    }

    return filter || searchValue;
  }

  private async loadPage() {
    const { filter, search, sort, page, pageSize } = this.state;

    const res = await this.userRepo.list(this.getWholeFilter(filter, search), null, sort, page, pageSize);
    const roleIds = res.list.flatMap(u => u.Memberships.map(m => m.RoleId));
    await dispatch(new EnsureRolesAction(roleIds));
    this.setState(state => ({
      ...state,
      loading: false,
      loaded: true,
      currentUserPage: res.list,
      total: res.total
    }));
  }

  @handle(StartAction)
  private async handleStart(s: StartAction) {
    const { Invites, Users, FeatureFlags, Organization } = s.context;

    this.handleFeatureFlags(FeatureFlags);

    this.orgId = Organization.Id;
    this.seasonId = Organization.ActiveSeasonId;
    this.setState(state => ({
      ...state,
      ...this.defaultState(),
      invites: Invites,
      users: Users || []
    }));
  }

  @handle(ApplyFeatureFlagsAction)
  private handleFeatureFlags(flags: FeatureFlagSet) {

  }

  @handle(LogoutAction)
  private handleLogout() {
    this.orgId = null;
    this.setState(state => this.defaultState());
  }

  @handle(AddInviteAction)
  private async handleAddInviteAction(action: AddInviteAction) {
    action.form.validate();

    const invites = await this.inviteRepo.post(
      action.form.EmailAddresses,
      action.form.RoleId,
      action.form.CollaboratorRoleIds,
      action.form.CollaboratorTeamIds
    );

    this.setState(state => ({
      ...state,
      invites: [...invites, ...state.invites]
    }));
  }

  @handle(ResendInvitesAction)
  private async handleResendInvitesAction(action: ResendInvitesAction) {
    await this.inviteRepo.resend(action.inviteIds);
  }

  @handle(DeleteInvitesAction)
  private async handleDeleteInvitesAction(action: DeleteInvitesAction) {
    await this.inviteRepo.delete(action.inviteIds);

    const invitesToBeDeleted = this.state.invites.filter(u =>
      action.inviteIds.some(id => id == u.Id)
    );

    this.setState(state => ({
      ...state,
      invites: difference(state.invites, invitesToBeDeleted)
    }));
  }

  @handle(UsersDeleteAction)
  private async handleUsersDeleteAction(action: UsersDeleteAction) {
    // apply on server
    await this.userRepo.deleteMembership(
      action.userIds,
      action.organizationId,
      action.collaborationOnly
    );

    // apply in memory
    if (action.collaborationOnly) {
      // only "deleting" them as a collaborator

      const users = this.getLoadedUsers();

      // remove their collaborator roles
      users.forEach(u => {
        const orgMemberships = u.Memberships.filter(
          m => m.OrganizationId == action.organizationId
        );
        for (const membership of orgMemberships) {
          membership.CollaboratorRoleIds = [];
        }
      });

      // remove them from teams
      await dispatch(
        new RemoveUsersFromTeams(action.userIds, action.collaborationOnly)
      );

      this.setState(s => s);
    } else {
      // deleting them completely, remove from hash and refresh list
      action.userIds.forEach(id => {
        if (this.state.userHash[id] != null) delete this.state.userHash[id];
      });

      dispatch(new RefreshListAction());
    }

    dispatch(new UpdateInviteCountAction());

  }

  @handle(ApplyUserChangeAction)
  private handleApplyUserChangeAction(action: ApplyUserChangeAction) {
    const existingUsers = this.getLoadedUsers(u => u.Id == action.user.Id);
    existingUsers.forEach(u => {
      Object.assign(u, action.user);
    });
    this.setState(s => s);
  }

  @handle(UpdateUserMembershipAction)
  private async handleUpdateUserMembershipAction(
    action: UpdateUserMembershipAction
  ) {
    const { OrganizationId, RoleId, CollaboratorRoleIds } = action.form;

    // apply on server
    await this.userRepo.putMembership(
      action.userId,
      OrganizationId,
      null,
      action.isCollabRoleUpdate ? null : RoleId,
      action.isCollabRoleUpdate ? action.form.CollaboratorRoleIds : null,
      action.form.MaxAssignmentsPerModule,
      action.form.IsAvailableForAssignment,
      action.form.ApplicationMatchingProperties
    );

    // apply in memory
    const existingUsers = this.getLoadedUsers(u => u.Id == action.userId);
    existingUsers.forEach(user => {
      let membership = user.Memberships.find(
        m => m.OrganizationId == action.form.OrganizationId
      );
      if (membership != null) {
        if (action.isCollabRoleUpdate)
          membership.CollaboratorRoleIds = action.form.CollaboratorRoleIds;
        else membership.RoleId = action.form.RoleId;
      }
    });

    this.setState(s => s);
  }

  @handle(UpdateUserCollaborationModulesAction)
  private async handleUpdateUserCollaborationModulesAction(
    action: UpdateUserCollaborationModulesAction
  ) {
    // apply on server
    await this.userRepo.putMembership(
      action.userId,
      action.organizationId,
      null,
      null,
      action.collaboratorRoleIds,
      null,
      null,
      null
    );

    // apply in memory
    const existingUsers = this.getLoadedUsers(u => u.Id == action.userId);
    existingUsers.forEach(user => {
      let membership = user.Memberships.find(
        m => m.OrganizationId == action.organizationId
      );
      if (membership != null)
        membership.CollaboratorRoleIds = action.collaboratorRoleIds;
    });

    this.setState(s => s);
  }

  @handle(UpdateInviteRoleAction)
  private async handleUpdateInviteRoleAction(action: UpdateInviteRoleAction) {
    action.form.validate();

    const existingInvite = this.state.invites.find(i => i.Id == action.form.Id);
    if (existingInvite == null) return;

    const newInvite = await this.inviteRepo.put(action.form.updatedModel());

    Object.assign(existingInvite, newInvite);

    this.setState(s => s);
  }

  @handle(EnsureUsersAction)
  private async handleEnsureUsersAction(action: EnsureUsersAction) {
    if (action.userIds == null) return;
    const idsToFetch = uniq(action.userIds.filter(
      id =>
        id != null &&
        id.length > 0 &&
        this.state.userHash[id] == null &&
        idsToLoad.indexOf(id) == -1 &&
        idsToLoadLoading.indexOf(id) == -1
    ));

    if (idsToFetch.length == 0) {
      return;
    }

    if (idsToLoad.length > 0) {
      // push it on to this thing and return..
      idsToLoad.push(...idsToFetch);
      return;
    }

    idsToLoad.push(...idsToFetch);

    await wait(10); // this is the buffer time, so that multiple components can all get in here
    idsToLoadLoading.push(...idsToLoad);
    idsToLoad = [];
    const newHash: { [id: string]: User } = {};

    try {
      const currentIdsToLoad = idsToLoadLoading.slice();
      const res = await this.userRepo.getIds(currentIdsToLoad);
      if (this.ffs.isFeatureFlagEnabled("RolesLazyLoad")) {
        await dispatch(new EnsureRolesAction(res.flatMap(u => u.Memberships.map(m => m.RoleId))));
      } 

      for (const user of res) {
        newHash[user.Id] = user;

        const idx = idsToLoadLoading.indexOf(user.Id);
        if (idx >= 0) idsToLoadLoading.splice(idx, 1);
      }

      // did anything that got loaded not return from the server?
      // if so, we need to fill it out with the defaultUser
      const idsToBeDefaulted = difference(
        currentIdsToLoad,
        res.map((u) => u.Id)
      );
      for (const id of idsToBeDefaulted) {
        newHash[id] = defaultUser;

        const idx = idsToLoadLoading.indexOf(id);
        if (idx >= 0) idsToLoadLoading.splice(idx, 1);
      }
    } catch (err) {
      idsToLoadLoading = [];
      throw err;
    }

    this.state.userHash = {
      ...this.state.userHash,
      ...newHash
    };
  }

  @handle(RefreshListAction)
  private async handleRefreshListAction(action: RefreshListAction) {
    this.setState(state => ({
      ...state,
      loading: true
    }));

    await this.loadPage();
  }

  @handle(EnsureListAction)
  private async handleEnsureListAction(action: EnsureListAction) {
    const { filter, search, sort, loaded } = this.state;
    if (
      action.filter == filter &&
      action.search == search &&
      action.sort == sort &&
      loaded
    )
      return;

    this.setState(state => ({
      ...state,
      filter: action.filter,
      search: action.search,
      sort: action.sort ?? defaultUserSort,
      page: 1,
      loading: true
    }));

    await this.loadPage();
  }

  @handle(NextPageAction)
  private async handleNextPageAction() {
    this.setState(state => ({
      ...state,
      page: state.page + 1,
      loading: true
    }));

    await this.loadPage();
  }

  @handle(PrevPageAction)
  private async handlePrevPageAction() {
    this.setState(state => ({
      ...state,
      page: state.page - 1,
      loading: true
    }));

    await this.loadPage();
  }

  @handle(SetFilterAction)
  private async handleSetFilter(s: SetFilterAction) {
    this.setState(state => ({
      ...state,
      filter: s.filter,
      filterArg: s.filterArg,
      filterType: s.filterType,

      page: s.clearOthers ? 1 : state.page,
      pageSize: s.clearOthers ? 20 : state.pageSize,
      loading: true
    }));
    if (s.filterType === "role") {
      await dispatch(new EnsureRolesAction([s.filterArg]))
    }

    await this.loadPage();
  }

  @handle(ToggleUsersSortAction)
  private async handleToggleUsersSortAction(ts: ToggleUsersSortAction) {
    const newSort = ts.sort == this.state.sort ? `-(${ts.sort})` : ts.sort;
    await dispatch(
      new EnsureListAction(this.state.filter, this.state.search, newSort)
    );
  }

  @handle(AddUserAction, FormErrorHandling)
  private async handleAddUserAction(action: AddUserAction) {
    action.form.validate();
    const result = await this.userRepo.addUser(action.form.updatedModel(), this.orgId);
    await wait(2000);
    dispatch(new RefreshListAction());
    dispatch(new UpdateInviteCountAction());

    let teamIds = [...action.form.EvaluationTeamIds, ...action.form.CollaboratorTeamIds];
    await dispatch(new EnsureUserInsideTeamAction(teamIds, result.Id));
    action?.callback(result.Id);
  }

  @handle(UpdateUserInSeasonAction, FormErrorHandling)
  private async handleUpdateUserInSeasonAction(
    action: UpdateUserInSeasonAction
  ) {
    action.form.validate();

    const updatedUser = await this.userRepo.putUser(
      action.id,
      action.seasonId,
      action.form.updatedModel()
    );

    this.setState(state => ({
      ...state,
      userHash: {
        ...state.userHash,
        [updatedUser.Id]: updatedUser
      }
    }));

    await wait(2000);
    dispatch(new RefreshListAction());
    dispatch(new UpdateInviteCountAction());

    let teamIds = [...action.form.EvaluationTeamIds, ...action.form.CollaboratorTeamIds];
    await dispatch(new EnsureUserInsideTeamAction(teamIds, action.id));
  }

  @handle(UpdateUserTeamIdsAction)
  private async UpdateUserTeamIdsAction(action: UpdateUserTeamIdsAction) {
    await dispatch(new EnsureUsersAction([action.userId]));
    const user = this.state?.userHash[action.userId];
    if (!user) return;

    const teamIds = user.MetaData.EvaluationTeams.find(i => i.SeasonId === action.seasonId)?.TeamIds;

    if (teamIds.includes(action.teamId)) {
      if (action.type === "remove") {
        const currentTeamIdIndex = teamIds.indexOf(action.teamId);
        teamIds.splice(currentTeamIdIndex, 1);
      }
    } else {
      teamIds.push(action.teamId);
    }

    user.MetaData.EvaluationTeams.find(i => i.SeasonId === action.seasonId).TeamIds = teamIds;

    this.state.userHash[action.userId] = user;

    this.setState(state => ({
      ...state
    }));
  }

  @handle(ExportUsersAction)
  private async handleExportUsersAction(action: ExportUsersAction) {
    let task: TaskRequest = null;

    switch (action.format) {
      case ExportFormatTypeCode.Tabular: {
        task = await this.exportRepo.userTabular(
          action.selection,
          action.exportDefinitionId,
          action.exportDefinition,
          action.fileProviderId,
          action.fileProviderFolder,
        );
        break;
      }
      case ExportFormatTypeCode.JSON: {
        task = await this.exportRepo.userJson(
          action.selection,
          action.exportDefinitionId,
          action.exportDefinition,
          action.fileProviderId,
          action.fileProviderFolder,
        );
        break;
      }
    }

    action.taskId = task.Id;

    if (
      action.fileProviderId != null &&
      action.fileProviderFolder != null &&
      action.fileProviderFolder.length > 0
    ) {
      await dispatch(new RefreshFileProviderAction(action.fileProviderId));
    }

    await dispatch(new WatchTaskAction(task, "Users Export"));
  }
}
