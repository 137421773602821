import { inject } from "fw";
import { dispatch } from 'fw-state';

import { createFilter, Filter, getFormFieldValueType, setDefaultFormFieldFilterParams } from "models/filter-setup";
import { getSectionMetas } from "models/testscore";
import { ApplicationSettingsStore } from "state/application-settings";
import { ContactStore, EnsureContactStoreAction } from "state/contacts";
import { CurrentContactOrganizationStore } from "state/current-contact-organization";
import { ContactsDataSourceInstanceStore, EnsureContactsDataSourceInstanceStoreAction } from "state/contacts-data-source-instance";
import { ContactSegmentStore } from "state/contact-segments";
import { PopoverController } from "service/popover";

import { ContactOrganization, ContactTypeDefinition, CustomFieldType, getFields, ICustomFieldDefinition, ICustomFieldDefinitionBase, NestedSearch, NestedSearchFieldDefinition } from "models/contact-organization";
import { CollaborationModulesStore } from "state/collaboration-modules";
import { FeatureFlagService } from "service/feature-flag";
import { DataDictionaryService } from "service/data-dictionary";
import { EventPermissionService } from "service/permissions/event";
import { CurrentUserStore } from "state/current-user";
import { Permissions } from "service/permission";
import { CurrentOrganizationStore } from "state/current-organization";
import { DataDictionaryField, DataDictionaryFieldDataSource, DataDictionaryIndexStatus } from "models/data-dictionary";
import { DataPolicyService } from "service/data-policy";
import { ApplicationSegmentStore } from "state/application-segments";
import { FormStore } from "state/forms";
import { FormFieldFilter, TableSectionGroupFilter } from "models/application-filters";
import { QuestionType } from "models/form";

interface FilterItem { name: string; type: string; data?: any; }
interface SeachableFilterItem { label: string; tokens: string[]; field?: DataDictionaryField; segmentId?: string, filterType: string; }

type FilterType = "applications" | "contacts" | "activity" | "tasks" | "events" | "nested" | "nested_search" | "table_section_row";

export interface AddFilterParams {
  canAddGroup: boolean,
  type: FilterType,
  data: { [key: string]: string },
  overrideContactType?: string,
  width?: string
}

function sortByName(a: FilterItem, b: FilterItem) {
  const nameA = a.name.toLowerCase();
  const nameB = b.name.toLowerCase();

  if (nameA < nameB) return -1;
  if (nameA > nameB) return 1;

  return 0;
}

@inject
export class AddFilter {
  private filters: FilterItem[] = [];
  private type: FilterType = "applications";
  private overrideContactType?: string = null;
  private selectedNestedFieldId: string = null;
  private selectedNestedSearchId: string = null;
  private selectedTableSectionPath: string = null;
  private selectedFormKey: string = null;
  private selectedFormSectionKey: string = null;
  private canAddGroup: boolean = false;
  private ceebFieldsSet?: Set<string> = new Set<string>();

  private width: string = "100%";
  private $refs: any;
  public searchTerm: string = "";
  private searchRef: any;

  loading: boolean = false;

  private searchableFields: SeachableFilterItem[] = [];
  private applicationPropertyRestrictions: string[] = [];
  private formRestrictions: string[] = [];

  constructor(
    private controller: PopoverController<Filter>,
    private appSettingsStore: ApplicationSettingsStore,
    private contactStore: ContactStore,
    private currentContactOrganizationStore: CurrentContactOrganizationStore,
    private contactsDataSourceInstanceStore: ContactsDataSourceInstanceStore,
    private contactSegmentStore: ContactSegmentStore,
    private dataDictionaryService: DataDictionaryService,
    private currentUserStore: CurrentUserStore,
    private formStore: FormStore,
    private permissions: Permissions,
    private dataPolicy: DataPolicyService,
    private ffs: FeatureFlagService,
    private collaborationModulesStore: CollaborationModulesStore,
    private currentOrganizationStore: CurrentOrganizationStore,
    private eventPermissionService: EventPermissionService,
    private segmentStore: ApplicationSegmentStore
  ) { }

  public async activate(params: AddFilterParams) {
    if (params.type == "contacts") {
      this.loading = true;

      await dispatch(new EnsureContactStoreAction());
      await dispatch(new EnsureContactsDataSourceInstanceStoreAction());

      this.loading = false;
    }

    this.type = params.type;
    this.selectedNestedFieldId = params?.data?.id;
    this.selectedNestedSearchId = params?.data?.nestedSearchId;
    this.selectedTableSectionPath = params?.data?.tableSectionPath;
    this.selectedFormKey = params?.data?.formKey;
    this.selectedFormSectionKey = params?.data?.sectionKey
    this.overrideContactType = params.overrideContactType;
    this.canAddGroup = params.canAddGroup;
    this.applicationPropertyRestrictions = this.dataPolicy.applicationPropertyPaths;
    this.formRestrictions = this.dataPolicy.formStepPaths.map(p => p.startsWith("forms") ? p : `forms.${p}`);
    await this.updateFilters();
  }

  public get ffApplicationSegmentingRootSearch() {
    return this.ffs.isFeatureFlagEnabled("ApplicationSegmentingRootSearch");
  }

  public clearSearch() {
    this.searchTerm = "";
    this.searchRef.focus();
  }

  public get filtered() {
    const list = this.filters;
    const search = this.searchTerm?.trim().toLowerCase();
    if (search?.length > 0) {
      // ??? not sure how else to keep the width the same while searching
      if (this.width === "100%") {
        this.width = `${this.$refs.filterList.clientWidth}px`;
      }
      return list.filter(f => f.name.toLowerCase().indexOf(search) !== -1);
    }
    return list;
  }

  public get filteredSearchableFields() {
    if (!this.searchTerm) {
      return [];
    }

    const term = this.searchTerm.toLocaleLowerCase();
    return this.searchableFields.filter(f => f.tokens.some(t => t.startsWith(term)));
  }

  public get useRootSearch() {
    return this.ffApplicationSegmentingRootSearch && this.type === 'applications' && this.searchTerm?.length > 0;
  }

  public getHighlightedLabel(text: string) {
    const tokens = text.split(" ");
    const searchTerm = this.searchTerm.toLowerCase();
    if (searchTerm.includes(" ")) {
      const index = text.toLocaleLowerCase().indexOf(searchTerm);
      if (index !== -1) {
        return `${
          text.substring(0, index)
        }<span class="highlight">${
          text.substring(index, index + searchTerm.length)
        }</span>${
          text.substring(index + searchTerm.length)
        }`;
      }
    }
    const highlighted = tokens.map(t => {
      if (t.toLowerCase().startsWith(searchTerm)) {
        return `<span class="highlight">${t}</span>`;
      }
      return t;
    });

    return highlighted.join(" ");
  }

  private async updateFilters() {
    this.filters = [];
    this.searchableFields = [];

    switch (this.type) {
      case "contacts":
        await this.addContactsFilter();
        break;
      case "activity":
        this.addActivityFilter();
        break;
      case "nested":
        this.addContactNestedFieldsFilters();
        break;
      case "nested_search":
        this.addContactNestedSearchFieldsFilters();
        break;
      case "tasks":
        this.addTaskFilters();
        break;
      case "events":
        this.addEventFilters();
        break;
      case "table_section_row":
        this.addTableSectionFilters();
        break;
      default:
        await this.addApplicationsFilter();
        break;
    }

    if (this.canAddGroup) {
      // group filter..  always at the bottom
      this.filters.push({ name: "Group", type: "group-filter" });
    }
  }

  private addContactNestedFieldsFilters() {
    if (!this.ffContactNestedSearch) {
      return;
    }

    const fields: ICustomFieldDefinition[] = getFields(this.organization);
    const nestedFields = fields.find(field => field.id === this.selectedNestedFieldId)?.nested_fields;

    if (!nestedFields || !nestedFields.length) {
      return;
    }

    this.filters = this.getFilters(nestedFields);
  }

  private addContactNestedSearchFieldsFilters() {
    if (!this.ffComplexDataAttachments) {
      return;
    }

    const fields: ICustomFieldDefinition[] = getFields(this.organization);
    const nestedSearches = fields.find(field => field.id === this.selectedNestedFieldId)?.nested_searches;

    if (!nestedSearches || !nestedSearches.length) {
      return;
    }

    const nestedSearchesFields = nestedSearches.find(nestedSearch => nestedSearch.id === this.selectedNestedSearchId)?.fields;
    this.filters = this.getFilters(nestedSearchesFields);
  }

  private async addTableSectionFilters() {
    if (!this.ffTableSectionRowLevelSearching) {
      return;
    }

    const fields = (await this.dataDictionaryService.getFields())
      .filter(x => x.Path !== this.selectedTableSectionPath 
        && x.Path?.startsWith(this.selectedTableSectionPath) 
        && !!x.SearchPath 
        && x.DataSource !== DataDictionaryFieldDataSource.SystemFieldType);

    this.filters =  fields.map(x => ({
      name: x.Label,
      type: "form-field-filter",
      data: {
        formKey: this.selectedFormKey,
        sectionKey: this.selectedFormSectionKey,
        field: x.SearchPath.replace(`forms.${this.selectedFormKey}.${this.selectedFormSectionKey}.`,'')
      }
    }));
  }

  private get organization(): ContactOrganization {
    return this.currentContactOrganizationStore.state.organization;
  }

  private async addApplicationsFilter(): Promise<void> {
    const filters: FilterItem[] = [];

    filters.push({ name: "Keyword", type: "keyword-filter" });
    filters.push({ name: "Application Form", type: "form-field-filter" });

    if (this.ffs.isFeatureFlagEnabled("MyApplicationReviewsFilter")
      && this.permissions.any("EvaluateApplications")
      && !this.currentUserStore.state.isGlobalPrincipal
    ) {
      filters.push({ name: "My Completed Reviews", type: "my-completed-evaluations-filter" });
      filters.push({ name: "My Incomplete Reviews", type: "my-incompleted-evaluations-filter" });
    }

    const { applicantSettings, applicationSettings } = this.appSettingsStore.state;

    if (applicationSettings.ApplicationProperties.length > 0) {
      filters.push({ name: "Application Property", type: "application-property-filter" });
    }

    if (applicantSettings.ApplicantProperties.length > 0) {
      filters.push({ name: "Applicant Property", type: "applicant-property-filter" });
    }

    filters.push(this.ffs.isFeatureFlagEnabled("ApplicationOrigin")
      ? { name: "Origin", type: "origin-filter" }
      : { name: "Application Source", type: "application-source-filter" }
    );

    if (this.ffs.isFeatureFlagEnabled("SearchableStepGroupPaymentStatus")) {
      filters.push({ name: "Submission Status", type: "submission-status-filter" });
      filters.push({ name: "Payment Status", type: "payment-status-filter" });
    }

    filters.push({ name: "Program", type: "program-filter" });
    filters.push({ name: "Program Property", type: "program-property-filter" });
    filters.push({ name: "Applicant Started", type: "date-started-filter" });
    filters.push({ name: "Application Stage", type: "program-stage-filter" });
    filters.push({ name: "Tags", type: "tag-filter" });

    if (await this.dataDictionaryService.hasReferenceStep()) {
      filters.push({ name: "Reference Counts", type: "reference-count-filter" });
    }

    filters.push({ name: "Segment", type: "segment-filter" });
    filters.push({ name: "Phase", type: "phase-filter-with-categories" });

    if (this.currentOrganizationStore.hasDecisionsFeatureEnabled) {
      filters.push({ name: "Decision", type: "decision-filter" });
      filters.push({ name: "Decision Letter", type: "decision-letter-filter" });
    }
    filters.push({ name: "Phase Calculated Field", type: "phase-calculated-field-filter" });
    filters.push({ name: "Review Assigned To", type: "assigned-user-filter" });
    filters.push({ name: "Reviewed By", type: "evaluated-by-filter" });
    filters.push({ name: "Review Complete", type: "evaluation-complete-filter" });

    const { modules } = this.collaborationModulesStore.state;
    if (modules.length > 0) {
      filters.push({ name: "Assigned to Collaboration Module", type: "collaboration-assigned-user-filter" });
      filters.push({ name: "Collaboration Reviewed By", type: "collaboration-evaluated-by-filter" });

      if (modules.some(m => m.CalculatedFields.length > 0)) {
        filters.push({ name: "Collaboration Calculated Field", type: "collaboration-calculated-field-filter" });
      }
    }

    filters.push({ name: "Last Updated", type: "last-updated-filter" });
    filters.push({ name: "Date Last Stage Changed", type: "date-stage-changed-filter" });
    filters.push({ name: "Date Last Phase Changed", type: "date-phase-changed-filter" });
    if (this.currentOrganizationStore.hasDecisionsFeatureEnabled) {
      filters.push({ name: "Date Last Decision Changed", type: "date-decision-changed-filter" });
    }
    filters.push({ name: "Comments", type: "comment-filter" });
    filters.push({ name: "Has Application", type: "application-filter" });

    this.filters = filters; /* filters.sort(sortByName); */

    if(this.ffApplicationSegmentingRootSearch) {
      const fields = await this.dataDictionaryService.getFields();
      this.ceebFieldsSet = new Set<string>(
        fields.filter(f =>
          f.DataSource === DataDictionaryFieldDataSource.QuestionTypeCode &&
          f.DataType === QuestionType.CEEBCode)
          .map(f => f.Path));

      this.searchableFields = fields
        .filter(field => (this.isAvailableFormField(field) && field.IndexStatus !== DataDictionaryIndexStatus.Unavailable)
          // TODO - include table sections
          || this.isAvailableApplicationProperty(field)
          || this.isAvailableProgramProperty(field)
        )
        .map(field => {
          let tokens = this.breakIntoTokens(field.Label);
          if(field.Category) {
            const parentName = field.Category.replace("Form: ", "").toLowerCase();
            tokens = [
                parentName,
              ...tokens,
              ...parentName.split(" ")
            ];
          }

          const filterType = this.getFilterByField(field);

          let label = field.Label;
          switch (filterType) {
            case "form-field-filter":
              label =  `${field.Category} > ${field.Label}`;
              break;
            case "table-section-group-filter":
              label =  `${field.Category} > ${field.Label}`;
              break;
            case "application-property-filter":
              label = `Application Property > ${field.Label}`;
              break;
            case "program-property-filter":
              label = `Program Property > ${field.Label}`;
              break;
          }

          return { label: label, tokens, field, filterType: filterType };
        });

        const segments = this.segmentStore.state.segments.map(s => 
          ({ label: `Segment > ${s.Label}`, tokens: this.breakIntoTokens(s.Label), segmentId: s.Id, filterType: "segment-filter" })
        );

        this.searchableFields = this.searchableFields
          .concat(filters.map(f => ({
            label: f.name,
            tokens: this.breakIntoTokens(f.name),
            field: null,
            filterType: f.type
          })))
          .concat(segments);
    }
  }

  private breakIntoTokens(text: string) {
    const tokens = text.toLowerCase().split(" ");
    let trailingSequences: string[] = [];
    if(tokens.length > 2) {
      trailingSequences = Array.from({ length: tokens.length - 2 }, (_, i) => tokens.slice(-i-2).join(" "));
    }
    
    return [ text.toLowerCase(), ...tokens, ...trailingSequences ];
  }

  private isAvailableFormField(field: DataDictionaryField) {
    return field.Path?.startsWith("forms.")
      && !this.isNotSearchableFormSubField(field)
      && !this.formRestrictions.includes(`forms.${field.FormKey}.*`)
      && !this.formRestrictions.includes(field.Path);
  }

  private isNotSearchableFormSubField(field: DataDictionaryField) {
    return field.DataSource === DataDictionaryFieldDataSource.SystemFieldType && field.ParentPath && (
      field.Path.endsWith(".address1") ||
      field.Path.endsWith(".address2") ||
      (field.Path.endsWith(".postalCode") && !this.ceebFieldsSet.has(field.ParentPath)) ||
      field.Path.endsWith(".geo") ||
      field.Path.endsWith(".location") ||
      field.Path.endsWith(".address") ||
      field.Path.endsWith(".title") ||
      field.Path.endsWith(".middle") ||
      field.Path.endsWith(".suffix") ||
      field.Path.endsWith(".fullName")
    )
  }

  private isIndexedRowsTableSectionField(field: DataDictionaryField) {
    return field.Path?.includes(".rows[]") || (!field.ParentPath && field.Path.endsWith(".rows"));
  }

  private isAvailableApplicationProperty(field: DataDictionaryField) {
    return field.Category === "Application"
      && field.DataSource === DataDictionaryFieldDataSource.CalculatedFieldDataTypeCode
      && !this.applicationPropertyRestrictions.includes(field.Path);
  }

  private isAvailableProgramProperty(field: DataDictionaryField) {
    return field.Category === "Program"
      && field.DataSource === DataDictionaryFieldDataSource.CalculatedFieldDataTypeCode
      && !this.applicationPropertyRestrictions.includes(field.Path);
  }

  private getFilterByField(field: DataDictionaryField) {
    if(this.ffTableSectionRowLevelSearching && this.isIndexedRowsTableSectionField(field)) {
      return "table-section-group-filter";
    }

    if (this.isAvailableFormField(field)) {
      return "form-field-filter";
    }

    if (this.isAvailableApplicationProperty(field)) {
      return "application-property-filter";
    }

    if (this.isAvailableProgramProperty(field)) {
      return "program-property-filter";
    }

    return null;
  }

  private addActivityFilter() {
    const filters: FilterItem[] = [
      { name: "Data Source", type: "activity-source-filter" },
      { name: "Activity Title", type: "activity-title-filter" },
      { name: "Activity Description", type: "activity-description-filter" },
      { name: "Business Value", type: "activity-business-value-filter" }
    ];
    this.filters = filters.sort(sortByName);
  }

  private async addContactsFilter() {
    const contactType = this.contactType;
    const filters: FilterItem[] = [];
    if (this.canAddGroup) {
      filters.push({ name: "Activity Filters", type: "contacts-activity-group-filter" });

      if (this.ffContactNestedSearch) {
        const fields: ICustomFieldDefinition[] = getFields(this.organization, contactType.key);
        fields.forEach(field => {
          if (field.nested_fields && field.nested_fields.length) {
            filters.push({ name: `${field.display_name} Filters`, type: "nested-group-filter", data: { field: field } });
          }
        });
      }

      if (this.ffComplexDataAttachments) {
        const fields: ICustomFieldDefinition[] = getFields(this.organization, contactType.key);
        fields.forEach(field => {
          if (field.nested_searches && field.nested_searches.length) {
            field.nested_searches.forEach(nestedSearch => {
              filters.push({ name: `${nestedSearch.display_name} Filters`, type: "nested-search-group-filter", data: { field: field, nestedSearch: nestedSearch } });
            })
          }
        });
      }
    }

    if (this.canViewEventFilters) {
      filters.push({ name: "Event Filters", type: "contacts-event-filter" });
    }

    if (this.organization.enable_contact_number_sequencing) {
      filters.push({ name: "Contact Number", type: "contacts-contact-number-filter" });
    }

    filters.push({ name: "Keyword", type: "contacts-keyword-filter" });
    filters.push({ name: "Email", type: "contacts-email-filter" });
    filters.push({ name: "Engagement Score", type: "contacts-operator-filter", data: { field: { display_name: "Engagement Score", search_field: "score" } } });

    let instanceFilter: FilterItem = {
      name: "Integration",
      type: "contacts-data-source-instance-filter",
      data: { integrations: [], selectedIds: [], uploadIds: [], mode: "any" }
    };
    let fields = this.organization.fields.filter(f => !f.is_system_field);

    if (contactType) {
      if (contactType.key === "applicant" && this.canAddGroup) {
        //  add this to the top of the filters list
        filters.unshift({ name: "Application Filters", type: "contacts-application-group-filter" });
      }

      if (this.contactSegmentStore.state.segments.findIndex(s => s.contact_type === contactType.key) !== -1) {
        filters.push({
          name: "Segment",
          type: "contacts-segment-filter",
          data: { contactType: contactType.key }
        });
      }

      instanceFilter.data.contactType = contactType.key;
      filters.push({ name: "Name", type: "contacts-name-filter", data: { useFullName: contactType.use_full_name } });

      const tagsField = this.organization.fields.find(f => f.contact_type == contactType.key && f.name === "Tags");
      if (tagsField) {
        const data = { field: tagsField, mode: "any", options: tagsField.options.map(o => o.name) };
        filters.push({ name: tagsField.display_name, type: "contacts-tags-filter", data: data });
      }

      fields = fields.filter(f => f.contact_type == contactType.key);
    }

    const instances = await this.contactsDataSourceInstanceStore.getInstances();
    if (instances && instances.length > 0) {
      instances.forEach(i => {
        if (contactType && contactType.key != i.contact_type) {
          return;
        }

        if (i.data_source_key === "upload") {
          instanceFilter.data.uploadIds.push(i.id);
          return;
        }

        instanceFilter.data.integrations.push({ name: i.name, id: i.id })
      });
    }
    filters.push(instanceFilter);

    this.filters = [...filters, ...this.getFilters(fields)];
  }

  private get contactType(): ContactTypeDefinition {
    return !this.overrideContactType ? this.contactStore.state.selectedContactType : this.organization.contact_types.find(ct => ct.key == this.overrideContactType);
  }

  private getFilters(fields: ICustomFieldDefinition[] | ICustomFieldDefinitionBase[]): FilterItem[] {
    const filters = [];

    fields.forEach(t => {
      let data: any = { field: t };
      switch (t.type) {
        case CustomFieldType.string:
        case CustomFieldType.largestring:
          filters.push({ name: t.display_name, type: "contacts-string-filter", data: data });
          break;
        case CustomFieldType.phone:
        case CustomFieldType.social:
          filters.push({ name: t.display_name, type: "contacts-key-value-filter", data: data });
          break;
        case CustomFieldType.address:
          filters.push({ name: t.display_name, type: "contacts-address-filter", data: data });
          break;
        case CustomFieldType.date:
          filters.push({ name: t.display_name, type: "contacts-date-filter", data: data });
          break;
        case CustomFieldType.number:
          filters.push({ name: t.display_name, type: "contacts-operator-filter", data: data });
          break;
        case CustomFieldType.dropdown:
        case CustomFieldType.funnel:
          data.options = t.options.map(o => { return { text: o.name, value: o.name }; });
          filters.push({ name: t.display_name, type: "contacts-option-filter", data: data });
          break;
        case CustomFieldType.boolean:
          data.options = [{ text: "Yes", value: true }, { text: "No", value: false }];
          filters.push({ name: t.display_name, type: "contacts-option-filter", data: data });
          break;
        case CustomFieldType.link:
          filters.push({ name: t.display_name, type: "contacts-link-filter", data: data });
          break;
        case CustomFieldType.multiselect:
          data.mode = "any";
          data.options = t.options.map(o => o.name);
          filters.push({ name: t.display_name, type: "contacts-multiselect-filter", data: data });
          break;
        case CustomFieldType.tags:
          data.mode = "any";
          data.options = t.options.map(o => o.name);
          filters.push({ name: t.display_name, type: "contacts-tags-filter", data: data });
          break;
        case CustomFieldType.relationship:
          filters.push({ name: t.display_name, type: "contacts-relationship-filter", data: data });
          break;
        case CustomFieldType.slideroomadmissionsapplications:
          filters.push({ name: t.display_name, type: "contacts-slideroom-admissions-applications-filter", data: data });
          break;
        case CustomFieldType.slideroomapplications:
          filters.push({ name: t.display_name, type: "contacts-slideroom-applications-filter", data: data });
          break;
        case CustomFieldType.testscore:
          data.sections = getSectionMetas(t);
          filters.push({ name: t.display_name, type: "contacts-test-score-filter", data: data });
          break;
        case CustomFieldType.user:
          data.mode = "any";
          filters.push({ name: t.display_name, type: "contacts-user-filter", data: data });
          break;
        case CustomFieldType.postalcode:
          filters.push({ name: t.display_name, type: "contacts-postal-code-filter", data: data });
          break;
        case CustomFieldType.country:
          filters.push({ name: t.display_name, type: "contacts-country-filter", data: data });
          break;
      }
    });

    return filters;
  }

  private addTaskFilters() {
    const filters: FilterItem[] = [];
    filters.push({ name: "Description", type: "task-description-filter" });
    filters.push({ name: "Date Completed", type: "task-date-completed-filter" });
    filters.push({ name: "Date Last Assigned", type: "task-date-last-assigned-filter" });
    filters.push({ name: "Tags", type: "task-tag-filter" });
    filters.push({ name: "Assignee", type: "task-assigned-user-filter" });
    filters.push({ name: "Target Type", type: "task-target-type-filter" });
    filters.push({ name: "Due Date", type: "task-date-range-filter" });
    this.filters = filters.sort(sortByName);
  }

  private addEventFilters() {
    const filters: FilterItem[] = [];
    filters.push({ name: "Location", type: "event-location-filter" });
    filters.push({ name: "Description", type: "event-description-filter" });
    filters.push({ name: "Event Type", type: "event-type-filter" });
    filters.push({ name: "Tags", type: "event-tags-filter" });
    filters.push({ name: "Department", type: "event-department-filter" });
    filters.push({ name: "Date", type: "event-date-filter" });
    this.filters = filters.sort(sortByName);
  }

  public addFilter(f: FilterItem) {
    this.controller.ok(createFilter(f.type, filter => {
      if (f.data) {
        Object.assign(filter, f.data);
      }
    }));
  }

  public addSearchedFilter(f: SeachableFilterItem) {
    let data = null;
    if(f.field && f.field.DataSource === DataDictionaryFieldDataSource.SystemFieldType && f.field.ParentPath) {
      // substitute the field with the parent field in the case of system fields reserved for special form questions (address,name,ceeb, etc)
      const parentField = this.searchableFields.find(sf => sf.field.Path === f.field.ParentPath)?.field;
      if(parentField.DataSource === DataDictionaryFieldDataSource.QuestionTypeCode) {
        f.field = parentField;
      }
    }

    let fieldKey = null;
    let sectionKey = null;
    if (f.field?.FormKey) {
      const path = f.field.SearchPath ?? f.field.Path;
      const segments = path.split(".");
      fieldKey = segments[2];
      switch (segments.length) {
        case 6:
          // table section field with named rows
          fieldKey = `${segments[3]}.${segments[4]}.${segments[5]}`;
          sectionKey = segments[2];
          break;
        case 5:
          // table section field with unnamed rows
          fieldKey = `${segments[3]}.${segments[4]}`;
          sectionKey = segments[2];
          break;
        case 4:
          if(segments[3] === "rows") {
            sectionKey = segments[2];
          }
          fieldKey = segments[3];
          break;
      }
    }

    let filter: Filter = null;
    switch (f.filterType) {
      case "table-section-group-filter": {
        const tableSectionPathIndex = f.field.Path.indexOf(".rows[]");
        const tableSectionPath = tableSectionPathIndex !== -1 ? f.field.Path.slice(0, tableSectionPathIndex + 7) : f.field.Path;
        data = { formKey: f.field?.FormKey, field: fieldKey, tableSectionPath: tableSectionPath, sectionKey };
        filter = createFilter(f.filterType, filter => { Object.assign(filter, data); });

        const groupFilter = filter.data as unknown as TableSectionGroupFilter;
        if (!f.field.Path.endsWith(".rows")) {
          groupFilter.filters = [];
          groupFilter.filters.push(createFilter("form-field-filter", filter => {
            this.setDefaultFormFieldFilterParams(f.field, data);
            Object.assign(filter, data);
          }))
        }
        break;
      }
      case "form-field-filter": {
        data = { formKey: f.field?.FormKey, field: fieldKey, sectionKey};
        this.setDefaultFormFieldFilterParams(f.field, data);
        filter = createFilter(f.filterType, filter => { Object.assign(filter, data); });
        break;
      }
      case "application-property-filter":
        data = { field: f.field.Path };
        filter = createFilter(f.filterType, filter => { Object.assign(filter, data); });
        break;
      case "program-property-filter":
        data = { field: f.field.Path.replace("program.properties.", "") };
        filter = createFilter(f.filterType, filter => { Object.assign(filter, data); });
        break;
      case "segment-filter": {
        data = { segmentId: f.segmentId };
        filter = createFilter(f.filterType, filter => { Object.assign(filter, data); });
        break;
      }
      default :
        filter = createFilter(f.filterType);
        break;
    }

    this.controller.ok(filter);
  }

  private setDefaultFormFieldFilterParams(field: DataDictionaryField, filter: FormFieldFilter) {
    const valueType = getFormFieldValueType(field);
    setDefaultFormFieldFilterParams( filter, valueType, this.ffs.isFeatureFlagEnabled("DropdownFilterAsKeyword8366"));
  }

  private get ffContactNestedSearch(): boolean {
    return this.ffs.isFeatureFlagEnabled("ContactNestedSearch");
  }

  public get ffComplexDataAttachments(): boolean {
    return this.ffs.isFeatureFlagEnabled("ComplexDataAttachments");
  }

  private get ffTableSectionRowLevelSearching(): boolean {
    return this.ffs.isFeatureFlagEnabled("TableSectionRowLevelSearching");
  }

  private get canViewEventFilters(): boolean {
    return this.ffs.isFeatureFlagEnabled("ContactsEventFiltersOUT4562Jul2024") &&
      this.ffs.isFeatureFlagEnabled("CalendarModule") &&
      this.eventPermissionService.canView;
  }
}
