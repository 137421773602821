import { inject } from "fw";
import { dispatch } from "fw-state";
import { FileGetter, FileUploader, PendingFile } from "uploader";

import { FileRepository } from "network/file-repository";
import { FilePostResult, IFileGetOptions } from "models/file";
import { CurrentOrganizationStore } from "state/current-organization";
import { AddFileAction } from "state/current-application";
import { Notification } from "./notification";

export type PendingFileAndFile = {
  pendingFile: PendingFile;
  file: FilePostResult;
};

const FILE_CONTENT_TYPE_MAPPING: Record<string, string> = {
  avi: "video/x-msvideo",
  divx: "video/divx",
  dvx: "video/divx",
  fly: "video/flv",
  mkv: "video/x-matroska",
  mov: "video/quicktime",
  mp4: "video/mp4",
  unknown: "application/octet-stream",
  wmv: "video/x-ms-wmv",
};

@inject
export class SharedFileService {
  public queue: PendingFile[] = [];

  constructor(
    private currentOrgStore: CurrentOrganizationStore,
    private fileRepo: FileRepository,
    private fileGetter: FileGetter,
    private fileUploader: FileUploader,
    private notification: Notification
  ) {}

  public isValidFileSize(pendingFile: PendingFile, maxFileSize: number, maxFileSizePretty?: string) {
    if (pendingFile.fileSize > maxFileSize) {
      const limit = maxFileSizePretty || '8 MB';
      this.notification.error(
        `This attachment is larger than the maximum file size of ${limit}s. We have removed this file from the email.`,
        "File size is too big"
      );
      return false;
    }

    return true;
  }

  public isValidFileExtension(pendingFile: PendingFile, allowedExtensions: string[]) {
    if (!allowedExtensions.includes(fileExtension(pendingFile.fileName))) {
      this.notification.error(
        `Only the following file types are allowed: ${allowedExtensions.join(", ")}.`,
        "Invalid file type"
      );
      return false;
    }

    return true;
  }

  private async queueUp(pendingFile: PendingFileAndFile) {
    this.queue.push(pendingFile.pendingFile);

    this.fileUploader.queue(pendingFile.pendingFile);

    await pendingFile.pendingFile.waitUntilUploaded();

    const idx = this.queue.indexOf(pendingFile.pendingFile);
    if (idx >= 0) this.queue.splice(idx, 1);
  }

  public async getFile(
    contextType: string = undefined,
    contextId: string = undefined,
    options: IFileGetOptions = {
      multiple: false,
    }
  ): Promise<PendingFileAndFile> {
    const files = await this.fileGetter.getFiles(options.multiple);

    if (files.length == 0) return null;

    if (options.maxFileSize && !this.isValidFileSize(files[0], options.maxFileSize, options.maxFileSizePretty)) return null;

    if (options.allowedExtensions && !this.isValidFileExtension(files[0], options.allowedExtensions))
      return null;
    const pendingFile = files[0];

    return await this.getContext(pendingFile, contextType, contextId);
  }

  public async getFiles(): Promise<PendingFileAndFile[]> {
    const files = await this.fileGetter.getFiles();

    if (files.length == 0) return null;

    const pendingFile = files[0];
    const promises: Promise<PendingFileAndFile>[] = files.map((f) => this.getContext(f));

    return await Promise.all<PendingFileAndFile>(promises);
  }

  public async enqueue(file: PendingFileAndFile) {
    await this.queueUp(file);
  }

  private getContentTypeByExtension(fileName: string): string {
    const extension = fileName.split(".").pop();
    if (!extension) return FILE_CONTENT_TYPE_MAPPING.unknown;
    return FILE_CONTENT_TYPE_MAPPING[extension];
  }

  public async getContext(
    pendingFile: PendingFile,
    contextType = "Organization",
    contextId = this.currentOrgStore.state.organization.Id
  ): Promise<PendingFileAndFile> {
    pendingFile.contentType = pendingFile.contentType || this.getContentTypeByExtension(pendingFile.fileName);
    const file = await this.fileRepo.post({
      ContextId: contextId,
      ContextType: contextType,
      Filename: pendingFile.fileName,
      Size: pendingFile.fileSize,
      ContentType: pendingFile.contentType,
      OrganizationId: this.currentOrgStore.state.organization.Id,
    });

    pendingFile.uploadUrl = file.PutUrl;

    //dispatch(new AddFile(file.FileId));

    return { pendingFile, file };
  }
}

@inject
export class ApplicationFileService {
  constructor(private sharedService: SharedFileService) {}

  public async enqueue(file: PendingFileAndFile) {
    await this.sharedService.enqueue(file);
    await dispatch(new AddFileAction(file.file.FileId));
  }

  public async getFile(
    contextType: string = undefined,
    contextId: string = undefined
  ): Promise<PendingFileAndFile> {
    return await this.sharedService.getFile(contextType, contextId);
  }
}

@inject
export class FileService {
  constructor(private sharedService: SharedFileService) {}

  public async enqueue(file: PendingFileAndFile) {
    await this.sharedService.enqueue(file);
  }

  public async getFile(
    contextType: string = undefined,
    contextId: string = undefined,
    options?: IFileGetOptions
  ): Promise<PendingFileAndFile> {
    return await this.sharedService.getFile(contextType, contextId, options);
  }
}

export const fileExtension = (fileName: string) => {
  const regEx = /\.([^.]+)$/gi;

  const results = regEx.exec(fileName);
  if (results.length == 2) {
    return results[1];
  }

  return "";
};
