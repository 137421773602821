import { inject } from "fw";
import { nameof } from "helpers/nameof";
import { uniqBy, orderBy, get } from "lodash-es";
import { CalculatedFieldDataTypeCode } from "models/calculated-field";
import { CustomFieldType } from "models/contact-organization";
import { DataDictionaryAggregationType, DataDictionaryField, DataDictionaryFieldCategory, DataDictionaryFieldDataSource, DataDictionaryFieldType as DataDictionaryFieldJsonDataType, DataDictionaryIndexStatus, SystemFieldType } from "models/data-dictionary";
import { Form, FormSection, QuestionType } from "models/form";
import { FeatureFlagService } from "./feature-flag";
import { DataDictionaryStore, EnsureDataDictionaryFieldsAction } from "state/data-dictionary";
import { dispatch } from "fw-state";
import { ValueType } from "views/components/application-filters/value-field";
@inject
export class DataDictionaryService {
    constructor(
        private dataDictionaryStore: DataDictionaryStore,
        private ffs: FeatureFlagService
    ) { }

    public includeCategory(fields: DataDictionaryField[], category: DataDictionaryFieldCategory | string) {
        return fields.filter(f => f.Category === category);
    }

    public includeForms(fields: DataDictionaryField[]) {
        return fields.filter(f => !!f.FormId);
    }

    public includeFormId(fields: DataDictionaryField[], formId: string) {
        return fields.filter(f => f.FormId === formId);
    }

    public includePrefix(fields: DataDictionaryField[], prefix: string): DataDictionaryField[] {
        return fields.filter(f => f.Path?.startsWith(prefix));
    }

    public excludeComplexPaths(fields: DataDictionaryField[]): DataDictionaryField[] {
        return fields.filter(field => !this.isComplexPath(field));
    }

    public includePaths(fields: DataDictionaryField[], paths: string[]): DataDictionaryField[] {
        return fields.filter(f => paths.includes(f.Path) || paths.find(p => f.Path?.startsWith(`${p}.`)));
    }

    public includeJsonDataTypes(fields: DataDictionaryField[], types: DataDictionaryFieldJsonDataType[]) {
        return fields.filter(f => types.includes(f.JsonDataType));
    }

    public includeTerm(fields: DataDictionaryField[], term: string) {
        if (!term) {
            return fields;
        }

        term = term.toLowerCase();
        return fields.filter(field => {
            return field.Path?.toLowerCase().includes(term)
                || field.Label?.toLowerCase().includes(term)
                || field.Validation?.join(",").toLowerCase().includes(term)
                || field.Values?.join(",").toLowerCase().includes(term);
        });
    }

    public includeEncrypted(fields: DataDictionaryField[]): DataDictionaryField[] {
        return fields.filter(field => this.isEncryptedField(field));
    }

    public excludeEncrypted(fields: DataDictionaryField[]): DataDictionaryField[] {
        return fields.filter(field => !this.isEncryptedField(field));
    }

    public includeCalculated(fields: DataDictionaryField[]): DataDictionaryField[] {
        return fields.filter(field => field.DataSource === DataDictionaryFieldDataSource.CalculatedFieldDataTypeCode);
    }

    public excludeCalculated(fields: DataDictionaryField[]): DataDictionaryField[] {
        return fields.filter(field => field.DataSource !== DataDictionaryFieldDataSource.CalculatedFieldDataTypeCode);
    }

    public includeContacts(fields: DataDictionaryField[]): DataDictionaryField[] {
        return fields.filter(field => this.isContactField(field));
    }

    public excludeContacts(fields: DataDictionaryField[]): DataDictionaryField[] {
        return fields.filter(field => !this.isContactField(field));
    }

    public excludeFileFields(fields: DataDictionaryField[]): DataDictionaryField[] {
        // This is here to provide backwards compatibility where we were excluding applicant attachments and doc / reference step attachments.
        // The use case was to allow to export file ids.
        return fields.filter(field => !this.isFileField(field) || field.JsonDataType === "string");
    }

    public includeGeo(fields: DataDictionaryField[]): DataDictionaryField[] {
        return fields.filter(field => this.isGeoField(field));
    }

    public excludeGeo(fields: DataDictionaryField[]): DataDictionaryField[] {
        return fields.filter(field => !this.isGeoField(field));
    }

    public includeReferenceForms(fields: DataDictionaryField[]): DataDictionaryField[] {
        return fields.filter(field => this.isReferenceFormField(field));
    }

    public excludeReferenceForms(fields: DataDictionaryField[]): DataDictionaryField[] {
        return fields.filter(field => !this.isReferenceFormField(field));
    }

    public excludeTableSectionFields(fields: DataDictionaryField[]): DataDictionaryField[] {
        return fields.filter(field => !field.IsTableSection);
    }

    public excludeTableSectionSubFields(fields: DataDictionaryField[]): DataDictionaryField[] {
        const paths: string[] = fields.filter(f => f.IsTableSection).map(f => f.Path);
        return fields.filter(f => !paths.find(p => f.Path?.startsWith(`${p}.`)));
    }

    public excludeTableQuestions(fields: DataDictionaryField[]): DataDictionaryField[] {
        return fields.filter(field => !this.isTableQuestionField(field));
    }

    public excludeIndexStatus(fields: DataDictionaryField[], status: DataDictionaryIndexStatus): DataDictionaryField[] {
        return fields.filter(field => field.IndexStatus !== status);
    }

    public excludeAggregationType(fields: DataDictionaryField[], aggregationType: DataDictionaryAggregationType): DataDictionaryField[] {
        return fields.filter(field => field.AggregationType !== aggregationType);
    }

    public excludeFormObjectFields(fields: DataDictionaryField[]): DataDictionaryField[] {
        return fields.filter(field => !this.isFormObjectField(field));
    }

    public includeSteps(fields: DataDictionaryField[], category: DataDictionaryFieldCategory): DataDictionaryField[] {
        switch (category) {
            case DataDictionaryFieldCategory.DocumentSteps:
            case DataDictionaryFieldCategory.PortfolioSteps:
            case DataDictionaryFieldCategory.ReferenceSteps:
                return fields.filter(f => f.Category === category && f.JsonDataType === "object" && f.Path?.split(".").length === 2);
            default:
                return [];
        }
    }

    public isContactField(field: DataDictionaryField): boolean {
        return field.DataSource === DataDictionaryFieldDataSource.ContactsFieldType;
    }

    public isBooleanField(field: DataDictionaryField): boolean {
        switch (field.DataSource) {
            case DataDictionaryFieldDataSource.SystemFieldType:
                return field.DataType === SystemFieldType.Boolean;
            case DataDictionaryFieldDataSource.ContactsFieldType:
                return field.DataType === CustomFieldType.boolean;
            case DataDictionaryFieldDataSource.CalculatedFieldDataTypeCode:
                return field.DataType === CalculatedFieldDataTypeCode.Boolean;
            case DataDictionaryFieldDataSource.QuestionTypeCode:
                return false;
        }
    }

    public isDateField(field: DataDictionaryField): boolean {
        switch (field.DataSource) {
            case DataDictionaryFieldDataSource.SystemFieldType:
                return field.DataType === SystemFieldType.Date;
            case DataDictionaryFieldDataSource.ContactsFieldType:
                return field.DataType === CustomFieldType.date;
            case DataDictionaryFieldDataSource.CalculatedFieldDataTypeCode:
                return field.DataType === CalculatedFieldDataTypeCode.Date;
            case DataDictionaryFieldDataSource.QuestionTypeCode:
                return field.DataType === QuestionType.Date;
        }
    }

    public isNumberField(field: DataDictionaryField): boolean {
        switch (field.DataSource) {
            case DataDictionaryFieldDataSource.SystemFieldType:
                return field.DataType === SystemFieldType.Number;
            case DataDictionaryFieldDataSource.ContactsFieldType:
                return field.DataType === CustomFieldType.number;
            case DataDictionaryFieldDataSource.CalculatedFieldDataTypeCode:
                return field.DataType === CalculatedFieldDataTypeCode.Number;
            case DataDictionaryFieldDataSource.QuestionTypeCode:
                return field.DataType === QuestionType.Number;
        }
    }

    public isComplexPath(field: DataDictionaryField): boolean {
        const path = field.ExportPath ?? field.Path;
        return path && path.includes("[]");
    }

    public isEncryptedField(field: DataDictionaryField): boolean {
        // TODO: do we need to check for parent path?
        switch (field.DataSource) {
            case DataDictionaryFieldDataSource.ContactsFieldType:
                return field.DataType === CustomFieldType.concealed;
            case DataDictionaryFieldDataSource.CalculatedFieldDataTypeCode:
                return field.DataType === CalculatedFieldDataTypeCode.Encrypted;
            case DataDictionaryFieldDataSource.QuestionTypeCode:
                return field.DataType === QuestionType.Encrypted;
            default:
                return false;
        }
    }

    public isFileField(field: DataDictionaryField): boolean {
        switch (field.DataSource) {
            case DataDictionaryFieldDataSource.SystemFieldType:
                return field.DataType === SystemFieldType.File;
            case DataDictionaryFieldDataSource.CalculatedFieldDataTypeCode:
                return field.DataType === CalculatedFieldDataTypeCode.File;
            case DataDictionaryFieldDataSource.QuestionTypeCode:
                return field.DataType === QuestionType.File;
            default:
                return false;
        }
    }

    public isGeoField(field: DataDictionaryField): boolean {
        return field.DataSource === DataDictionaryFieldDataSource.SystemFieldType && field.DataType == SystemFieldType.Geo;
    }

    public isReferenceField(field: DataDictionaryField): boolean {
        return field.Category === DataDictionaryFieldCategory.ReferenceSteps;
    }

    public isReferenceFormField(field: DataDictionaryField): boolean {
        return this.isReferenceField(field) && (!!field.FormKey || field.Path.endsWith(".forms"));
    }

    public isRoutingField(field: DataDictionaryField): boolean {
        return field.DataSource === DataDictionaryFieldDataSource.QuestionTypeCode && field.DataType === QuestionType.Name;
    }

    public isTableQuestionField(field: DataDictionaryField): boolean {
        return field.DataSource === DataDictionaryFieldDataSource.QuestionTypeCode && field.DataType === QuestionType.Table;
    }

    public isFormObjectField(field: DataDictionaryField): boolean {
        const regex = /(^|\.)(forms|form)\.[^\.]+$/;
        return field.JsonDataType === "object" && field.Path !== null && regex.test(field.Path)
    }

    public async getByCategory(category: DataDictionaryFieldCategory): Promise<DataDictionaryField[]> {
        const fields = await this.getFields();
        return this.includeCategory(fields, category);
    }

    public async getByPrefix(prefix: string): Promise<DataDictionaryField[]> {
        const fields = await this.getFields();
        return this.includePrefix(fields, prefix);
    }

    public async getByFormId(formId: string, filterUnique: boolean = false): Promise<DataDictionaryField[]> {
        const fields = this.includeFormId(await this.getFields(), formId);
        if (filterUnique) {
            // NOTE: There could be a case not handled here where FormPath is not set.
            return uniqBy(fields, nameof<DataDictionaryField>("FormPath"));
        }

        return fields;
    }

    /**
    * Returns a table sections form questions. These are returned uniquely by form path as a result named rows won't always be returned.
    */
    public async getByFormTableSection(form: Form, section: FormSection): Promise<DataDictionaryField[]> {
        if (!section.IsTableSection) {
            return [];
        }

        const fields = await this.getByFormId(form.Id, true);
        let rowsSuffix = ".rows";
        if (section.TableSectionOptions.NamedRows.length === 0) {
            rowsSuffix += "[]";
        }

        return this.includePaths(fields, [`forms.${form.Key}.${section.TableSectionOptions.Key}${rowsSuffix}`]).filter(f => !!f.FormPath);
    }

    public async getAggregateFields(includeContactsFields: boolean): Promise<DataDictionaryField[]> {
        let fields = await this.getFields();
        fields = this.excludeIndexStatus(fields, DataDictionaryIndexStatus.Unavailable);

        if (!includeContactsFields) {
            fields = this.excludeContacts(fields);
        }

        return this.excludeAggregationType(fields, DataDictionaryAggregationType.None);
    }

    public async getApplicationPropertyFields(): Promise<DataDictionaryField[]> {
        const fields = await this.getByCategory(DataDictionaryFieldCategory.Application);
        return this.excludeComplexPaths(this.includeCalculated(fields));
    }
    public async getFieldsByCategory(
        includeEncrypted: boolean = true,
        includeContactsFields: boolean = false,
        includeFileFields: boolean = false,
        includeReferenceForms: boolean = false,
        includeComplexPaths: boolean = false,
        includeFormObjectFields: boolean = true,
        includeTableSectionFields: boolean = true,
    ): Promise<DataDictionaryField[]> {
        let fields = await this.getFields();
        fields = this.excludeTableSectionSubFields(fields);
        fields = this.excludeTableQuestions(fields);

        if (!includeEncrypted) {
            fields = this.excludeEncrypted(fields);
        }

        if (!includeContactsFields) {
            fields = this.excludeContacts(fields);
        }

        if (!includeFileFields) {
            fields = this.excludeFileFields(fields);
        }

        if (!includeReferenceForms) {
            fields = this.excludeReferenceForms(fields);
        }

        if (!includeComplexPaths) {
            fields = this.excludeComplexPaths(fields);
        }

        if (!includeFormObjectFields) {
            fields = this.excludeFormObjectFields(fields);
        }

        if (!includeTableSectionFields) {
            fields = this.excludeTableSectionFields(fields);
        }

        return fields;
    }

    public async getGeoFieldsByCategory(): Promise<DataDictionaryField[]> {
        const fields = await this.getFields();
        return this.excludeComplexPaths(this.includeGeo(this.excludeCalculated(this.includeForms(fields))));
    }

    public async getRoutingFieldsByCategory(): Promise<DataDictionaryField[]> {
        const fields = await this.getFields();
        return this.excludeComplexPaths(fields.filter(f => f.IsRoutable));
    }

    public async getReferenceSteps(): Promise<DataDictionaryField[]> {
        return await this.getSteps(DataDictionaryFieldCategory.ReferenceSteps);
    }

    public resolveStep(fields: DataDictionaryField[], category: DataDictionaryFieldCategory, stepKey: string): DataDictionaryField {
        switch (category) {
            case DataDictionaryFieldCategory.DocumentSteps:
            case DataDictionaryFieldCategory.PortfolioSteps:
            case DataDictionaryFieldCategory.ReferenceSteps:
                const key = `.${stepKey}`;
                return this.includeSteps(fields, category).find(f => f.Path?.endsWith(key));
            default:
                return undefined;
        }
    }

    public async getSteps(category: DataDictionaryFieldCategory): Promise<DataDictionaryField[]> {
        switch (category) {
            case DataDictionaryFieldCategory.DocumentSteps:
            case DataDictionaryFieldCategory.PortfolioSteps:
            case DataDictionaryFieldCategory.ReferenceSteps:
                const fields = await this.getFields();
                return this.includeSteps(fields, category);
            default:
                return [];
        }
    }

    public async hasReferenceStep(): Promise<boolean> {
        const steps = await this.getReferenceSteps();
        return steps.length > 0;
    }

    public getFieldPathKey(field: DataDictionaryField): string {
        return field.Path?.split(".").pop();
    }

    public async getFields(): Promise<DataDictionaryField[]> {
        await dispatch(new EnsureDataDictionaryFieldsAction(false));
        return orderBy(this.dataDictionaryStore.state.fields, f => f.Category?.toLowerCase());
    }

    public getFieldByPath(path: string): DataDictionaryField {
        const { fields, fieldPathHash } = this.dataDictionaryStore.state;
        if (fieldPathHash[path] === undefined || fields[fieldPathHash[path]] === undefined) {
            return new DataDictionaryField();
        }
        return fields[fieldPathHash[path]];
    }

    public async buildCompleterSchema(tw, completerList) {
        let searchPath = tw.token && tw.token.replaceAll('?', '');
        let completions = {};

        if (searchPath.charAt(searchPath.length - 1) === '.') searchPath = searchPath.substring(0, searchPath.length - 1);

        if (tw.word === '') {
            completions = get(completerList, searchPath.split('.'));
        } else {
            const lastDelimiterIndex = searchPath.lastIndexOf('.');

            completions = get(completerList, searchPath.substring(0, lastDelimiterIndex)) ?? completerList;

            completions = Object
                .keys(completions)
                .filter(key => key.startsWith(tw.word))
                .reduce((obj, key) => {
                    obj[key] = completions[key];
                    return obj;
                }, {});
        }

        return Object
            .keys(completions)
            .map(key => ({
                displayText: key,
                properties: completions[key],
                text: `${key}${!completions[key] ? '' : '?.'}`,
                token: key,
            }));
    }

    public get alwaysAvailableFieldsInExportFileNameFormat(): DataDictionaryField[] {
        const fields = [];
        const category = "Always Available";

        // All this is of string JsonDataType
        const stringFields = ["minute", "hour", "date", "year", "month", "dayOfMonth", "totalRecordCount", "exportName", "season", "fileRecordCount", "fileNumber", "fileCount", "rangeStart", "rangeEnd"];
        stringFields.forEach(field => {
            fields.push(<DataDictionaryField>{
                Label: field,
                Path: field,
                JsonDataType: "string",
                Category: category,
            });
        });

        return fields;
    }
}
