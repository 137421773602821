import { CustomFieldType } from "./contact-organization";

export enum AvailableFieldType {
  Number = 0,
  Boolean = 1,
  Date = 2,
  String = 3
}

export function mapToCustomFieldType(value: AvailableFieldType): CustomFieldType {
  const mapping: Record<AvailableFieldType, CustomFieldType> = {
    [AvailableFieldType.Number]: CustomFieldType.number,
    [AvailableFieldType.Boolean]: CustomFieldType.boolean,
    [AvailableFieldType.Date]: CustomFieldType.date,
    [AvailableFieldType.String]: CustomFieldType.string
  };

  return mapping[value];
}

export class AttachmentCollection {
  name: string = null;
  path: string = null;
  display_name: string = null;
  available_fields: AvailableField[] = [];
}

export class AvailableField {
  path: string = null;
  display_name: string = null;
  type: AvailableFieldType = null;
  values: string[] = [];
}

export class AttachmentMappingSample {
  collections: AttachmentCollection[] = [];
}
