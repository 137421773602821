import { inject } from "fw";
import { createFromArray } from "fw-model";
import { Store, handle, dispatch } from "fw-state";

import { StartAction, LogoutAction } from "./actions";
import { FormErrorHandling } from "./error-handling";

import { ApplicationSettings } from "models/application-settings";
import { ApplicantSettings } from "models/applicant-settings";
import { GridColumn } from 'models/grid-column';
import { ApplicationSettingsRepository } from "network/application-settings-repository";
import { ApplicantSettingsRepository } from "network/applicant-settings-repository";
import { UpdateApplicationSettingsAction, UpdatePinnedFieldsAction, UpdateGridColumnsAction, UpdateApplicationPropertiesAction, RevertToDefaultGridColumnsAction, GridColumnsChangedAction } from "forms/application-settings";
import { UpdateApplicantSettingsAction } from "forms/applicant-settings";
import { SetSeasonSettingAction } from './current-user-settings';

export class RefreshSettingsAction {}

interface ApplicationSettingsStoreShape {
  seasonId: string;
  applicationSettings: ApplicationSettings;
  applicantSettings: ApplicantSettings;
}

const DEFAULT_COLUMNS = createFromArray(GridColumn, [
  {
    Label: "Name",
    Path: "applicant.name",
    Sort: "applicant.familyName applicant.givenName"
  },
  {
    Label: "Email",
    Path: "applicant.emailAddress",
    Sort: "applicant.emailAddress"
  },
  {
    Label: "Program",
    Path: "program.name",
    Sort: "program.name"
  }
]);

@inject
export class ApplicationSettingsStore extends Store<
  ApplicationSettingsStoreShape
> {
  constructor(
    private applicationSettingsRepo: ApplicationSettingsRepository,
    private applicantSettingsRepo: ApplicantSettingsRepository,
  ) {
    super();
  }

  public defaultState() {
    return {
      seasonId: null,
      applicationSettings: null,
      applicantSettings: null
    };
  }

  @handle(StartAction)
  private async handleStart(s: StartAction) {
    this.setState(state => ({
      applicationSettings: s.context.Season.ApplicationSettings,
      applicantSettings: s.context.Season.ApplicantSettings,
      seasonId: s.context.Season.Id,
    }));

    //NOTE:  Use .GridColumns as .DefaultGridColumns is a value set client-side and therefore will
    //       have no stored value at this point in the lifecycle (ie.  that's what we are trying to
    //       do below).
    const defaultColumns = (s.context.Season.ApplicationSettings.GridColumns == null || s.context.Season.ApplicationSettings.GridColumns.length == 0)
      ? DEFAULT_COLUMNS
      : s.context.Season.ApplicationSettings.GridColumns;
    const columns = s.context.UserSeasonSettings.Settings['applicant.grid.columns'];
    this.setState(state => ({
      ...state,
      applicationSettings: {
        ...state.applicationSettings,
        GridColumns: (columns == null || columns.length == 0)
          ? defaultColumns
          : columns,
        DefaultGridColumns: defaultColumns
      }
    }));
  }

  @handle(LogoutAction)
  private handleLogout() {
    this.setState(s => this.defaultState());
  }

  @handle(RefreshSettingsAction)
  private async handleRefreshSettingsAction(action: RefreshSettingsAction) {
    const updatedSettings = await this.applicationSettingsRepo.getSettings(this.state.seasonId);
    const defaultGridColumns = (updatedSettings.GridColumns == null || updatedSettings.GridColumns.length == 0)
      ? DEFAULT_COLUMNS
      : updatedSettings.GridColumns;
    this.setState(state => ({
      ...state,
      applicationSettings: {
        ...updatedSettings,
        //NOTE:  Use .GridColumns and not .DefaultGridColumns as that value is not
        //       set when calling the repo.
        GridColumns:  state.applicationSettings.GridColumns?.length ? state.applicationSettings.GridColumns : defaultGridColumns,
        DefaultGridColumns: defaultGridColumns
      }
    }));
  }

  @handle(UpdatePinnedFieldsAction, FormErrorHandling)
  private async handleUpdatePinnedFieldsAction(
    action: UpdatePinnedFieldsAction,
  ) {
    action.form.validate();

    const updatedFields = await this.applicationSettingsRepo.putPinnedFields(
      action.form.updatedModel(),
    );

    action.form.applyModel(updatedFields);

    this.setState(state => ({
      ...state,
      applicationSettings: {
        ...state.applicationSettings,
        PinnedFieldGroups: updatedFields.PinnedFieldGroups,
        SidePinnedFieldGroups: updatedFields.SidePinnedFieldGroups,
      },
    }));
  }

  @handle(UpdateApplicationPropertiesAction, FormErrorHandling)
  private async handleUpdateApplicationPropertiesAction(action: UpdateApplicationPropertiesAction) {
    action.form.validate();
    action.form.clearValidation();

    const settings = action.form.updatedModel();

    const updated = await this.applicationSettingsRepo.putApplicationProperties(
      settings.ApplicationProperties,
      this.state.applicationSettings.MetaData.ApplicationPropertiesLastUpdatedUtc
        ? new Date(this.state.applicationSettings.MetaData.ApplicationPropertiesLastUpdatedUtc).getTime()
        : null
    );

    this.setState(state => ({
      ...state,
      applicationSettings: {
        ...state.applicationSettings,
        ...updated,
      }
    }));
  }

  @handle(UpdateGridColumnsAction, FormErrorHandling)
  private async handleUpdateGridColumnsAction(
    action: UpdateGridColumnsAction,
  ) {
    for (const f of action.form) {
      f.validate();
    }

    const columns = action.form.map(a => a.updatedModel());

    const value = action.isDefault
      ? null
      : columns
    const saveAction = new SetSeasonSettingAction('applicant.grid.columns', value);
    await dispatch(saveAction);

    if (action.isDefault) {
      await this.applicationSettingsRepo.putGridColumns(
        columns
      );
    }

    const { GridColumns } = this.state.applicationSettings;
    this.setState(state => ({
      ...state,
      applicationSettings: {
        ...state.applicationSettings,
        GridColumns: columns,
        DefaultGridColumns: (action.isDefault)
          ? columns
          : state.applicationSettings.DefaultGridColumns
      }
    }));

    await dispatch(new GridColumnsChangedAction(GridColumns, columns));
  }

  @handle(RevertToDefaultGridColumnsAction)
  private async handleRevertToDefaultGridColumnsAction(
    action: RevertToDefaultGridColumnsAction
  ) {
    const saveAction = new SetSeasonSettingAction('applicant.grid.columns', null);
    await dispatch(saveAction);

    const refreshAction = new RefreshSettingsAction();
    await dispatch(refreshAction);

    const { GridColumns, DefaultGridColumns } = this.state.applicationSettings;
    this.setState(state => ({
      ...state,
      applicationSettings: {
        ...state.applicationSettings,
        GridColumns: DefaultGridColumns
      }
    }));

    await dispatch(new GridColumnsChangedAction(GridColumns, DefaultGridColumns));
  }

  @handle(UpdateApplicationSettingsAction, FormErrorHandling)
  private async handleUpdateApplicationSettingsAction(
    action: UpdateApplicationSettingsAction,
  ) {
    action.form.validate();

    const updatedSettings = await this.applicationSettingsRepo.put(
      action.form.updatedModel(),
    );

    this.setState(state => ({
      ...state,
      applicationSettings: updatedSettings,
    }));
  }

  @handle(UpdateApplicantSettingsAction, FormErrorHandling)
  private async handleUpdateApplicantSettingsAction(
    action: UpdateApplicantSettingsAction,
  ) {
    action.form.validate();

    const updatedSettings = await this.applicantSettingsRepo.put(
      action.form.updatedModel(),
    );

    this.setState(state => ({
      ...state,
      applicantSettings: updatedSettings,
    }));
  }
}
