import { inject } from "fw";
import { Store, handle } from "fw-state";
import { LogoutAction, StartAction } from "./actions";
import { LoginForm } from "forms/login";
import isEqual from "lodash-es/isEqual";

export enum TableSectionViewMode {
  TABLE = "table",
  DOCUMENT = "document",
}
interface TableSectionViewShape {
  viewMode: { [key: string]: TableSectionViewMode };
}

export class SwitchTableSectionView {
  constructor(public formId: string, public sectionKey: string, public viewMode: TableSectionViewMode ) { }
}

export class CrossTabTableSectionViewStart { }

export class CrossTabTableSectionViewStop { }

const TABLE_SECTION_VIEW_MODE =  "table-section-view-mode";

export const getStorageKey = (formId: string, sectionKey: string) => `form-${formId}-section-${sectionKey}`;

@inject
export class SwitchTableSectionViewStore extends Store<TableSectionViewShape> {
  constructor() {
    super();
  }

  defaultState() {
    return { viewMode: {} };
  }

  private getSectionViewFromStorage() {
    try {
      return JSON.parse(localStorage.getItem(TABLE_SECTION_VIEW_MODE));
    } catch {
      return {};
    }
  }

  private handleMultipleTabTableSectionView(event: StorageEvent){
    if (event.key !== TABLE_SECTION_VIEW_MODE) return;

    const viewMode = JSON.parse(event.newValue);
    if (isEqual(this.state.viewMode, viewMode)) return;

    this.setState(() => ({ viewMode }));
  }

  private multipleTabHandler = (event: StorageEvent) => {
    this.handleMultipleTabTableSectionView(event)
  };

  @handle(CrossTabTableSectionViewStart)
  private async handleCrossTabTableSectionViewStartAction()  {
    const viewMode = this.getSectionViewFromStorage();
    this.setState(() => ({ viewMode }));

    window.addEventListener("storage", this.multipleTabHandler);
  }

  @handle(CrossTabTableSectionViewStop)
  private async handleCrossTabTableSectionViewStopAction()  {
    window.removeEventListener("storage", this.multipleTabHandler);
  }

  @handle(SwitchTableSectionView)
  private async handleSwitchTableSectionViewAction(action: SwitchTableSectionView) {
    this.setState((currentState) => {
      const key = getStorageKey(action.formId, action.sectionKey)
      const newState = {
        ...currentState.viewMode,
        [key]: action.viewMode,
      }

      localStorage.setItem(TABLE_SECTION_VIEW_MODE, JSON.stringify(newState));

      return {
        viewMode: newState
      };
    });
  }

  private setDefaultView() {
    this.setState(() => ({ viewMode: {} }));
    localStorage.removeItem(TABLE_SECTION_VIEW_MODE);
  }

  @handle(LogoutAction)
  private handleLogout() {
    this.setDefaultView();
  }

  @handle(StartAction)
  private handleStart() {
    this.setDefaultView();
  }

  @handle(LoginForm)
  private handleLoginForm() {
    this.setDefaultView();
  }
}
